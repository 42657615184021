import React, { useEffect, useState } from 'react';
import { useStyle } from 'lib/utils';
import { Button, Dropdown, Layout, Menu } from 'antd';
import {
    DownOutlined,
    FolderOpenOutlined,
    GroupOutlined,
    LayoutOutlined,
    LinkOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { Link, NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { logout, selectAuth } from 'app/slice/authSlice';
import { ALL_ROLES } from 'App';
import CustomModal from 'components/common/CustomModal';
import questionIcon from 'assets/images/icon_question.png';
import SystemNotification from 'components/common/SystemNotification';

const { Header, Sider, Content } = Layout;

const navLinks = [
    '/dashboard/meeting-list',
    '/dashboard/meeting',
    '/dashboard/consultation',
    '/dashboard/profile',
    '/dashboard/whats-new',
];

const DashboardLayout = () => {
    useStyle('dashboard');
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { roleName, name, token } = useAppSelector(selectAuth);

    const [isConfirmLogoutVisible, setIsConfirmLogoutVisible] = useState(false);
    const onCloseConfirmLogout = () => setIsConfirmLogoutVisible(false);
    const onOpenConfirmLogout = () => setIsConfirmLogoutVisible(true);

    const onLogout = () => {
        dispatch(logout());
        onCloseConfirmLogout();
        if (roleName === 'sharoushi') {
            navigate('/sharoushi/login');
        } else {
            navigate('/shindanshi/login');
        }
    };

    const getActiveLink = () => {
        for (let link of navLinks) {
            if (location.pathname.startsWith(link)) {
                return link;
            }
        }

        return '';
    };

    useEffect(() => {
        if (roleName && !ALL_ROLES.includes(roleName)) {
            navigate('/forbidden');
        }
    }, [roleName, dispatch, navigate]);

    useEffect(() => {
        if (!token && !roleName) {
            navigate(-1);
        }
    }, [token, roleName, navigate]);

    useEffect(() => {
        if (location.pathname === '/dashboard') {
            navigate('/dashboard/meeting', { replace: true });
        }
    }, [location.pathname, navigate]);

    const menu = (
        <Menu>
            <Menu.Item onClick={onOpenConfirmLogout}>
                ログアウト
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <SystemNotification />
        <Layout>
            <Sider
                width={300}
                collapsedWidth='0'
                breakpoint='xs'
            >
                <div className='sider-title'>中企団 案件ご紹介管理サイト</div>
                <Menu mode='inline' selectedKeys={[getActiveLink()]}>
                    <Menu.Item key='/dashboard/meeting' icon={<LayoutOutlined />}>
                        <NavLink to='/dashboard/meeting'>管理画面</NavLink>
                    </Menu.Item>
                    <Menu.Item key='/dashboard/consultation' icon={<GroupOutlined />}>
                        <Link to='/dashboard/consultation'>面談依頼</Link>
                    </Menu.Item>
                    <Menu.Item key='/dashboard/meeting-list' icon={<FolderOpenOutlined />}>
                        <Link to='/dashboard/meeting-list'>面談予定一覧</Link>
                    </Menu.Item>
                    <Menu.Item key='/dashboard/profile' icon={<UserOutlined />}>
                        <Link to='/dashboard/profile'>プロフィール</Link>
                    </Menu.Item>
                    {/* Will restore in the future */}
                    {/*<Menu.Item key='/dashboard/service' icon={<QuestionCircleOutlined />}>*/}
                    {/*    <Link to='/dashboard/service'>サービスの使い方</Link>*/}
                    {/*</Menu.Item>*/}
                    <div className='bottom-link'>
                        <a href='https://www.chukidan.com/wp-content/uploads/2022/02/220216sr_kiyaku.pdf'
                           target='_blank' rel='noreferrer'
                        >
                            <Button block icon={<LinkOutlined />}>利用規約</Button>
                        </a>
                        <a href='https://www.chukidan.com/privacy' target='_blank' rel='noreferrer'>
                            <Button block icon={<LinkOutlined />}>個人情報取扱規約</Button>
                        </a>
                    </div>
                </Menu>
            </Sider>
            <Layout>
                <Header>
                    <div className='buttons'>
                        <Dropdown overlay={menu} placement='bottomRight' trigger={['click']}>
                            <Button>
                                {name}
                                <DownOutlined className='down-icon' />
                            </Button>
                        </Dropdown>
                    </div>
                </Header>
                <Content>
                    <Outlet />
                </Content>
            </Layout>

            <CustomModal
                isVisible={isConfirmLogoutVisible}
                onClose={onCloseConfirmLogout}
                title='ログアウト'
                icon={questionIcon}
                text='ログアウトしてもよろしいですか'
                showCancelButton={true}
                cancelButtonText='いいえ'
                showConfirmButton={true}
                confirmButtonText='はい'
                confirmOnClick={onLogout}
                cancelOnClick={onCloseConfirmLogout}
            />
        </Layout>
            </>
    );
};

export default DashboardLayout;
