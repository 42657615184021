import React, { useEffect, useMemo, useState } from 'react';
import avatar from 'assets/images/avatardefault.png';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Button, Card, Checkbox, Col, Form, Input, Row, Select, Skeleton, Upload } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { DeleteOutlined, LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { countFormatter, scrollToTop, validateImage, validatePdf } from 'lib/utils';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { fetchUserData, selectAuth } from 'app/slice/authSlice';
import { FETCH_PROFILE, fetchProfile, selectProfile, UPDATE_PROFILE, updateProfile } from 'app/slice/profileSlice';
import { getEmployeeCountRanges, getFields, getIndustries, getPrefectures } from 'app/slice/resourcesSlice';
import ChangePasswordModal from 'components/dashboard/ChangePasswordModal';
import { UploadFile } from 'antd/lib/upload/interface';
import { isEmpty } from 'lodash';
import { Rules } from 'types';

type CertificationFile = {
    uid: string,
    name: string
}

type ProfileFile = {
    file: { status: string; originFileObj: Blob }
}

const rules: Rules = {
    profileImage: [validateImage('single')],
    lastName: [
        { required: true, whitespace: true, message: '姓をご入力ください。' },
        { max: 50, message: '50文字以内ご入力ください。' },
    ],
    firstName: [
        { required: true, whitespace: true, message: '名をご入力ください。' },
        { max: 50, message: '50文字以内ご入力ください。' },
    ],
    lastNameFurigana: [
        { pattern: new RegExp(/^([\sァ-ヶー]+)$/), message: '姓をフリガナでご入力ください。' },
        { max: 50, message: '50文字以内ご入力ください。' },
    ],
    firstNameFurigana: [
        { pattern: new RegExp(/^([\sァ-ヶー]+)$/), message: '名をフリガナでご入力ください。' },
        { max: 50, message: '50文字以内ご入力ください。' },
    ],
    officeName: [
        { required: true, whitespace: true, message: '事務所名をご入力ください。' },
        { max: 100, message: '100文字以内ご入力ください。' },
    ],
    postalCode: [{ required: true }],
    prefectureCode: [{ required: true, whitespace: true, message: '都道府県をご選択ください。' }],
    buildingName: [],
    prefectures: [{ required: true }],
    address: [
        { required: true, whitespace: true, message: '住所をご入力ください。' },
        { max: 200, message: '200文字以内ご入力ください。' },
    ],
    officeHistory: [
        { max: 1000, message: '1000文字以内ご入力ください。' },
    ],
    officePr: [
        { whitespace: true, message: 'オフィスの強さを入力してください。' },
        { max: 1000, message: '1000文字以内ご入力ください。' },
    ],
    qualifications: [
        { whitespace: true, message: '資格を入力してください。' },
        { max: 1000, message: '1000文字以内ご入力ください。' },
    ],
    supportedAreas: [
        { max: 100, message: '100文字以内ご入力ください。' },
    ],
    otherAddress: [
        { whitespace: true, message: '他の住所を入力してください。' },
        { max: 100, message: '100文字以内ご入力ください。' },
    ],
    email: [
        { required: true, whitespace: true, message: 'メールアドレスをご入力ください。' },
        { max: 100, message: '100文字以内ご入力ください。' },
        { pattern: new RegExp(/^[\\xFF01-\\xFF5E0-9._%+-@]+$/i), message: ' メールアドレスを半角でご入力ください。' },
        { type: 'email', message: ' 有効なメールアドレスを入力してください。' },
    ],
    fields: [{ required: true, message: 'フィールドを選択してください' }],
    industries: [{ required: true, message: '業界を選択する' }],
    employeeCount: [{ required: true, message: '従業員の範囲を選択' }],
    phone: [{ required: true }],
    businessManual: [validatePdf('single')],
    certificationMark: [validateImage('list')],
};

const Profile = () => {
    const [form] = Form.useForm();
    const [editMode, setEditMode] = useState(false);
    const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);
    const {
        fields,
        prefectures,
        industries,
        employeeCountRanges,
    } = useSelector((state: RootState) => state.resource);
    const { data, success, errors, loading, type } = useAppSelector(selectProfile);
    const auth = useSelector(selectAuth);
    const dispatch = useAppDispatch();
    const [checkAllEmployeeCount, setCheckAllEmployeeCount] = useState(false);
    const [checkOtherField, setCheckOtherField] = useState(false);
    const [checkAllIndustries, setCheckAllIndustries] = useState(false);
    const [isResourcesLoading, setIsResourcesLoading] = useState(true);
    const [profileImageUrl, setProfileImageUrl] = useState<string | null>(null);
    const [profileImageLoading, setProfileImageLoading] = useState(false);
    const [certificationImageList, setCertificationImageList] = useState<UploadFile<unknown>[]>([]);
    const [businessPdf, setBusinessPdf] = useState<UploadFile<unknown>[]>([]);
    const [emailError, setEmailError] = useState(false);

    const enableEditMode = () => setEditMode(true);
    const resetEmailError = () => setEmailError(false);

    const onSubmit = () => {
        dispatch(updateProfile(form.getFieldsValue()));
    };

    const defaultValues = useMemo(() => {
        if (isEmpty(data) || !employeeCountRanges.length || !industries.length) return;

        let specialist = data;
        let user = specialist?.user;
        let profile = specialist?.user?.profile;

        setProfileImageUrl(specialist?.profileImageUrl || avatar);

        const fieldValue = specialist?.fields.map(field => field.code);
        const industryValue = specialist?.industries.map(industry => industry.code);
        const employeeCountValue = specialist?.employeeCountRanges.map(employeeCount => employeeCount.code);
        const prefecturesValue = specialist?.prefectures.map(prefecture => prefecture.code);
        const otherFieldValue = specialist?.specialistOtherFields?.[0]?.name || '';

        setCheckOtherField(!!otherFieldValue);

        const certificationImageValue = specialist?.specialistCertImages.map((value, index) => ({
            uid: index.toString(),
            name: 'image-' + (index + 1),
            url: value?.file_url,
        }));

        setCertificationImageList(certificationImageValue);

        if (specialist?.businessManualUrl !== '') {
            setBusinessPdf([
                {
                    'url': specialist?.businessManualUrl,
                    'name': specialist?.businessManualUrl?.split('/').pop() ?? '',
                    'uid': '-1',
                },
            ]);
        }

        return {
            'user[email]': user?.email,
            'profile[lastname]': profile?.lastname,
            'profile[firstname]': profile?.firstname,
            'profile[lastnameFurigana]': profile?.lastnameFurigana ?? '',
            'profile[firstnameFurigana]': profile?.firstnameFurigana ?? '',
            'profile[phone]': profile?.phone ?? '',
            'specialist[officeName]': specialist?.officeName ?? '',
            'specialist[postalCode]': specialist?.postalCode,
            'specialist[address]': specialist?.address ?? '',
            'specialist[buildingName]': specialist?.buildingName ?? '',
            'specialist[officePr]': specialist?.officePr ?? '',
            'specialist[supportedAreas]': specialist?.supportedAreas ?? '',
            'specialist[otherAddress]': specialist?.otherAddress ?? '',
            'specialist[officeHistory]': specialist?.officeHistory ?? '',
            'specialist[qualifications]': specialist?.qualifications ?? '',
            'specialist[twitterUrl]': specialist?.twitterUrl ?? '',
            'specialist[facebookUrl]': specialist?.facebookUrl ?? '',
            'specialist[homepageUrl]': specialist?.homepageUrl ?? '',
            'specialist[prefectureCode]': specialist?.prefectureCode,
            'attachment[profileImageUrl]': specialist?.profileImageUrl,
            'attachment[subProfileImageUrl]': specialist?.subProfileImageUrl,
            'attachment[businessManual]': specialist?.businessManualUrl,
            'attachment[profileImage]': specialist?.profileImageUrl,
            'attachment[subProfileImage]': specialist?.subProfileImageUrl,
            'fields[others]': otherFieldValue,
            'fields[]': fieldValue,
            'industries[]': industryValue,
            'employeeCount[]': employeeCountValue,
            'prefectures[]': prefecturesValue,
            'attachment[certificationMark][]': certificationImageValue,
        };
    }, [data, employeeCountRanges.length, industries.length]);

    const onChangeBusinessPdf = ({ fileList: newFileList }: { fileList: UploadFile<unknown>[] | CertificationFile[] }) => {
        setBusinessPdf(newFileList);
    };

    const onChangeCertificationImage = ({ fileList: newFileList }: { fileList: CertificationFile[] }) => {
        setCertificationImageList(newFileList);
    };

    useEffect(() => {
        if (!success && !loading && errors.hasOwnProperty('user.email')) {
            //can also be used for other fields but for now email is the only one needed for fetching
            setEmailError(true);
        }
    }, [errors, success, loading]);

    useEffect(() => {
        if (type === FETCH_PROFILE && success && !isResourcesLoading) {
            form.setFieldsValue(defaultValues);
        }
    }, [isResourcesLoading, defaultValues, data, success, type, form]);

    useEffect(() => {
        if (success === true && type === UPDATE_PROFILE) {
            dispatch(fetchProfile());
            dispatch(fetchUserData());
            setEditMode(false);
        }
    }, [dispatch, success, type]);

    useEffect(() => {
        if (!loading && type === FETCH_PROFILE) {
            dispatch(getPrefectures());
            dispatch(getIndustries());
            dispatch(getFields({ role: auth.roleName }));
            dispatch(getEmployeeCountRanges());
        }
    }, [dispatch, auth.roleName, loading, type]);

    useEffect(() => {
        dispatch(fetchProfile());
    }, [dispatch]);

    useEffect(() => {
        if (prefectures && industries && fields && employeeCountRanges) {
            setIsResourcesLoading(false);
        }
    }, [prefectures, industries, fields, employeeCountRanges]);

    useEffect(() => {
        form.validateFields(['user[email]']);
    }, [emailError, form]);

    const onChangeOtherfield = (e: { target: { value: string | string[]; }; }) => {
        setCheckOtherField(e.target.value.length > 0);
    };

    const onSelectAllPrefecture = (prefectureValue: string[]) => {
        let data: string[];
        if (prefectureValue.includes('all')) {
            data = prefectures.map(prefectures => prefectures.code);
        } else {
            data = prefectureValue;
        }
        form.setFieldsValue({
            'prefectures[]': data,
        });
    };

    const onCheckAllIndustry = (e: CheckboxChangeEvent) => {
        let data: string[] = [];
        setCheckAllIndustries(e.target.checked);
        if (e.target.checked) {
            data = industries.map(industries => industries.code);
        }
        form.setFieldsValue({
            'industries[]': data,
        });
    };

    const onCheckIndustryOption = (checkValue: CheckboxValueType[]) => {
        setCheckAllIndustries(checkValue.length === industries.length);
    };

    const onCheckAllEmployeeCount = (e: CheckboxChangeEvent) => {
        let data: string[] = [];
        setCheckAllEmployeeCount(e.target.checked);
        if (e.target.checked) {
            data = employeeCountRanges.map(employeeCountRange => employeeCountRange.code);
        }
        form.setFieldsValue({
            'employeeCount[]': data,
        });
    };

    const onCheckEmployeeOption = (checkValue: CheckboxValueType[]) => {
        setCheckAllEmployeeCount(checkValue.length === employeeCountRanges.length);
    };

    const getBase64 = (img: Blob, callback: Function) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const uploadProfileButton = () => {
        return (
            <Button className='upload-box' icon={profileImageLoading ? <LoadingOutlined /> : <PlusOutlined />}>
                <span className='upload-label'>アップロード</span>
            </Button>
        );
    };

    const handleProfileChange = (info: ProfileFile) => {
        if (info?.file?.status === 'uploading') {
            setProfileImageLoading(true);
            return;
        }
        if (info?.file?.status === 'done') {
            getBase64(info.file.originFileObj, (imageUrl: string) => {
                setProfileImageUrl(imageUrl);
                setProfileImageLoading(false);
            });
        }
    };

    const fileUpload = (e: { fileList: object, file: object }) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    //Created a custom request for upload to not seperate post request with submit
    const uploadfiles = (options: { onSuccess?: any }) => {
        const { onSuccess } = options;
        onSuccess('Ok');
    };

    const onCloseChangePasswordModal = () => setIsChangePasswordVisible(false);
    const onOpenChangePasswordModal = () => setIsChangePasswordVisible(true);

    const onCancelEdit = () => {
        setEditMode(false);
        form.setFieldsValue(defaultValues);
        setCheckAllIndustries(defaultValues?.['industries[]'].length === industries.length);
        setCheckAllEmployeeCount(defaultValues?.['employeeCount[]'].length === employeeCountRanges.length);
        scrollToTop();
    };

    const deleteProfileImage = () => {
        form.setFieldsValue({
            'attachment[profileImage]': [],
        });
        setProfileImageUrl(avatar);
    };

    const emailValidation = () => {
        if (emailError) {
            return Promise.reject('取られた電子メール');
        }

        return Promise.resolve();
    };

    return (
        <div className='dashboard-content profile'>
            <Card className='sharoushi-registration-card'>
                <div className='title text-start'>登録プロフィール</div>
                {!editMode && (
                    <Button className='edit-button' onClick={enableEditMode} type='primary'>編集</Button>
                )}

                {(type === FETCH_PROFILE && loading) ? (
                    <Skeleton active />
                ) : (
                    <Form form={form}
                          className='mt-4'
                          layout='vertical'
                          autoComplete='off'
                          onFinish={onSubmit}
                    >
                        <Row gutter={16}>
                            {editMode ? (
                                <>
                                    <Col xs={24} md={24}>
                                        <div className='position-relative'>
                                            <Form.Item
                                                className='upload-form'
                                                name='attachment[profileImage]'
                                                label='プロフィール画像'
                                                extra='画像形式： png、jpg、jpeg。最大容量：2MB'
                                                rules={rules.profileImage}
                                                getValueFromEvent={fileUpload}
                                            >
                                                <Upload name='profile' disabled={!editMode} showUploadList={false}
                                                        onChange={handleProfileChange} customRequest={uploadfiles}
                                                >
                                                    {profileImageUrl ? (
                                                        <img className='mt-2 w-150px h-150px' src={profileImageUrl}
                                                             alt='avatar'
                                                        />
                                                    ) : (
                                                        uploadProfileButton()
                                                    )}
                                                </Upload>
                                            </Form.Item>
                                            {profileImageUrl !== avatar && (
                                                <Button className='delete-profile-image' type='primary' danger
                                                        icon={<DeleteOutlined />} onClick={deleteProfileImage}
                                                />
                                            )}
                                        </div>
                                    </Col>
                                </>
                            ) : (
                                <>
                                    <Col span={24}>
                                        <Form.Item
                                            className='upload-form'
                                            name='profile'
                                            label='プロフィール画像​'
                                            extra='画像形式： png、jpg、jpeg。最大容量：2MB'
                                        >
                                            <img className='upload-preview' src={profileImageUrl ?? ''} alt='' />
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name='profile[lastname]'
                                    label='姓'
                                    extra='50文字以内'
                                    rules={rules.lastName}
                                >
                                    <Input readOnly={!editMode} placeholder={!editMode ? '' : '山田'} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name='profile[firstname]'
                                    label='名'
                                    extra='50文字以内'
                                    rules={rules.firstName}
                                >
                                    <Input readOnly={!editMode} placeholder={!editMode ? '' : '太郎'} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name='profile[lastnameFurigana]'
                                    label='姓（フリガナ）'
                                    extra='カタカナ（全角）を入力'
                                    rules={rules.lastNameFurigana}
                                >
                                    <Input readOnly={!editMode} placeholder={!editMode ? '' : 'ヤマダ'} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name='profile[firstnameFurigana]'
                                    label='名（フリガナ）'
                                    extra='カタカナ（全角）を入力'
                                    rules={rules.firstNameFurigana}
                                >
                                    <Input readOnly={!editMode} placeholder={!editMode ? '' : 'タロウ'} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name='specialist[officeName]'
                                    label='事務所名'
                                    extra='100文字以内'
                                    rules={rules.officeName}
                                >
                                    <Input readOnly={!editMode} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name='specialist[postalCode]'
                                    label='郵便番号'
                                    extra='0123456のように半角数字で入力'
                                    rules={rules.postalCode}
                                >
                                    <Input readOnly={!editMode} placeholder={!editMode ? '' : '0000000'} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name='specialist[prefectureCode]'
                                    label='都道府県'
                                    rules={rules.prefectureCode}
                                >
                                    <Select placeholder={!editMode ? '' : '東京都'} disabled={!editMode}>
                                        {prefectures.map((row) => (
                                            <Select.Option key={row.code} value={row.code}>{row.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name='specialist[address]'
                                    label='住所'
                                    extra='200文字以内'
                                    rules={rules.address}
                                >
                                    <Input readOnly={!editMode} placeholder={!editMode ? '' : '住所'} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name='specialist[buildingName]'
                                    label='ビル名'
                                    rules={rules.buildingName}
                                >
                                    <Input readOnly={!editMode} placeholder={!editMode ? '' : 'ビル名'} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item
                                    name='prefectures[]'
                                    label='オンライン面談対応可能地域（複数選択可）'
                                    rules={rules.prefectures}
                                >
                                    <Select
                                        placeholder={!editMode ? '' : '東京都'}
                                        mode='multiple'
                                        allowClear disabled={!editMode}
                                        onChange={onSelectAllPrefecture}
                                    >
                                        <Select.Option key='-1' value={'all'}>全国</Select.Option>
                                        {prefectures.map((row) => (
                                            <Select.Option key={row.code} value={row.code}>{row.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name='specialist[supportedAreas]'
                                    label='その他対応可能地域（自由入力）'
                                    extra='自由入力、100文字以内'
                                    rules={rules.supportedAreas}
                                >
                                    <Input readOnly={!editMode} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name='specialist[otherAddress]'
                                    label='その他事務所住所（事務所が2つ以上ある方）'
                                    extra='自由入力、100文字以内'
                                    rules={rules.otherAddress}
                                >
                                    <Input readOnly={!editMode} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item
                                    name='specialist[officePr]'
                                    label='事務所の強みやPR'
                                    extra='自由入力、1000文字以内'
                                    rules={rules.officePr}
                                >
                                    <TextArea readOnly={!editMode} rows={3}
                                              showCount={editMode ? { formatter: countFormatter } : false}
                                              maxLength={1000}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item
                                    name='specialist[officeHistory]'
                                    label='経歴・実績'
                                    extra='自由入力、1000文字以内'
                                    rules={rules.officeHistory}
                                >
                                    <TextArea readOnly={!editMode} rows={3}
                                              showCount={editMode ? { formatter: countFormatter } : false}
                                              maxLength={1000}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item
                                    name='specialist[qualifications]'
                                    label='保有資格'
                                    extra='自由入力、1000文字以内'
                                    rules={rules.qualifications}
                                >
                                    <TextArea readOnly={!editMode} rows={3}
                                              showCount={editMode ? { formatter: countFormatter } : false}
                                              maxLength={1000}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24} className='mb-3'>
                                <Form.Item
                                    className='mb-1'
                                    name='fields[]'
                                    label={(
                                        <>
                                            得意分野
                                            <span className='ms-2 text-gray'>少なくとも1つ選択してください</span>
                                        </>
                                    )}
                                    rules={rules.fields}
                                >
                                    <Checkbox.Group className='w-100' disabled={!editMode}>
                                        <Row gutter={[8, 8]}>
                                            {Object.entries(fields).map((column, key) => (
                                                <Col key={key} sm={24} md={12}>
                                                    <Row gutter={[8, 8]}>
                                                        <Col key={key} md={24}>
                                                            <Checkbox value={column[1].code}>{column[1].name}</Checkbox>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Checkbox.Group>
                                </Form.Item>
                                <Row style={{ lineHeight: '32px' }}>
                                    <Checkbox checked={checkOtherField} disabled={!editMode}>その他</Checkbox>
                                    <Form.Item
                                        className='w-350px'
                                        name='fields[others]'
                                    >
                                        <Input className='' onChange={onChangeOtherfield} readOnly={!editMode}
                                               placeholder={!editMode ? '' : '選択項目にない分野はこちらにご入力ください'}
                                        />
                                    </Form.Item>
                                </Row>
                            </Col>
                            <Col xs={24} md={24}>
                                <div className='required-mark'>
                                    得意業種
                                    <span className='ms-2 text-gray'>少なくとも1つ選択してください</span>
                                </div>
                                {/* Putting this on Form.Item will break the check all functionality */}
                                <Checkbox className='mt-2 mb-2' checked={checkAllIndustries}
                                          disabled={!editMode}
                                          onChange={onCheckAllIndustry}
                                >
                                    全業種対応可
                                </Checkbox>
                                <Form.Item
                                    name='industries[]'
                                    rules={rules.industries}
                                >
                                    <Checkbox.Group className='w-100' onChange={onCheckIndustryOption}
                                                    disabled={!editMode}
                                    >
                                        <Row gutter={[8, 8]}>
                                            {Object.entries(industries).map((column, key) => (
                                                <Col key={key} sm={24} md={12}>
                                                    <Row gutter={[8, 8]}>
                                                        <Col key={key} md={24}>
                                                            <Checkbox value={column[1].code}
                                                            >{column[1].name}</Checkbox>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <div className='required-mark'>
                                    得意事業規模
                                    <span className='ms-2 text-gray'>少なくとも1つ選択してください</span>
                                </div>
                                {/* Putting this on Form.Item will break the check all functionality */}
                                <Checkbox className='mt-2 mb-2'
                                          checked={checkAllEmployeeCount}
                                          disabled={!editMode}
                                          onChange={onCheckAllEmployeeCount}
                                >
                                    全ての規模対応可
                                </Checkbox>
                                <Form.Item
                                    name='employeeCount[]'
                                    rules={rules.employeeCount}
                                >
                                    <Checkbox.Group className='w-100' onChange={onCheckEmployeeOption}
                                                    disabled={!editMode}
                                    >
                                        <Row gutter={[8, 8]}>
                                            {Object.entries(employeeCountRanges).map((column, key) => (
                                                <Col key={key} sm={24} md={12}>
                                                    <Row gutter={[8, 8]}>
                                                        <Col key={key} md={24}>
                                                            <Checkbox value={column[1].code}
                                                            >{column[1].name}</Checkbox>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name='profile[phone]'
                                    label='電話番号'
                                    extra='03-1234-5678の形式（半角数字、半角ハイフン）'
                                    rules={rules.phone}
                                >
                                    <Input readOnly={!editMode} placeholder={!editMode ? '' : '000-000-0000'} />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                <Form.Item
                                    name='user[email]'
                                    rules={[
                                        ...rules.email,
                                        { validator: emailValidation },
                                    ]}
                                    label='Eメール'
                                >
                                    <Input readOnly={!editMode} onChange={resetEmailError} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name='specialist[twitterUrl]'
                                    label='Twitter URL'
                                >
                                    <Input readOnly={!editMode} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name='specialist[facebookUrl]'
                                    label='Facebook URL'
                                >
                                    <Input readOnly={!editMode} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item
                                    name='specialist[homepageUrl]'
                                    label='サイトURL'
                                >
                                    <Input readOnly={!editMode} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    className='upload-form'
                                    name='attachment[businessManual]'
                                    label='事業所案内'
                                    rules={rules.businessManual}
                                    getValueFromEvent={fileUpload}
                                    extra='PDFでアップロード、最大容量：2MB'
                                >
                                    <Upload name='business_manual' onChange={onChangeBusinessPdf} fileList={businessPdf}
                                            disabled={!editMode} customRequest={uploadfiles} accept='.pdf' maxCount={1}
                                    >
                                        <Button icon={<UploadOutlined />}>追加</Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    className='upload-form'
                                    name='attachment[certificationMark][]'
                                    label='認証・認定マーク'
                                    getValueFromEvent={fileUpload}
                                    rules={rules.certificationMark}
                                    extra={<div>Pマーク等、認証マークの画像を掲載できます。<br />推奨サイズ : 500px x 500px以上（最大容量：2MB）</div>}
                                >
                                    <Upload name='certification_mark' onChange={onChangeCertificationImage}
                                            fileList={certificationImageList} defaultFileList={certificationImageList}
                                            listType='picture' accept='image/*' disabled={!editMode}
                                            customRequest={uploadfiles}
                                    >
                                        <Button icon={<UploadOutlined />}>追加</Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Button type='link' onClick={onOpenChangePasswordModal} className='change-password'>
                            パスワードを変更する
                        </Button>
                        <ChangePasswordModal isVisible={isChangePasswordVisible} onClose={onCloseChangePasswordModal} />
                        {editMode && (
                            <>
                                <Row className='text-center mt-3 justify-content-center' gutter={[10, 10]}>
                                    <Col>
                                        <Button onClick={onCancelEdit} className='buttons' size='large'
                                                disabled={loading}
                                        >
                                            キャンセル
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button htmlType='submit' type='primary' size='large' className='buttons'
                                                loading={loading}
                                        >
                                            保存
                                        </Button>
                                    </Col>
                                </Row>
                            </>

                        )}
                    </Form>
                )}
            </Card>
        </div>
    );
};

export default Profile;
