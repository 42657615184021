import { Button, Form, Input, Modal } from 'antd';
import { Rule } from 'antd/lib/form';
import { CancelApplicationRequest } from 'api/application';
import React from 'react';

type Props = {
    isVisible: boolean,
    onClose: () => void,
    onCancelApplication: (val: string) => void
}

const cancelReasonRules: Rule[] = [
    {
        required: true,
        message: 'キャンセル理由をご入力ください。',
    },
];

const ConsultationCancelModal = (props: Props) => {
    const { isVisible, onClose, onCancelApplication } = props;

    const onSubmit = (values: CancelApplicationRequest) => {
        onCancelApplication(values.reason);
    };

    return (
        <Modal className='consultation-cancel-modal' visible={isVisible} footer={null} onCancel={onClose}>
            <div className='title'>面談をキャンセル</div>
            <Form
                layout='vertical'
                onFinish={onSubmit}
            >
                <Form.Item
                    name='reason'
                    label='キャンセル理由（必須）'
                    rules={cancelReasonRules}
                >
                    <Input.TextArea />
                </Form.Item>
                <div className='form-buttons'>
                    <Button className='modal-button' type='primary' danger block htmlType='submit'>
                        確定
                    </Button>
                    <Button className='modal-button' block onClick={onClose}>
                        面談詳細に戻る
                    </Button>
                </div>
            </Form>
        </Modal>
    );

};

export default ConsultationCancelModal;
