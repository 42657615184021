import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { getErrorMessage } from 'api';
import { getProfile, saveProfile } from 'api/user';
import { isArray } from 'lodash';

export const FETCH_PROFILE = 'FETCH_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

export type ResourcesValue = {
    code: string;
}

export type ProfileData = {
    
    officeName: string,
    postalCode: string,
    address: string,
    buildingName: string,
    officePr: string,
    supportedAreas: string,
    otherAddress: string,
    officeHistory: string,
    qualifications: string,
    interview: string,
    twitterUrl: string,
    facebookUrl: string,
    homepageUrl:string,
    prefectureCode: number,
    businessManualUrl: string,
    profileImageUrl: string,
    subProfileImageUrl: string,
    user: {
        email: string
        profile: {
            lastname: string,
            firstname: string,
            firstnameFurigana: string,
            lastnameFurigana: string,
            phone: string
        }
    },
    employeeCountRanges: ResourcesValue[],
    specialistOtherFields: {name:string}[],
    fields: ResourcesValue[],
    industries: ResourcesValue[],
    prefectures: ResourcesValue[],
    specialistCertImages: {file_url:string}[]
  
};
export type ErrorFields = {
    //Currently values wanted to fetch, can add in the future
    field: string
    
}
export type ProfileState = {
    type: string,
    success: boolean | null,
    data: ProfileData,
    loading: boolean,
    errors: ErrorFields
}

export type ProfileFormData = {
    'user[email]': string;
    'profile[lastname]': string;
    'profile[firstname]': string;
    'profile[lastnameFurigana]': string;
    'profile[firstnameFurigana]': string;
    'profile[phone]': string
    'specialist[officeName]': string;
    'specialist[postalCode]': string;
    'specialist[address]': string;
    'specialist[buildingName]': string;
    'specialist[officePr]': string;
    'specialist[supportedAreas]': string;
    'specialist[otherAddress]': string;
    'specialist[officeHistory]': string;
    'specialist[qualifications]': string;
    'specialist[twitterUrl]': string;
    'specialist[facebookUrl]': string;
    'specialist[homepageUrl]': string;
    'specialist[prefectureCode]': string;
    'attachment[profileImageUrl]': string;
    'attachment[subProfileImageUrl]': string;
    'attachment[businessManual]': File | string;
    'attachment[profileImage]': File | string;
    'attachment[subProfileImage]': File | string;
    'fields[others]': string;
    'fields[]': ResourcesValue[];
    'industries[]': ResourcesValue[];
    'employeeCount[]': ResourcesValue[];
    'prefectures[]': ResourcesValue[];
    'attachment[certificationMark][]':  File | string;
}

/**
 * calls profile API
 **/
export const fetchProfile = createAsyncThunk(
    'users/fetchProfile',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            let response = await getProfile();
            const { data = {} } = response;
            let newData = data.data.specialist;
           
            for (let key in newData) {
                let dataInitialLength = Object.keys(newData).length
                let newkey = key.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
                newData[newkey] = newData[key];
                if (key === 'user') { 
                    for (let profileKey in newData[key].profile) {
                        let userInitialLength = Object.keys(newData[key].profile).length
                        let newProfilekey = profileKey.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
                        newData[key].profile[newProfilekey] = newData.user.profile[profileKey];
                        if (userInitialLength !== Object.keys(newData[key].profile).length) {
                            delete newData.user.profile[profileKey]; 
                        }
                    }     
                   continue;
                } 
                if (dataInitialLength !== Object.keys(newData).length) {
                    delete newData[key]; 
                }
            }

            if (data.success === true) {
                dispatch(setData(newData));
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

/**
 * PATCH user's registration data
 **/
export const updateProfile = createAsyncThunk(
    'users/updateProfile',
    async (values: ProfileFormData, { getState, rejectWithValue }) => {
        const formData = new FormData();
        //Loop form data to append form fields with array  value
        for (let [key, value] of Object.entries<any>(values)) {
            let row = isArray(value) ? value : [value];

            for (let data of row) {
                data = data?.originFileObj ?? data;
              
                //Add if the value is not undefined or a field array
                if (data !== undefined || key.includes('[]')) {
                    formData.append(key, data?.url || data);
                }
            }
        }
        
        const newData = new FormData();
        let counter = 0;
         //Loop forms to convert camelCase to snake_case
        for (let [key, value] of  formData.entries()) {
            let newkey = key.replace(/[A-Z]/g, (letter: string) => `_${letter.toLowerCase()}`);   
            newkey = (newkey.includes('certification_mark') === true) ? 'attachment[certification_mark]['+ (counter++) + ']' : newkey; 
            newData.append(newkey, value);
            if(newkey !== key){
                newData.delete(key);
            }
        }
        // Add request method of put because form data isn't working well with put request
        newData.append('_method', 'PUT')

        try {
            let uuid = (getState() as RootState).auth.uuid;
            let response = await saveProfile(newData);
            const { data = {} } = response;

            if (data.success === true) {
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error, true));
        }
    },
);

export const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        type: '',
        loading: false,
        success: null,
        data: {},
        errors: {} as ErrorFields
    } as ProfileState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
    },
    extraReducers: (builder) => {
        // fetchProfile action pending
        builder.addCase(fetchProfile.pending, (state: ProfileState) => {
            state.type = FETCH_PROFILE;
            state.loading = true;
            state.success = null;
        });
        // fetchProfile action rejected
        builder.addCase(fetchProfile.rejected, (state: ProfileState) => {
            state.type = FETCH_PROFILE;
            state.loading = false;
            state.success = false;
        });
        // fetchProfile action fulfilled
        builder.addCase(fetchProfile.fulfilled, (state: ProfileState) => {
            state.type = FETCH_PROFILE;
            state.loading = false;
            state.success = true;
        });
        // updateProfile action pending
        builder.addCase(updateProfile.pending, (state: ProfileState) => {
            state.type = UPDATE_PROFILE;
            state.loading = true;
            state.success = false;
        });
        // updateProfile action rejected
        builder.addCase(updateProfile.rejected, (state: ProfileState, { payload }) => {     
            state.type = UPDATE_PROFILE;
            state.loading = false;
            state.success = false;
            state.errors = payload as ErrorFields
        });
        // updateProfile action fulfilled
        builder.addCase(updateProfile.fulfilled, (state: ProfileState) => {
            state.type = UPDATE_PROFILE;
            state.loading = false;
            state.success = true;
        });
    },
});

export const { setData } = profileSlice.actions;
export const selectProfile = (state: RootState) => state.profile;