import { getAxiosClientWithToken } from './index';
import { MeetingCountParams, MeetingListParams } from 'app/slice/meetingListSlice';

export const getScheduledMeetings = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/specialist/scheduled/meetings');
};

export const getMeetings = (params: MeetingListParams) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/specialist/meetings', { params });
};

export const getMeetingsCount = (params: MeetingCountParams) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/specialist/meetings/count', { params });
};
