import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { Funds } from 'app/slice/applicationSlice';

type Props = {
    fundApplication: Funds[],
    requirementTitle: string
}

const MeetingDetailsTextArea = (props: Props) => {
    const { fundApplication, requirementTitle } = props;

    return (
        <>
            {
                fundApplication?.map((fund, index) => {
                   return fund?.fundRequirements?.map((fundRequirements, id) => {
                        if (fundRequirements?.title === requirementTitle) {
                            return (
                                <div className='consultation-detail' key={`detail-${id}-${index}`} >
                                    <h4><strong>面談詳細</strong></h4>
                                    <TextArea rows={3} disabled value={fund?.applicationRemarks || ''} />
                                </div>
                            );
                        }
                        return <div key={`detail-${id}-${index}`}/>
                    });
                })
            }
        </>
    );
};

export default MeetingDetailsTextArea;
