import 'assets/styles/app.scss';
import 'moment/locale/ja';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { fetchUserData, selectAuth } from 'app/slice/authSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import ConsultationDetails from 'pages/dashboard/ConsultationDetails';
import ConsultationList from 'pages/dashboard/ConsultationList';
import DashboardLayout from 'pages/dashboard/DashboardLayout';
import DiagnosticLayout from 'pages/shindanshi/DiagnosticLayout';
import ForgotPassword from 'pages/main/ForgotPassword';
import ForgotPasswordModal from 'components/common/ForgotPasswordModal';
import ForgotPasswordSuccess from 'pages/main/ForgotPasswordSuccess';
import MeetingCalendar from 'pages/dashboard/MeetingCalendar';
import Profile from 'pages/dashboard/Profile';
import Registration from 'pages/main/Registration';
import SharoushiLogin from 'pages/sharoushi/Login';
import ShindanshiLogin from 'pages/shindanshi/Login';
import SocialWorkerLayout from 'pages/sharoushi/SocialWorkerLayout';
import SuccessModal from 'components/common/SuccessModal';
import Service from 'pages/dashboard/Service';
import { reset } from 'app/slice/forgotPasswordSlice';
import Forbidden from './pages/main/Forbidden';
import MeetingList from 'pages/dashboard/MeetingList';

export const ALL_ROLES = ['sharoushi', 'shindanshi'];

const App = () => {
    const [isForgotPasswordModalVisible, setIsForgotPasswordModalVisible] = useState(false);
    const [origin, setOrigin] = useState('');
    const dispatch = useAppDispatch();

    const { token } = useAppSelector(selectAuth);

    const onCloseForgotPasswordModal = () => {
        setIsForgotPasswordModalVisible(false);
        dispatch(reset());
    };

    const onOpenForgotPasswordModalSharoushi = () => {
        setIsForgotPasswordModalVisible(true);
        setOrigin('chukidan-member-sharoushi');
    };

    const onOpenForgotPasswordModalShindanshi = () => {
        setIsForgotPasswordModalVisible(true);
        setOrigin('chukidan-member-shindanshi');
    };

    useEffect(() => {
        if (token) {
            dispatch(fetchUserData());
        }
    }, [token, dispatch]);

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/sharoushi' element={<SocialWorkerLayout />}>
                    <Route path='login'
                           element={<SharoushiLogin onOpenForgotPasswordModal={onOpenForgotPasswordModalSharoushi} />}
                    />
                    <Route path='registration' element={<Registration />} />
                    <Route path='forgot-password/:token/success' element={<ForgotPasswordSuccess />} />
                    <Route path='forgot-password/:token' element={<ForgotPassword />} />
                </Route>
                <Route path='/shindanshi' element={<DiagnosticLayout />}>
                    <Route path='login'
                           element={<ShindanshiLogin onOpenForgotPasswordModal={onOpenForgotPasswordModalShindanshi} />}
                    />
                    <Route path='registration' element={<Registration />} />
                    <Route path='forgot-password'>
                        <Route path=':token' element={<ForgotPassword />} />
                        <Route path=':token/success' element={<ForgotPasswordSuccess />} />
                    </Route>
                </Route>
                <Route path='/dashboard' element={<DashboardLayout />}>
                    <Route path='meeting' element={<MeetingCalendar />} />
                    <Route path='profile' element={<Profile />} />
                    <Route path='meeting-list'>
                        <Route index element={<MeetingList />} />
                        <Route path=':type'>
                            <Route index element={<MeetingList />} />
                            <Route path=':uuid/details' element={<ConsultationDetails />} />
                        </Route>
                    </Route>
                    <Route path='consultation'>
                        <Route index element={<ConsultationList />} />
                        <Route path=':uuid/details' element={<ConsultationDetails />} />
                    </Route>
                    <Route path='service' element={<Service />} />
                </Route>
                <Route path='/forbidden' element={<Forbidden />} />
            </Routes>
            <ForgotPasswordModal isVisible={isForgotPasswordModalVisible}
                                 onClose={onCloseForgotPasswordModal}
                                 origin={origin}
            />
            <SuccessModal />
        </BrowserRouter>
    );
};

export default App;
