import React, { useEffect } from 'react';
import { useStyle } from 'lib/utils';
import { Layout } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import SystemNotification from 'components/common/SystemNotification';

const DiagnosticsLayout = () => {
    useStyle('labor-social');
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname === '/shindanshi') {
            navigate('/shindanshi/login', { replace: true });
        }
    }, [location.pathname, navigate]);

    return (
        <Layout>
            <SystemNotification />
            <Header className='labor-social-navbar'>
                <div className='title'>
                    <span>中企団 案件ご紹介管理サイト</span>
                </div>
            </Header>
            <Layout className='labor-social-content'>
                <Outlet />
            </Layout>
        </Layout>
    );
};

export default DiagnosticsLayout;
