import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'app/store';

type SearchQueryState = {
    searchQuery: string
}

export const searchQuerySlice = createSlice({
    name: 'searchQuery',
    initialState: {
        searchQuery: ''
    } as SearchQueryState,
    reducers: {
        setSearchQuery: (state, {payload}) => {
            state.searchQuery = payload;
        }
    }
});

export const selectSearchQuery = (state: RootState) => state.searchQuery;
export const { setSearchQuery } = searchQuerySlice.actions;
