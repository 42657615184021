import React, { useEffect } from 'react';
import { Alert, Button, Card, Form, Input } from 'antd';
import { ChangePasswordRequest, selectForgotPassword, updatePassword } from 'app/slice/forgotPasswordSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { validatePasswordConfirmation } from 'lib/utils';

const rules = {
    temporaryPassword: [{ required: true, whitespace: true, message: '仮パスワードをご入力ください。' }],
    newPassword: [
        { required: true, whitespace: true, message: '新しいパスワードをご入力ください。' },
        { pattern: new RegExp(/^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d).*$/), message: '8桁の英数混合のパスワードをご入力ください。' },
    ],
    confirmPassword: [
        { required: true, whitespace: true, message: '新しいパスワード（確認用）' },
        validatePasswordConfirmation('password', 'パスワードは一致していません。'),
    ],
};
const ForgotPassword = () => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const { message, status, updateSuccess } = useAppSelector(selectForgotPassword);
    const navigate = useNavigate();
    const params = useParams();
    const token = params.token;

    const onFormSubmit = (values: ChangePasswordRequest) => {
        const { temporary_password, password, password_confirmation } = values;
        dispatch(updatePassword({ temporary_password, password, password_confirmation, token }));
    };

    useEffect(() => {
        if (updateSuccess) {
            navigate('success');
        }
    }, [navigate, updateSuccess]);

    return (
        <Card className='forgot-password-card'>
            <div className='title'>パスワード再発行</div>
            {status === 'failed' && <Alert type={'error'} message={message} className='my-3' />}
            <Form
                form={form}
                layout='vertical'
                onFinish={onFormSubmit}
                autoComplete='off'
                className='m-0 w-auto'
            >
                <Form.Item
                    name='temporary_password'
                    label='仮パスワード'
                    rules={rules.temporaryPassword}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name='password'
                    label='新しいパスワード'
                    rules={rules.newPassword}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name='password_confirmation'
                    label='新しいパスワード（確認用）'
                    rules={rules.confirmPassword}
                >
                    <Input.Password />
                </Form.Item>
                <Button type='primary' htmlType='submit' className='w-100'>
                    確定
                </Button>
            </Form>
        </Card>

    );
};

export default ForgotPassword;
