import { getAxiosClientWithToken } from './index';

export const getNotifications = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/notifications/notViewed');
};

export const markingAsViewed = (id: number) => {
    const restClient = getAxiosClientWithToken();
    return restClient.patch('/notifications/' + id);
};
