import { Button, Col, Form, Input, Row } from 'antd';
import { useAppDispatch } from 'app/hooks';
import {
    Application,
    FundRequirements,
    SaveMeetingRemarks,
    Schedule, SUBMIT_MEETING_REMARKS,
    submitMeetingRemarks,
} from 'app/slice/applicationSlice';
import ClientDetails from 'components/consultation/ClientDetails';
import ConsultationStatus from 'components/consultation/ConsultationStatus';
import GrantChecklist from 'components/consultation/GrantChecklist';
import ScheduleData from 'components/consultation/ScheduleData';
import { ConsultationStatuses } from 'enums';
import React, { useEffect } from 'react';
import { resetState } from '../../app/slice/meetingSlice';
import { setSuccessState, SuccessModalState } from '../../app/slice/successSlice';
import successIcon from '../../assets/images/success_check.png';

type Props = {
    application: Application,
    role: string,
    fundRequirements: FundRequirements[],
    schedule: Schedule,
    type: string,
    success: boolean
}

const rules = {
    remarks: [{ required: true, message: '面談結果をご入力してください。' }],
};

const Finished = (props: Props) => {
    const [form] = Form.useForm();
    const { application, role, fundRequirements, schedule, type, success } = props;
    const { TextArea } = Input;
    const dispatch = useAppDispatch();

    const onFormSubmit = (value: SaveMeetingRemarks) => {
        const data: SaveMeetingRemarks = {
            uuid: application.uuid,
            remarks: value.remarks
        }
        dispatch(submitMeetingRemarks(data));
    }

    useEffect(() => {
        form.setFieldsValue({'remarks': application.meetingRemarks})
    }, [application, form]);

    useEffect(() => {
        if (type === SUBMIT_MEETING_REMARKS && success) {
            const successModalPayload: SuccessModalState = {
                isShow: success,
                title: '面談結果を更新しました',
                message: '',
                withButton: true,
                image: successIcon,
                buttonLink: '',
                buttonTitle: '閉じる',
            };
            dispatch(setSuccessState(successModalPayload));
            dispatch(resetState());
        }
    }, [dispatch, success, type]);

    return (
        <>
            <div className='dashboard-content consultation-details'>
                <div className='title'>案件の詳細・面談の申込</div>

                <ClientDetails
                    application={application}
                    role={role}
                    scheduleData={<ScheduleData application={application} schedule={schedule} isMeetingDetailsDisplayed={false}/>}
                />

                <ConsultationStatus fundDetails={application?.funds}
                                    status={ConsultationStatuses.FINISHED}
                                    replyPeriod={application?.schedule} />
                <GrantChecklist fundApplication={application?.funds}
                                fundRequirements={fundRequirements}
                                status={ConsultationStatuses.FINISHED}
                />

                <div className='consultation-detail my-3'>
                    <Form
                        form={form}
                        layout='vertical'
                        onFinish={onFormSubmit}
                        autoComplete='off'
                    >
                        <Row align='middle' gutter={12}>
                            <Col md={20} xs={18}>
                                <Form.Item
                                    name='remarks'
                                    label='面談結果'
                                    rules={rules.remarks}
                                >
                                    <TextArea rows={3} />
                                </Form.Item>
                            </Col>
                            <Col md={2} xs={6}>
                                <Button type='primary' htmlType='submit' block>更新</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    );
};

export default Finished;
