import React from 'react';
import Text from 'antd/lib/typography/Text';
import { Button } from 'antd';
import successCheckImage from 'assets/images/success_check.png';

type Props = {
    onCloseSuccess: () => void,
}

const ChangePasswordSuccess = (props: Props) => {
    return (
        <>
            <div className='text-center'>
                <img src={successCheckImage} alt='' />
                <div className='mt-4'>
                    <Text> パスワードが変更されました。</Text>
                    <Button type='primary' onClick={props.onCloseSuccess} className='w-100 mt-4'>
                        閉じる
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ChangePasswordSuccess;
