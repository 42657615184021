import { Alert, Tag } from 'antd';
import React  from 'react';
import { Link } from 'react-router-dom';
import { Notification } from 'app/slice/notificationSlice';
import moment from 'moment';

type Props = {
    notificationData: Notification,
    viewNotif: (id: number) => void,
}

const AlertNotification = (props: Props) => {
    const { notificationData, viewNotif } = props;

    const onNotificationClick = () => {
        viewNotif(notificationData.id)
    }

    const notificationMessage = () => {
        const url = `/dashboard/consultation/${notificationData.uuid}/details`;
        const formatDate = (toFormatDate: string) => {
            return moment(toFormatDate).format('YYYY/MM/DD');
        };
        return (
            <Link to={url} onClick={onNotificationClick}>
                <Tag color='#006BB5'>{formatDate(notificationData.createdAt)}</Tag> 
                {notificationData.message}
            </Link>
        );
    };

    return (
        <Alert className='mb-2'
               type='warning'
               message={notificationMessage()}
               showIcon />
    )
}
export default AlertNotification;
