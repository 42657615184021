import { Card, Col, Row } from 'antd';
import { Funds } from 'app/slice/applicationSlice';
import classNames from 'classnames';
import { ConsultationStatuses } from 'enums';
import React from 'react';

type Props = {
    fundDetails: Funds[],
    status: string,
    replyPeriod: string
}

const ConsultationStatus = (props: Props) => {

    const { fundDetails, status, replyPeriod } = props;

    /*
        Change color depending on status of consultation.
     */
    const getColor = () => {
        switch (status) {
            case ConsultationStatuses.SCHEDULED:
                return 'status-scheduled';
            case ConsultationStatuses.DECLINED:
                return 'status-declined';
            case ConsultationStatuses.MATCHING:
                return 'status-matching';
            case ConsultationStatuses.DUE_DATE:
                return 'status-due-date';
            case ConsultationStatuses.CANCELED:
                return 'status-canceled';
            case ConsultationStatuses.FINISHED:
                return 'status-finished';
            case ConsultationStatuses.WAITING_CONF:
                return 'status-waiting-conf';
            case ConsultationStatuses.MATCHING_END:
                return 'status-matching-end';
            default:
                return '';
        }
    };

    return (
        <div className='consultation-status-card'>
            <Card className={classNames('status', getColor())}>
                <Row align={'middle'}>
                    <Col className='gutter-row status-title' md={13} xs={24}>
                        <p><strong>内容:</strong></p>
                        {fundDetails?.map((item, index) => <p key={index}>{item.title}</p>)}
                    </Col>
                    <Col className='gutter-row text-right' md={11} xs={24}>
                        {
                            status === ConsultationStatuses.MATCHING && replyPeriod &&
                            <div className='status-date'>回答期限： {replyPeriod}</div>
                        }
                        <div className='status-text'>{status}</div>
                    </Col>
                </Row>
            </Card>
        </div>
    );

};

export default ConsultationStatus;
