import React, { Fragment, useMemo } from 'react';
import { useAppSelector } from 'app/hooks';
import { selectCompanyProfile } from 'app/slice/companyProfileSlice';
import { Col, Modal, Row, Skeleton } from 'antd';
import { isArray, isEmpty } from 'lodash';

type Props = {
    isVisible: boolean,
    onClose: () => void
}

const CompanyProfileModal = (props: Props) => {
    const { isVisible, onClose } = props;

    const { data, loading } = useAppSelector(selectCompanyProfile);

    const company = useMemo(() => {
        if (isEmpty(data)) return [];

        return [
            { name: '会社名', value: data.company.name },
            { name: '企業名フリガナ', value: data.company.nameFurigana },
            { name: '担当者さま役職', value: data.company.representativePosition },
            { name: '', value: '' },
            { name: '担当者さま', value: data.profile.lastname + ' ' + data.profile.firstname },
            { name: '担当者さまフリガナ', value: data.profile.lastnameFurigana + data.profile.firstnameFurigana },
            { name: 'TEL', value: data.profile.phone },
            { name: 'メールアドレス', value: data.user.email },
            { name: '〒', value: data.company.postalCode },
            {
                name: '都道府県',
                value: data.company.prefectureName,
            },
            { name: '住所', value: data.company.address },
            { name: 'ビル名', value: data.company.buildingName },
            {
                name: '業種',
                value: data.company.industryName,
            },
            { name: 'パート/アルバイト数', value: data.company.parttimeEmployeeCount },
            {
                name: '従業員数',
                value: data.company.employeeCountRangeName,
            },
            { name: '就業規則の有無', value: data.company.laborRegulationsCompliant ? '有' : '無' },
            {
                name: '社会保険加入状況',
                value: data.insurancePlans.length > 0 ? data.insurancePlans.map(insurance => insurance.name) : '無',
            },
            { name: '顧問社労士の有無', value: data.company.allowSharoushi ? '有' : '無' },
            { name: '', value: '' },
            { name: '顧問診断士の有無', value: data.company.allowShindanshi ? '有' : '無' },
        ];
    }, [data]);

    return (
        <Modal className='company-profile-modal p-0' visible={isVisible} footer={null} onCancel={onClose} width={1000}>
            <div className='title mb-4'>プロフィール詳細</div>
            <Row gutter={[12, 12]}>
                {loading ? (
                    <Skeleton active />
                ) : company.map((row, index) => (
                    (row.name === '' && row.value === '') ? (
                        <Col key={index} span={12} className='d-xs-none'>
                            <br />
                        </Col>
                    ) : (
                        <Fragment key={index}>
                            <Col xs={12} md={6}>
                                {row.name}
                            </Col>
                            <Col xs={12} md={6}>
                                {isArray(row?.value) ? row?.value.map((value, index3) => (
                                    <li key={index3}>
                                        <strong>{value}</strong>
                                    </li>
                                )) : (
                                    <strong>{row?.value}</strong>
                                )}
                            </Col>
                        </Fragment>
                    )
                ))}
            </Row>
        </Modal>
    );
};

export default CompanyProfileModal;
