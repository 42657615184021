import React from 'react';
import { useAppDispatch } from 'app/hooks';
import { removeScheduleFromList } from 'app/slice/meetingSlice';
import { Space } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

type Props = {
    id: number
};

const ScheduleTableActionButtons = (props: Props) => {
    const { id } = props;
    const dispatch = useAppDispatch();
    const onItemRemove = () => {
        dispatch(removeScheduleFromList(id));
    };

    return (
        <Space key={id} size="middle">
            <span className={'schedule-table-remove-action'} onClick={onItemRemove}><CloseOutlined /></span>
        </Space>
    );
};

export default ScheduleTableActionButtons;
