import { Application, FundRequirements } from 'app/slice/applicationSlice';
import ClientDetails from 'components/consultation/ClientDetails';
import ConsultationStatus from 'components/consultation/ConsultationStatus';
import GrantChecklist from 'components/consultation/GrantChecklist';
import { ConsultationStatuses } from 'enums';
import React from 'react';

type Props = {
    application: Application,
    role: string,
    fundRequirements: FundRequirements[],
}

const Canceled = (props: Props) => {
    const { application, role, fundRequirements } = props;

    return (
        <>
            <div className='dashboard-content consultation-details'>
                <div className='title'>案件の詳細・面談の申込</div>

                <ClientDetails
                    application={application}
                    role={role}
                />
                <ConsultationStatus fundDetails={application?.funds}
                                    status={ConsultationStatuses.CANCELED}
                                    replyPeriod={application?.schedule} />
                <GrantChecklist fundApplication={application?.funds}
                                fundRequirements={fundRequirements}
                                status={ConsultationStatuses.CANCELED}
                />
            </div>
        </>

    );

};

export default Canceled;
