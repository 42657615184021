import { Button, Col, DatePicker, Form, message, Modal, Row, TimePicker } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
    SelectedSchedules,
    SelectedSchedulesRequest,
    selectMeeting,
    setSelectedMeetingSchedules, updateScheduleFromList,
} from 'app/slice/meetingSlice';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { getAvailableTimeSlot, getHolidays, selectApplications } from 'app/slice/applicationSlice';
import { some } from 'lodash';

type Props = {
    title: string,
    isVisible: boolean,
    onClose: () => void,
    scheduleLabel: string,
    data?: SelectedSchedules,
    onSubmit?: (values: SelectedSchedulesRequest) => void,
    loading?: boolean
}

const rules = {
    date: [{ required: true, message: '日程をご選択ください。' }],
    startTime: [{ required: true, message: '開始時間をご選択ください。' }],
    endTime: [{ required: true, message: '終了時間をご選択ください。' }],
};

const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
const ScheduleSubmitModal = (props: Props) => {
    const dispatch = useAppDispatch();
    const { isVisible, scheduleLabel, data, loading, title } = props;
    const [form] = Form.useForm();
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [date, setDate] = useState('');
    const { availableStartTime, holidays } = useAppSelector(selectApplications);
    const { selectedSchedules } = useAppSelector(selectMeeting);
    //validations for current date
    const [timeFormat, setTimeFormat] = useState('');
    const [showNow, setShowNow] = useState(false);
    const [dateError, setDateError] = useState(false);

    const onFormSubmit = (values: SelectedSchedulesRequest) => {
        values = { ...values, date, startTime, endTime };
        // check if the dates inputted are already existing in the selectedSchedules
        const validateSchedule = (schedule?: SelectedSchedules) => (
            schedule
            && moment(schedule.date, 'YYYY年MM月DD日（ddd）').isSame(moment(date))
            && schedule.content === `${values.startTime} ~ ${values.endTime}`
        );

        let isExistsInScheduleList = some(selectedSchedules, validateSchedule);
        let isSameAsInitialValue = validateSchedule(data);

        if (isExistsInScheduleList && !isSameAsInitialValue) {
            message.error('スケジュールは既に存在しています。');
            return;
        }

        // If there's onSubmit on props you have to do things manually for it to show the loading
        if (props.onSubmit) {
            props.onSubmit(values);
        } else {
            if (!data?.id) {
                dispatch(setSelectedMeetingSchedules(values));
            } else {
                dispatch(updateScheduleFromList({ ...values, id: data?.id }));
            }

            form.resetFields();
            onClose();
        }
    };

    const onClose = () => {
        form.resetFields();
        props.onClose();
    };

    const onDateChange = (date: Moment | null, dateString: string) => {
        setDateError(false);
        if (date !== null) {
            dispatch(getAvailableTimeSlot(moment(date)));
            setDate(dateString);
        }
    };

    const onTimeStartOk = (value: Moment) => {
        setStartTime(value.format('HH:mm'));
        const end = value.clone().add(30, 'minutes');
        setEndTime(end.format('HH:mm'));
        form.setFieldsValue({ 'endTime': end });
    };

    const disabledStartHours = (): number[] => {
        let startHours = [] as number[];
        availableStartTime?.forEach((item) => {
            if (item?.availability) {
                startHours.push(parseInt(item?.availableStartTime.split(':')[0]));
            }
            return [];
        });
        return hours.filter(hour => !startHours.includes(hour));
    };

    // Can not select days before today
    const disabledDate = (current: Moment) => {
        return holidays === undefined ? (moment().add(-1, 'days') >= current ||
            moment(current).day() === 0 ||
            moment(current).day() === 6) : moment().add(-1, 'days') >= current ||
            moment(current).day() === 0 ||
            moment(current).day() === 6 ||
            holidays.indexOf(moment(current).format('Y-MM-DD')) !== -1;
    };

    useEffect(() => {
        if (data) {
            let date = moment(data.date, 'YYYY年MM月DD日（ddd）');
            let time = data.content.split(' ~ ');
            let startTime = moment(time[0], 'HH:mm');
            let endTime = moment(time[1], 'HH:mm');

            form.setFieldsValue({
                date, startTime, endTime,
            });

            setDate(date.format('YYYY-MM-DD'));
            setStartTime(startTime.format('HH:mm'));
            setEndTime(endTime.format('HH:mm'));
            setTimeFormat('HH:mm');

            dispatch(getAvailableTimeSlot(moment(date)));
        } else {
            form.resetFields();
        }
    }, [data, dispatch, form]);

    useEffect(() => {
        setTimeFormat(availableStartTime.length > 0 ? 'HH:mm' : 'HH');
        setShowNow(availableStartTime.length > 0);
        setDateError(availableStartTime.length === 0);
        if (form.getFieldValue('date')) {
            form.validateFields(['date']);
        }
    }, [availableStartTime.length, form]);

    const availableTimeValidation = () => {
        if (dateError) {
            return Promise.reject('本日以降の面談日をご選択ください。');
        }
        return Promise.resolve();
    };

    useEffect(() => {
        dispatch(getHolidays());
    }, []);

    return (
        <Modal className='submit-schedule-modal' centered transitionName='' maskTransitionName='' visible={isVisible}
               onCancel={onClose} footer={null}
        >
            <div className='submit-schedule-content'>
                <div className='title text-center'>{title}</div>
                <Form
                    form={form}
                    layout='vertical'
                    onFinish={onFormSubmit}
                    autoComplete='off'
                >
                    <div className='submit-schedule-modal-list'>
                        <ul>
                            <li>※システム上、予約不可の日程は選択できないようになっております</li>
                            <li>※面談の候補日程は、最大5つまで提示できます。</li>
                            <li>※面談の候補日程は、希望順位の順番に1つずつご登録ください。</li>
                            <li><span className='schedule-label'>{scheduleLabel}</span></li>
                        </ul>
                    </div>
                    <div className='submit-schedule-modal'>
                        <Form.Item
                            name='date'
                            label='日程'
                            rules={[
                                ...rules.date,
                                { validator: availableTimeValidation },
                            ]}
                        >
                            <DatePicker className='schedule'
                                        placeholder='日付を選択'
                                        onChange={onDateChange}
                                        format={'YYYY-MM-DD'}
                                        disabledDate={disabledDate}
                            />
                        </Form.Item>
                        <Row justify='space-between' gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item
                                    name='startTime'
                                    label='開始時間'
                                    rules={rules.startTime}
                                >
                                    <TimePicker className={'schedule'}
                                                placeholder='時刻を選択'
                                                onOk={onTimeStartOk}
                                                format={timeFormat}
                                                minuteStep={60}
                                                hideDisabledOptions={true}
                                                showNow={showNow}
                                                disabledHours={disabledStartHours}
                                                inputReadOnly={true}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name='endTime'
                                    label='終了時間'
                                    rules={rules.endTime}
                                >
                                    <TimePicker className={'schedule'}
                                                placeholder='時刻を選択'
                                                format={'HH:mm'}
                                                inputReadOnly={true}
                                                value={moment(endTime, 'HH:mm')}
                                                disabled={true}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <div className='submit-schedule-description mt-3'>
                        <Button type='primary' htmlType='submit' className='w-75 mt-3' loading={loading}>
                            登録
                        </Button>
                        <Button type='default' className='w-75 mt-3' onClick={onClose} disabled={loading}>
                            キャンセル
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};

export default ScheduleSubmitModal;
