import React from 'react';
import { Col, Row } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Application, Schedule } from 'app/slice/applicationSlice';

type Props = {
    application: Application
    schedule: Schedule
    isMeetingDetailsDisplayed?: boolean
}

const ScheduleData = (props: Props) => {
    const { schedule, application, isMeetingDetailsDisplayed = true } = props;

    return (
        <>
            <div>
                <hr />
                <p>
                    <strong>日程 : {schedule.datetime}</strong>
                </p>
                {application?.daidoJoins ? (
                    <Row className='my-3'>
                        <Col md={12} xs={24}>
                            <CheckOutlined className='attendance-check-icon' /> 大同生命営業担当者も同席します。
                        </Col>
                        <Col md={12} xs={24}>
                            担当者情報：{application?.company?.personInCharge}
                        </Col>
                    </Row>
                ) : (
                    <div className='my-3'>
                        <CloseOutlined className='attendance-check-icon' /> 大同生命営業担当者の同席を希望しません。
                    </div>
                )}
                {isMeetingDetailsDisplayed && (
                    <Row gutter={20} align='bottom'>
                        <Col md={4} xs={24}>
                            <strong>ミーティングURL:</strong>
                        </Col>
                        <Col md={20} xs={24}>
                            <a className='link' href={application?.joinUrl || ''} target='_blank' rel='noreferrer'>
                                {application?.joinUrl || ''}
                            </a>
                        </Col>
                        <Col md={4} xs={24}>
                            <strong>パスワード:</strong>
                        </Col>
                        <Col md={20} xs={24}>
                            {application?.password || ''}
                        </Col>
                    </Row>
                )}
            </div>
        </>
    );
};

export default ScheduleData;
