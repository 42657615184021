import { Button, Col, Row, Table } from 'antd';
import ScheduleTableActionButtons from 'components/consultation/ScheduleTableActionButtons';
import ScheduleSubmitModal from 'components/consultation/ScheduleSubmitModal';
import React, { useState } from 'react';
import { SelectedSchedules } from 'app/slice/meetingSlice';
import moment from 'moment';


type Props = {
    data: SelectedSchedules[],
    scheduleLabel: string
};

const ScheduleTableMatching = (props: Props) => {
    const { data, scheduleLabel } = props;
    const [isScheduleSubmitVisible, setIsScheduleSubmitVisible] = useState(false);

    const onCloseScheduleSubmitModal = () => setIsScheduleSubmitVisible(false);
    const onOpenScheduleSubmitModal = () => setIsScheduleSubmitVisible(true);

    const columns = [
        {
            title: '希望順位',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: '日程',
            dataIndex: 'date',
            key: 'date',
            render: (text: string) => {
                let date = moment(text, 'YYYY-MM-DD');
                return date.isValid() ? date.format('YYYY年MM月DD日（ddd）') : text;
            },
        },
        {
            title: '時間',
            dataIndex: 'content',
            key: 'content',
        },
        {
            title: '',
            key: 'number',
            render: (text: string, record: { id: number; }) => (
                <ScheduleTableActionButtons key={record.id} id={record.id} />
            ),
        },
    ];

    const rowKey = (record: SelectedSchedules) => {
        return record.id;
    };

    return (
        <>
            <div className='section-title my-3'>
                <Row>
                    <Col xs={24} xl={1}>
                        日程
                    </Col>
                    <Col xs={24} xl={17}>
                        <div className='section-description'>
                            ※お客さまの希望日が『相談内容詳細』欄にある場合は、そちらの日程をご提示いただくと選択されやすくなります。<br />
                            ※候補日は、複数ご提示いただくと選択されやすくなります。<br />
                            <span className='schedule-label'>{scheduleLabel}</span>
                        </div>
                    </Col>
                    <Col xs={24} xl={6}>
                        <div className='schedule-button'>
                            <Button type='primary' onClick={onOpenScheduleSubmitModal} disabled={data.length === 5}>
                                面談申込（日程入力）
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
            <ScheduleSubmitModal title='面談日程を追加'
                                 isVisible={isScheduleSubmitVisible}
                                 scheduleLabel={scheduleLabel}
                                 onClose={onCloseScheduleSubmitModal}
            />
            <Table className='schedule-table' columns={columns} dataSource={data} pagination={false} rowKey={rowKey} />
        </>
    );
};

export default ScheduleTableMatching;


