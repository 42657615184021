import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Card, Form, Input } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { AuthRequest, selectAuth } from 'app/slice/authSlice';
import { useNavigate } from 'react-router-dom';
import { loginShindanshi, resetLogin, selectLogin } from 'app/slice/loginSlice';
import RegistrationModal from 'components/common/RegistrationModal';
import { Rules } from 'types';

type Props = {
    onOpenForgotPasswordModal: () => void
}

const rules: Rules = {
    email: [
        { required: true, whitespace: true, message: 'メールアドレスをご入力ください。' },
        { type: 'email' }
    ],
    password: [{ required: true, whitespace: true, message: 'パスワードをご入力ください。' }]
};

const Login = (props: Props) => {
    const { onOpenForgotPasswordModal } = props;
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const loginState = useAppSelector(selectLogin);
    const { token, loading } = useAppSelector(selectAuth);
    const [isRegistrationVisible, setRegistrationVisible] = useState(false);

    const isLoading = () => loginState.loading || loading;

    const onFormSubmit = async (values: AuthRequest) => {
        const { email, password } = values;
        dispatch(loginShindanshi({ email, password }));
    };

    const onClickForgotPassword = () => onOpenForgotPasswordModal();
    const onOpenRegistrationModal = () => setRegistrationVisible(true);
    const onCloseRegistrationModal = useCallback(() => {
        setRegistrationVisible(false);
    }, []);

    useEffect(() => {
        if (token) {
            dispatch(resetLogin());
            navigate('/dashboard/meeting');
        }
    }, [navigate, dispatch, token]);

    return (
        <>
            <Card className='login-card'>
                <Button onClick={onOpenRegistrationModal} type='primary' className='register-button'>
                    新規登録
                </Button>
                <div className='title'>診断士ログイン</div>
                <div className='subtitle required-mark'>
                    は必須項目です
                </div>
                <div className='subtitle mt-2'>
                    ※ログイン失敗回数が一定数を超えると、<br />
                    アカウントがロックされますので、ご注意ください。<br />
                </div>
                
                <Form
                    form={form}
                    layout='vertical'
                    onFinish={onFormSubmit}
                    autoComplete='off'
                >
                    {
                        (!loginState.loading && loginState.success === false && loginState.message) &&
                        <Alert className='mb-3' message={loginState.message} type='error' showIcon closable />
                    }
                    <Form.Item
                        name='email'
                        label='メールアドレス'
                        rules={rules.email}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name='password'
                        label='パスワード'
                        rules={rules.password}
                    >
                        <Input.Password />
                    </Form.Item>
                    <div className='link text-center' onClick={onClickForgotPassword}>パスワードをお忘れの方</div>
                    <Button type='primary' htmlType='submit' className='w-100' loading={isLoading()}>
                        {!isLoading() ? 'ログイン' : ''}
                    </Button>
                </Form>
            </Card>
            <RegistrationModal isVisible={isRegistrationVisible} onClose={onCloseRegistrationModal} />
        </>
    );
};

export default Login;
