import { Button } from 'antd';
import { Application, FundRequirements, Schedule } from 'app/slice/applicationSlice';
import ClientDetails from 'components/consultation/ClientDetails';
import ConsultationCancelModal from 'components/consultation/ConsultationCancelModal';
import ConsultationStatus from 'components/consultation/ConsultationStatus';
import GrantChecklist from 'components/consultation/GrantChecklist';
import ScheduleData from 'components/consultation/ScheduleData';
import { ConsultationStatuses } from 'enums';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { selectSearchQuery } from 'app/slice/searchQuerySlice';
import { useAppSelector } from 'app/hooks';

type Props = {
    application: Application,
    role: string,
    fundRequirements: FundRequirements[],
    schedule: Schedule,
    onCancelApplication: (val: string) => void,
}

const Scheduled = (props: Props) => {
    const { application, role, fundRequirements, schedule, onCancelApplication } = props;
    const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
    const onCloseCancelModal = () => setIsCancelModalVisible(false);
    const { searchQuery } = useAppSelector(selectSearchQuery);

    return (
        <>
            <div className='dashboard-content consultation-details'>
                <div className='title'>案件の詳細・面談の申込</div>

                <ClientDetails
                    application={application}
                    hasProfileButton={true}
                    scheduleData={<ScheduleData application={application} schedule={schedule} />}
                    role={role}
                />
                <div className='note-text'>
                    ※面談ルームへの入室は予約時刻の５分前から可能です。<br />
                    ※面談時間は予約時刻から３０分間となります。３０分を過ぎた場合は予告なくシステムが終了する可能性がございます。予めご了承ください。<br />
                    ※万が一、ご都合が合わなくなった場合や日程変更をご要望の際は、中小企業福祉事業団 事業部 事業課までお願いいたします。daido-chukidan@chukidan-jp.com
                </div>

                <ConsultationStatus fundDetails={application?.funds}
                                    status={ConsultationStatuses.SCHEDULED}
                                    replyPeriod={application?.schedule}
                />
                <GrantChecklist fundApplication={application?.funds}
                                fundRequirements={fundRequirements}
                                emergencyContactNumber={application.emergencyContactNumber}
                                status={ConsultationStatuses.SCHEDULED}
                />

                <div className='text-center mt-4'>
                    <Link to={'/dashboard/consultation' + searchQuery}>
                        <Button type='primary' className='w-150px me-2'>戻る</Button>
                    </Link>
                </div>

            </div>
            <ConsultationCancelModal isVisible={isCancelModalVisible}
                                     onClose={onCloseCancelModal}
                                     onCancelApplication={onCancelApplication}
            />
        </>
    );

};

export default Scheduled;
