import React, { useCallback, useEffect } from 'react';
import { Alert, Button, Form, Input, Modal } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RegistrationEmail, resetRegistration, selectRegistration, sendEmail } from 'app/slice/registrationSlice';
import { setSuccessState, SuccessModalState } from 'app/slice/successSlice';
import sendingEmailSuccessIcon from 'assets/images/icon_send_mail.png';
import { useLocation } from 'react-router-dom';

type Props = {
    isVisible: boolean,
    onClose: () => void,
}

const RegistrationModal = (props: Props) => {
    const location = useLocation();
    const { isVisible } = props;
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const { success, status, message, loading } = useAppSelector(selectRegistration);

    const onFormSubmit = (value: RegistrationEmail) => {
        const { email } = value;
        const type: string = location.pathname.split('/')[1];
        dispatch(sendEmail({ email, type }));
    };

    const onClose = useCallback(() => {
        props.onClose();
        form.resetFields();
    }, [form, props]);


    useEffect(() => {
        if (success) {
            const successModalPayload: SuccessModalState = {
                isShow: success,
                title: '送信された登録URL',
                message: '入力されたメールアドレスに認証メールを送信しました。\n送信されたURLから登録してください。',
                withButton: true,
                image: sendingEmailSuccessIcon,
                buttonTitle: '閉じる',
            };
            onClose();
            dispatch(setSuccessState(successModalPayload));
            dispatch(resetRegistration());
        }
    }, [success, dispatch, onClose, location.pathname]);

    return (
        <Modal className='registration-modal' visible={isVisible} onCancel={onClose} footer={null}>
            <div className='title'>
                {location.pathname.includes('shindanshi') ? '診断士登録' : '社労士登録'}
            </div>
            {status === 'failed' && <Alert type={'error'} message={message} className='my-3' />}
            <Form
                form={form}
                layout='vertical'
                onFinish={onFormSubmit}
                autoComplete='off'
            >
                <Form.Item
                    name='email'
                    label='メールアドレス'
                    rules={[
                        { pattern: new RegExp(/^[\\xFF01-\\xFF5E0-9._%+-@]+$/i), message: ' メールアドレスを半角でご入力ください。' },
                        { type: 'email', message: ' 有効なメールアドレスを入力してください。' }, 
                        { required: true, message: 'メールアドレスをご入力ください。' }
                    ]}
                >
                    <Input placeholder='yamada@mail.com' />
                </Form.Item>
                <Button type='primary' htmlType='submit' className='w-100 mb-1' loading={loading}>
                    送信
                </Button>
                <Button onClick={onClose} className='w-100' disabled={loading}>
                    戻る
                </Button>
            </Form>
        </Modal>
    );
};

export default RegistrationModal;
