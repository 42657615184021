import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from 'api';
import { authenticateSharoushiApi, authenticateShindanshiApi } from 'api/auth';
import { setAuth } from 'app/slice/authSlice';
import { RootState } from 'app/store';

export type LoginState = {
    success: boolean | null,
    message: string,
    loading: boolean
}

export type LoginRequest = {
    email: string,
    password: string
}

/**
 * calls login API
 **/
export const loginSharoushi = createAsyncThunk(
    'users/loginSharoushi',
    async ({ email, password }: LoginRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await authenticateSharoushiApi(email, password);
            const { data = {} } = response;
            if (data.success === true) {
                dispatch(setAuth(data));
                return data;
            }

            return rejectWithValue(data.message);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

/**
 * calls login API
 **/
export const loginShindanshi = createAsyncThunk(
    'users/loginShindanshi',
    async ({ email, password }: LoginRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await authenticateShindanshiApi(email, password);
            const { data = {} } = response;
            if (data.success === true) {
                dispatch(setAuth(data));
                return data;
            }

            return rejectWithValue(data.message);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const loginSlice = createSlice({
    name: 'login',
    initialState: {
        success: null,
        message: '',
        loading: false,
    } as LoginState,
    reducers: {
        resetLogin: (state: LoginState) => {
            state.success = false;
            state.message = '';
            state.loading = false;
        },
    },
    extraReducers: (builder) => {
        // login action pending
        builder.addCase(loginSharoushi.pending, (state: LoginState) => {
            state.loading = true;
        });
        // login action rejected
        builder.addCase(loginSharoushi.rejected, (state: LoginState, action) => {
            state.loading = false;
            state.success = false;
            state.message = action.payload as string;
        });
        // login action fulfilled
        builder.addCase(loginSharoushi.fulfilled, (state: LoginState) => {
            state.loading = false;
            state.success = true;
        });
        // login action pending
        builder.addCase(loginShindanshi.pending, (state: LoginState) => {
            state.loading = true;
        });
        // login action rejected
        builder.addCase(loginShindanshi.rejected, (state: LoginState, action) => {
            state.loading = false;
            state.success = false;
            state.message = action.payload as string;
        });
        // login action fulfilled
        builder.addCase(loginShindanshi.fulfilled, (state: LoginState) => {
            state.loading = false;
            state.success = true;
        });
    },
});

export const selectLogin = (state: RootState) => state.login;
export const { resetLogin } = loginSlice.actions;
