import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { authSlice } from 'app/slice/authSlice';
import { loaderSlice } from 'app/slice/loaderSlice';
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { forgotPasswordSlice } from 'app/slice/forgotPasswordSlice';
import { notificationSlice } from 'app/slice/notificationSlice';
import { successModalSlice } from 'app/slice/successSlice';
import { loginSlice } from 'app/slice/loginSlice';
import { profileSlice } from 'app/slice/profileSlice';
import { registrationSlice } from 'app/slice/registrationSlice';
import { resourcesSlice } from 'app/slice/resourcesSlice';
import { specialistSlice } from 'app/slice/specialistSlice';
import { applicationSlice } from 'app/slice/applicationSlice';
import { meetingSlice } from 'app/slice/meetingSlice';
import { companyProfileSlice } from 'app/slice/companyProfileSlice';
import { meetingListSlice } from 'app/slice/meetingListSlice';
import { systemNotificationSlice } from 'app/slice/systemNotificationSlice';
import { searchQuerySlice } from 'app/slice/searchQuerySlice';
import { scheduleSlice } from 'app/slice/scheduleSlice';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth'],
    blacklist: ['auth'],
};

const authPersistConfig = {
    key: 'auth',
    storage,
    whitelist: ['email', 'name', 'uuid', 'token', 'roleName'],
};

const reducers = combineReducers({
    auth: persistReducer(authPersistConfig, authSlice.reducer),
    applications: applicationSlice.reducer,
    loader: loaderSlice.reducer,
    login: loginSlice.reducer,
    profile: profileSlice.reducer,
    successModal: successModalSlice.reducer,
    forgotPassword: forgotPasswordSlice.reducer,
    specialist: specialistSlice.reducer,
    registration: registrationSlice.reducer,
    resource: resourcesSlice.reducer,
    meeting: meetingSlice.reducer,
    notification: notificationSlice.reducer,
    companyProfile: companyProfileSlice.reducer,
    meetingList: meetingListSlice.reducer,
    systemNotification: systemNotificationSlice.reducer,
    searchQuery: searchQuerySlice.reducer,
    schedule: scheduleSlice.reducer
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
