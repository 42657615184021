import { Moment } from 'moment';
import { SubmitScheduleRequest } from 'app/slice/meetingSlice';
import { SaveMeetingRemarks } from 'app/slice/applicationSlice';
import { getAxiosClientWithToken } from './index';

export type GetApplicationsRequest = {
    keywords?: string;
    page?: number;
    status?: number;
    orderBy?: number | string | string[];
    sortBy?: number | 'asc' | 'desc';
};

export type CancelApplicationRequest = {
    reason: string,
    uuid: string
}

/**
 * Function to call API and get a list of Applications belonging to the logged-in User
 **/
export const getApplicationsApi = (request: GetApplicationsRequest) => {
    const restClient = getAxiosClientWithToken();

    // Remove keywords key if empty
    if (request.keywords === undefined || request.keywords === '') {
        delete request['keywords'];
    }

    // Remove per_page key if empty or if it has an invalid value
    if (request.page === undefined || request.page <= 0) {
        delete request['page'];
    }

    // Remove status key if empty or invalid
    if (request.status === undefined || request.status === -1) {
        delete request.status;
    }

    // Remove status key if empty or invalid
    if (request.orderBy === undefined || request.orderBy === -1) {
        delete request.orderBy;
    }

    // Remove status key if empty or invalid
    if (request.sortBy === undefined || request.sortBy === -1) {
        delete request.sortBy;
    }

    return restClient.get(`/specialist/application`, { params: request });
};

/**
 * Function to call API and get a list of Application Status Types
 **/
export const getApplicationStatusTypesApi = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/specialist/application/statuses`);
};

export const getApplicationDetailsApi = (uuid: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/specialist/application/${uuid}`);
};

export const declineApplicationApi = (uuid: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.patch(`/specialist/application/${uuid}`);
};

export const cancelApplicationApi = (data: CancelApplicationRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.patch(`/specialist/application/${data.uuid}/cancel`, data);
};

export const getAvailableTimeSlotOnCurrentDate = (date: Moment) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/specialist/schedules/time-slots', { params: { date: date.format('YYYY-MM-DD') } });
};

export const getHolidaysApi = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/specialist/schedules/holidays');
};

export const submitScheduleApi = (data: SubmitScheduleRequest) => {
    const { uuid } = data;
    const restClient = getAxiosClientWithToken();
    return restClient.post(`/specialist/application/${uuid}/schedule`, data);
};

export const getCompanyProfile = (uuid: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/specialist/application/${uuid}/company`);
};

export const saveMeetingRemarks = (data: SaveMeetingRemarks) => {
    const { uuid } = data;
    const restClient = getAxiosClientWithToken();
    return restClient.patch(`/specialist/application/${uuid}/meeting-remarks`, data);
};
