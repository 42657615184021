import { Button, Col, Row, Table } from 'antd';
import ScheduleSubmitModal from 'components/consultation/ScheduleSubmitModal';
import React, { useEffect, useState } from 'react';
import { SelectedSchedules, SelectedSchedulesRequest } from 'app/slice/meetingSlice';
import moment from 'moment';
import { DeleteOutlined, EditOutlined} from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import RemoveScheduleModal from 'components/consultation/RemoveScheduleModal';
import { useParams } from 'react-router-dom';
import { setSuccessState, SuccessModalState } from 'app/slice/successSlice';
import successIcon from 'assets/images/success_check.png';
import {
    CREATE_SCHEDULE, createSchedule,
    REMOVE_SCHEDULE,
    removeSchedule, resetState,
    selectSchedule,
    UPDATE_SCHEDULE, updateSchedule,
} from 'app/slice/scheduleSlice';
import { getApplicationDetails } from 'app/slice/applicationSlice';

type Props = {
    data: SelectedSchedules[],
    scheduleLabel: string,
};

const ScheduleTableWaitingConf = (props: Props) => {
    const { data, scheduleLabel } = props;
    const { uuid } = useParams();
    const [selectedData, setSelectedData] = useState<SelectedSchedules>();
    const { success, type, loading } = useAppSelector(selectSchedule);
    const dispatch = useAppDispatch();

    const [isScheduleSubmitVisible, setIsScheduleSubmitVisible] = useState(false);
    const [isConfirmRemoveVisible, setIsConfirmRemoveVisible] = useState(false);

    const onCloseScheduleSubmitModal = () => setIsScheduleSubmitVisible(false);
    const onOpenScheduleSubmitModal = () => setIsScheduleSubmitVisible(true);

    const onCloseConfirmRemoveModal = () => setIsConfirmRemoveVisible(false);
    const onOpenConfirmRemoveModal = () => setIsConfirmRemoveVisible(true);

    useEffect(() => {
        if (success && [CREATE_SCHEDULE, UPDATE_SCHEDULE, REMOVE_SCHEDULE].includes(type)) {
            let successModalPayload: SuccessModalState = {
                isShow: success,
                title: '',
                message: '',
                withButton: true,
                image: successIcon,
                buttonTitle: 'OK',
            };

            if (type === CREATE_SCHEDULE) {
                successModalPayload.title = '新しいスケジュールを登録しました。';
            } else if (type === UPDATE_SCHEDULE) {
                successModalPayload.title = 'スケジュールを変更しました。';
            } else if (type === REMOVE_SCHEDULE) {
                successModalPayload.title = 'スケジュールを削除しました。';
            }

            dispatch(setSuccessState(successModalPayload));
            dispatch(resetState());
            dispatch(getApplicationDetails(uuid!));
        }
    }, [dispatch, success, type, uuid]);

    const columns = [
        {
            title: '希望順位',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: '日程',
            dataIndex: 'date',
            key: 'date',
            render: (text: string) => {
                let date = moment(text, 'YYYY-MM-DD');
                return date.isValid() ? date.format('YYYY年MM月DD日（ddd）') : text;
            },
        },
        {
            title: '時間',
            dataIndex: 'content',
            key: 'content',
        },
        {
            title: '',
            key: 'number',
            render: (text: string, record: SelectedSchedules) => {
                const onItemEdit = () => {
                    setSelectedData({ ...record });
                    onOpenScheduleSubmitModal();
                }

                const onItemRemove = () => {
                    setSelectedData({ ...record });
                    onOpenConfirmRemoveModal();
                }

                return (
                    <div className='text-center'>
                        <span className={'schedule-table-remove-action'} onClick={onItemEdit}>
                            <EditOutlined />
                        </span>
                        {data.length > 1 && (
                            <span className={'schedule-table-remove-action ms-3'} onClick={onItemRemove}>
                                <DeleteOutlined />
                            </span>
                        )}
                    </div>
                )
            },
            width: '10%',
        },
    ];

    const onClickAddSchedule = () => {
        setSelectedData(undefined);
        onOpenScheduleSubmitModal();
    }

    const onAddSchedule = (values: SelectedSchedulesRequest) => {
        const date = moment(values.date + ' ' + values.startTime).format('YYYY-MM-DD HH:mm')
        dispatch(createSchedule({ uuid: uuid!, date }))
    }

    const onEditSchedule = (values: SelectedSchedulesRequest) => {
        if (selectedData) {
            const date = moment(values.date + ' ' + values.startTime).format('YYYY-MM-DD HH:mm')
            dispatch(updateSchedule({ uuid: uuid!, id: selectedData.id, date}))
        }
    }

    const onRemoveSchedule = () => {
        if (selectedData) {
            dispatch(removeSchedule({ uuid: uuid!, id: selectedData.id }))
        }
    }

    const rowKey = (record: SelectedSchedules) => {
        return record.id;
    };

    return (
        <>
            <div className='section-title my-3'>
                <Row>
                    <Col xs={24} xl={1}>
                        日程
                    </Col>
                    <Col xs={24} xl={17}>
                        <div className='section-description'>
                            ※お客さまの希望日が『相談内容詳細』欄にある場合は、そちらの日程をご提示いただくと選択されやすくなります。<br />
                            ※候補日は、複数ご提示いただくと選択されやすくなります。<br />
                            <span className='schedule-label'>{scheduleLabel}</span>
                        </div>
                    </Col>
                    <Col xs={24} xl={6}>
                        <div className='schedule-button'>
                            <Button type='primary' onClick={onClickAddSchedule} hidden={data.length === 5}>
                                日程を追加
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
            <ScheduleSubmitModal
                title={!selectedData ? '面談日程を追加' : '面談日程を変更'}
                isVisible={isScheduleSubmitVisible}
                scheduleLabel={scheduleLabel}
                data={selectedData}
                onClose={onCloseScheduleSubmitModal}
                onSubmit={!selectedData ? onAddSchedule : onEditSchedule}
                loading={loading && [CREATE_SCHEDULE, UPDATE_SCHEDULE].includes(type)}
            />
            <Table className='schedule-table' columns={columns} dataSource={data} pagination={false} rowKey={rowKey} />

            <RemoveScheduleModal
                isVisible={isConfirmRemoveVisible}
                title={<div>{selectedData?.date} {selectedData?.content}<br/>上記のスケジュールを削除してもよろしいでしょうか。</div>}
                onClose={onCloseConfirmRemoveModal}
                onSubmit={onRemoveSchedule}
                loading={loading && type === REMOVE_SCHEDULE}
            />
        </>
    );
};

export default ScheduleTableWaitingConf;


