import React, { useEffect } from 'react';
import { useAppDispatch } from 'app/hooks';
import { logout } from 'app/slice/authSlice';

const Forbidden = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(logout());
    }, [dispatch]);

    return (
        //TODO needs to have a more profound Forbidden page
        <div>Forbidden</div>
    );
}

export default Forbidden;
