import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { getSystemNotification } from 'api/systemNotification';

type SystemNotificationData = {
    title: string,
    content: string,
    createdAt: string
}

type SystemNotificationState = {
    success: boolean | null,
    data: SystemNotificationData
}

export const fetchSystemNotification = createAsyncThunk(
    'system-notification',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const response = await getSystemNotification();
            dispatch(setSystemNotification(response.data.data));
            return response.data;
        } catch (err) {
            return rejectWithValue(false);
        }
    },
);

export const systemNotificationSlice = createSlice({
    name: 'system-notification',
    initialState: {
        success: null,
        data: {} as SystemNotificationData,
    } as SystemNotificationState,
    reducers: {
        setSystemNotification: (state, { payload }) => {
            state.data = {
                title: payload.title,
                content: payload.content,
                createdAt: payload.created_at,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSystemNotification.fulfilled, (state) => {
            state.success = true;
        });
    },
});

export const selectSystemNotification = (state: RootState) => state.systemNotification;

export const {
    setSystemNotification,
} = systemNotificationSlice.actions;
