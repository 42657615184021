import React from 'react';
import { Link } from 'react-router-dom';

const Service = () => {
    return (
        <div className='dashboard-content'>
            <div className='title'>サービスの使い方</div>
            <br />
            <ol>
                <li><Link to=''>操作マニュアル</Link></li>
                <li><Link to=''>使い方の説明動画</Link></li>
            </ol>
        </div>
    );
};

export default Service;
