import { Button } from 'antd';
import { Application, FundRequirements, selectApplications, SUBMIT_SCHEDULE } from 'app/slice/applicationSlice';
import { SelectedSchedules } from 'app/slice/meetingSlice';
import ClientDetails from 'components/consultation/ClientDetails';
import ConsultationStatus from 'components/consultation/ConsultationStatus';
import DeclineConsultationModal from 'components/consultation/DeclineConsultationModal';
import GrantChecklist from 'components/consultation/GrantChecklist';
import ScheduleTableMatching from 'components/consultation/ScheduleTableMatching';
import { ConsultationStatuses } from 'enums';
import React from 'react';
import { useAppSelector } from 'app/hooks';

type Props = {
    application: Application,
    role: string,
    fundRequirements: FundRequirements[],
    selectedSchedules: SelectedSchedules[],
    isCancelModalVisible: boolean,
    onCancelClick: () => void,
    onDeclineClick: () => void,
    onOpenCancelModal: () => void,
    onCloseCancelModal: () => void,
    onSubmitSchedule: () => void,
}

const Matching = (props: Props) => {
    const {
        application,
        role,
        fundRequirements,
        selectedSchedules,
        isCancelModalVisible,
        onCancelClick,
        onDeclineClick,
        onOpenCancelModal,
        onCloseCancelModal,
        onSubmitSchedule,
    } = props;
    const { type, loading: scheduleLoading } = useAppSelector(selectApplications);

    const loading = type === SUBMIT_SCHEDULE && scheduleLoading;

    return (
        <>
            <div className='dashboard-content consultation-details'>
                <div className='title'>案件の詳細・面談の申込</div>

                <div className='cancel-button upper-buttons'>
                    <Button onClick={onOpenCancelModal} className='w-200'>辞退</Button>
                </div>

                <ClientDetails application={application} role={role} />

                <ConsultationStatus fundDetails={application?.funds}
                                    status={ConsultationStatuses.MATCHING}
                                    replyPeriod={application?.schedule} />

                <GrantChecklist
                    fundApplication={application?.funds}
                    fundRequirements={fundRequirements}
                    status={ConsultationStatuses.MATCHING}
                />

                <ScheduleTableMatching data={selectedSchedules} scheduleLabel={application.specialistScheduleLabel} />

                <div className='text-center action-buttons'>
                    <Button className='action-buttons_cancel' onClick={onCancelClick} disabled={loading}>キャンセル</Button>
                    <Button className='action-buttons_send'
                            type='primary'
                            disabled={selectedSchedules.length === 0}
                            onClick={onSubmitSchedule}
                            loading={loading}
                    >
                        送信
                    </Button>
                </div>

            </div>
            <DeclineConsultationModal isVisible={isCancelModalVisible}
                                      onClose={onCloseCancelModal}
                                      onDeclineClick={onDeclineClick} />
        </>

    );

};

export default Matching;
