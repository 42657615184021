import { Application, FundRequirements } from 'app/slice/applicationSlice';
import ClientDetails from 'components/consultation/ClientDetails';
import ConsultationStatus from 'components/consultation/ConsultationStatus';
import GrantChecklist from 'components/consultation/GrantChecklist';
import { ConsultationStatuses } from 'enums';
import React from 'react';

type Props = {
    application: Application,
    role: string,
    fundRequirements: FundRequirements[],
}

const MatchingEnd = (props: Props) => {
    const { application, role, fundRequirements } = props;

    return (
        <>
            <div className='dashboard-content consultation-details'>
                <div className='title'>面談日程</div>

                <ClientDetails
                    application={application}
                    role={role}
                />
                <ConsultationStatus fundDetails={application?.funds}
                                    status={ConsultationStatuses.MATCHING_END}
                                    replyPeriod={application?.schedule} />
                <GrantChecklist
                    fundApplication={application?.funds}
                    fundRequirements={fundRequirements}
                    status={ConsultationStatuses.MATCHING_END}
                />
            </div>
        </>

    );

};

export default MatchingEnd;
