import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Calendar, Col, ConfigProvider, Row, Tag } from 'antd';
import { CalendarMode } from 'antd/lib/calendar/generateCalendar';
import locale from 'antd/lib/locale/ja_JP';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getUserScheduledMeetings, selectMeeting } from 'app/slice/meetingSlice';
import { getUserNotification, markAsViewed, Notification, selectNotification } from 'app/slice/notificationSlice';
import AlertNotification from 'components/common/AlertNotification';
import moment, { Moment } from 'moment';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

type CalendarHeaderConfig = {
    value: Moment;
    type: CalendarMode;
    onChange: (date: Moment) => void;
    onTypeChange: (type: CalendarMode) => void;
}

type ListData = {
    content: string,
    uuid: string
}

const MeetingCalendar = () => {
    const { schedules, countScheduledMeetings } = useAppSelector(selectMeeting);
    const { notificationData } = useAppSelector(selectNotification);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getUserScheduledMeetings());
        dispatch(getUserNotification());
    }, [dispatch]);

    const getListData = (value: Moment) => {
        let listData: ListData[] = [];
        schedules.forEach(sched => {
            const scheduledDate = moment(sched.schedule).format('YYYY/MM/DD');
            const calendarDate = moment(value).format('YYYY/MM/DD');
            if (calendarDate === scheduledDate) {
                listData.push({
                    content: moment(sched.schedule).format('HH:mm') + ' - ' + sched.companyName,
                    uuid: sched.uuid
                });
            }
        });
        return listData;
    };

    const CalendarHeader = (config: CalendarHeaderConfig) => {
        const onClickPrev = () => {
            const newValue = config.value.clone().add(-1, 'month');
            config.onChange(newValue);
        };

        const onClickNext = () => {
            const newValue = config.value.clone().add(1, 'month');
            config.onChange(newValue);
        };

        return (
            <Row className='calendar-header'>
                <Col md={8} xs={8}>
                    <div className='count'>予約：{countScheduledMeetings}件</div>
                </Col>
                <Col md={{ span: 8, offset: 8 }} xs={16} className='month-picker'>
                    <Button onClick={onClickPrev}><LeftOutlined /></Button>
                    <span className='month-picker-text'>{config.value.format('YYYY年M月')}</span>
                    <Button onClick={onClickNext}><RightOutlined /></Button>
                </Col>
            </Row>
        );
    };

    const dateCellRender = (value: Moment) => {
        const listData = getListData(value);
        return (
            <ul className='events'>
                {listData.map(item => (
                    <li key={item.content}>
                        <Link to={`/dashboard/consultation/${item.uuid}/details`}>
                            <Tag color='blue'>{item.content}</Tag>
                        </Link>
                    </li>
                ))}
            </ul>
        );
    };

    const viewNotif = (id: number) => {
        dispatch(markAsViewed(id));
        dispatch(getUserNotification());
    };

    return (
        <div className='dashboard-content meeting-calendar'>
            <div className='title mb-2'>ダッシュボード</div>
            {notificationData.length > 0 && (
                <div className='meeting-notifications-container mb-2'>
                    {notificationData?.map((item: Notification, index: number) =>
                        <AlertNotification key={index} notificationData={item} viewNotif={viewNotif} />
                    )}
                </div>
            )}
            <ConfigProvider locale={locale}>
                <Calendar
                    dateCellRender={dateCellRender}
                    headerRender={CalendarHeader}
                />
            </ConfigProvider>
        </div>
    );

};

export default MeetingCalendar;
