import React, { useCallback, useMemo} from 'react';
import { StyleList } from 'types';
import { Rule } from 'antd/lib/form';
import { useLocation, useSearchParams } from 'react-router-dom';

/**
 * Set body id for styling
 *
 * @param {string} style
 */
export const useStyle = (...style: StyleList[]) => {
    document.body.id = style.join(' ');
};

/**
 * Scroll to top
 */
export const scrollToTop = (behavior?: 'smooth' | 'auto') => window.scroll({
    top: 0,
    left: 0,
    behavior: behavior,
});

/**
 * Rule for checking password match
 *
 * @param {string} fieldName
 * @param {string} message
 * @returns {({getFieldValue}: {getFieldValue: any}) => {validator(_, value): (Promise<never>)}}
 */
export const validatePasswordConfirmation: (fieldName: string, message: string) => Rule = (fieldName, message) => {
    return ({ getFieldValue }) => ({
        validator(_, value) {
            if (value && getFieldValue(fieldName) !== value) {
                return Promise.reject(new Error(message));
            }
            return Promise.resolve();
        },
    });
};

/**
 * Rule for image checking
 * @returns {() => {validator(_, value): (Promise<never>)}}
 */
export const validateImage: (fileField: String) => Rule = (fileField) => {
    return () => ({
        validator(_, value) {
            if (value !== undefined && !validURL(value)) {
                return new Promise(function(resolve, reject) {
                    let file = value[value.length - 1];
                    if (value !== '') {
                        value.forEach((data: { type: string; size: number; url: string }) => {
                            file = (fileField === 'list') ? data : file;
                            if (!data.url) {
                                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
                                if (!isJpgOrPng) {
                                    reject(new Error('アップロードできるのはJPG / PNGファイルのみです'));
                                }
                                const isLt2M = file.size / 1024 / 1024 < 2;
                                if (!isLt2M) {
                                    reject(new Error('画像は2MB未満である必要があります'));
                                }
                            }
                        });
                    }
                    resolve('success');
                });
            }
            return Promise.resolve('success');
        },
    });
};

/**
 * Rule for Pdf checking
 * @returns {() => {validator(_, value): (Promise<never>)}}
 */
export const validatePdf: (fileField: String) => Rule = (fileField) => {
    return () => ({
        validator(_, value) {
            if (value !== undefined && !validURL(value) && Object.keys(value).length > 0) {
                return new Promise(function(resolve, reject) {
                    let file = value[value.length - 1];
                    value.forEach((data: { type: string; size: number; }) => {
                        file = (fileField === 'list') ? data : file;
                        const isPdf = file.type === 'application/pdf';
                        if (!isPdf) {
                            reject(new Error('アップロードできるのはPDFファイルのみです'));
                        }
                        const isLt2M = file.size / 1024 / 1024 < 2;
                        if (!isLt2M) {
                            reject(new Error('画像は2MB未満である必要があります'));
                        }
                    });
                    resolve('success');
                });
            }
            return Promise.resolve('success');
        },
    });
};

const validURL = (url: string) => {
    var pattern = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!-/]))?/;
    return pattern.test(url);
};

export const isDevMode = () => {
    return process.env.NODE_ENV === 'development' || window.location.hostname.includes('dev');
};

// A custom hook that builds on useLocation to parse
// the query string for you.
export const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const countFormatter = ({ count, maxLength }: { count: number, maxLength?: number }) => {
    return `${count}/${maxLength}`;
};

export const useParamsState = <T = string>(
    key: string,
    defaultValue: any = null,
    transform: ((value: any) => any) = (value) => value,
): [T, (value: T) => void] => {
    const [searchParams, setSearchParams] = useSearchParams();

    return [
        useMemo(
            () => transform(searchParams.get(key) ?? defaultValue),
            [defaultValue, key, searchParams, transform],
        ),
        useCallback((value: T) => {
            const query = value as any;

            if (searchParams.get(key) !== query) {
                setSearchParams({ ...Object.fromEntries(searchParams), [key]: query });
            }
        }, [key, searchParams, setSearchParams]),
    ];
};
