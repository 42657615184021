import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { changePasswordUser } from 'api/specialist';

export type SPECIALIST_STATUS = 'success' | 'failed' | 'pending';

type SpecialistState = {
    loading: boolean,
    success: boolean,
    status: SPECIALIST_STATUS,
    message: string
}

export type ChangePasswordRequest = {
    currentPassword: string,
    newPassword: string,
    newPasswordConfirmation: string
}

/**
 * changePassword user
 **/
export const changePassword = createAsyncThunk(
    'specialist/user/password',
    async (data: ChangePasswordRequest, { getState, rejectWithValue }) => {
        const { currentPassword, newPassword, newPasswordConfirmation } = data;
        // @ts-ignore
        const { loading } = getState().specialist;
        if (!loading) {
            return;
        }
        try {
            const response = await changePasswordUser(currentPassword, newPassword, newPasswordConfirmation);
            return response.data;
        } catch (err) {
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(false);
        }
    },
);


const initialState: SpecialistState = {
    loading: false,
    success: false,
    status: 'pending',
    message: '',
};

export const specialistSlice = createSlice({
    name: 'specialist',
    initialState: initialState,
    reducers: {
        reset: (state: SpecialistState) => {
            state.success = false;
            state.loading = false;
            state.status = 'pending';
            state.message = '';
        },
    },
    extraReducers: (builder) => {
        builder.addCase((changePassword.pending), (state) => {
            state.loading = true;
            state.status = 'pending';
        });
        builder.addCase((changePassword.fulfilled), (state) => {
            state.loading = false;
            state.status = 'success';
            state.success = true;
        });
        builder.addCase((changePassword.rejected), (state) => {
            state.loading = false;
            state.status = 'failed';
        });
    },
});

export const { reset } = specialistSlice.actions;
