import { changePassword, requestForgotPasswordEmail, validateToken } from 'api/forgotPassword';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'app/store';

export type FORGOT_PASSWORD_STATUS = 'success' | 'failed' | 'pending';

type ForgotPasswordState = {
    loading: boolean;
    success: boolean;
    status: FORGOT_PASSWORD_STATUS;
    message: string;
    hasValidToken: boolean;
    updateSuccess: boolean;
};

export type ForgotPasswordEmailSendingRequest = {
    email: string;
    origin: string;
};

export type ChangePasswordRequest = {
    token: string | undefined;
    temporary_password: string;
    password: string;
    password_confirmation: string;
};

/**
 * request for forgot password email API
 **/
export const sendForgotPasswordEmail = createAsyncThunk(
    'users/forgotPassword',
    async (data: ForgotPasswordEmailSendingRequest, { getState, rejectWithValue }) => {
        const { email, origin } = data;
        // @ts-ignore
        const { loading } = getState().forgotPassword;
        if (!loading) {
            return;
        }
        try {
            const response = await requestForgotPasswordEmail(email, origin);
            return response.data;
        } catch (err) {
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(false);
        }
    },
);

/**
 * validate forgot password token
 **/
export const validateForgotPasswordToken = createAsyncThunk(
    'users/validateForgotPasswordToken',
    async (data: string | undefined, { rejectWithValue }) => {
        try {
            const response = await validateToken(data);
            return response.data;
        } catch (err) {
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(false);
        }
    },
);

/**
 * update password API
 **/
export const updatePassword = createAsyncThunk(
    'users/changePassword',
    async (data: ChangePasswordRequest, { getState, rejectWithValue }) => {
        const { token, temporary_password, password, password_confirmation } = data;
        // @ts-ignore
        const { loading } = getState().forgotPassword;
        if (!loading) {
            return;
        }
        try {
            const response = await changePassword(temporary_password, password, password_confirmation, token);
            return response.data;
        } catch (err) {
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(false);
        }
    },
);

const initialState: ForgotPasswordState = {
    loading: false,
    success: false,
    status: 'pending',
    message: '',
    hasValidToken: true,
    updateSuccess: false,
};

export const forgotPasswordSlice = createSlice({
    name: 'forgotPassword',
    initialState: initialState,
    reducers: {
        reset: (state: ForgotPasswordState) => {
            state.success = false;
            state.loading = false;
            state.status = 'pending';
            state.message = '';
            state.hasValidToken = false;
            state.updateSuccess = false;
        },
    },
    extraReducers: (builder) => {
        // forgot password action pending
        builder.addCase(sendForgotPasswordEmail.pending, (state) => {
            state.loading = true;
            state.status = 'pending';
        });
        // forgot password action fulfilled
        builder.addCase(sendForgotPasswordEmail.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.success = payload.success;
            state.status = 'success';
            state.message = 'メールを確認してください。リンクが送信されました。';
        });
        // forgot password action rejected
        builder.addCase(sendForgotPasswordEmail.rejected, (state) => {
            state.loading = false;
            state.success = false;
            state.status = 'failed';
            state.message = '指定されたメールアドレスは存在しません。';
        });
        // update password action fulfilled
        builder.addCase(updatePassword.pending, (state) => {
            state.loading = true;
            state.status = 'pending';
            state.updateSuccess = false;
        });
        // update password action fulfilled
        builder.addCase(updatePassword.fulfilled, (state) => {
            state.loading = false;
            state.status = 'success';
            state.updateSuccess = true;
        });
        // update password action fulfilled
        builder.addCase(updatePassword.rejected, (state) => {
            state.loading = false;
            state.status = 'failed';
            state.updateSuccess = false;
            state.message = '仮パスワードが違います。';
        });
        builder.addCase(validateForgotPasswordToken.fulfilled, (state) => {
            state.hasValidToken = true;
        });
        builder.addCase(validateForgotPasswordToken.rejected, (state) => {
            state.hasValidToken = false;
        });
    },
});

export const selectForgotPassword = (state: RootState) => state.forgotPassword;

export const { reset } = forgotPasswordSlice.actions;
