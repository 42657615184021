import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { getNotifications, markingAsViewed } from 'api/notification';

type NotificationState = {
    loading: boolean,
    success: boolean,
    total: number,
    perPage: number,
    currentPage: number,
    lastPage: number,
    notificationData: Notification[],
}

type NotificationData = {
    id: number,
    message: string,
    created_at: string,
    viewed_at: string,
    uuid: string,
    application: { uuid: string }
}

export type Notification = {
    id: number,
    message: string,
    createdAt: string,
    viewedAt: string,
    uuid: string
}

/**
 * GET user's notifications
 **/
export const getUserNotification = createAsyncThunk(
    'users/notification',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const response = await getNotifications();
            dispatch(setNotificationList(response.data));
            return response.data;
        } catch (err) {
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(false);
        }
    },
);

/**
 * PATCH user's notification as viewed
 **/
export const markAsViewed = createAsyncThunk(
    'users/notification',
    async (id: number, { rejectWithValue }) => {
        try {
            const response = await markingAsViewed(id);
            return response.data;
        } catch (err) {
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(false);
        }
    },
);


const initialState: NotificationState = {
    loading: false,
    success: false,
    total: 0,
    perPage: 0,
    currentPage: 0,
    lastPage: 0,
    notificationData: [] as Notification[],
};

export const notificationSlice = createSlice({
    name: 'notification',
    initialState: initialState,
    reducers: {
        setNotificationList: (state, { payload }) => {
            state.notificationData = payload.data.map((notification: NotificationData) => ({
                id: notification.id,
                message: notification.message,
                createdAt: notification.created_at,
                viewedAt: notification.viewed_at,
                uuid: notification?.application?.uuid,
            }));
        },
        reset: (state: NotificationState) => {
            state.success = false;
            state.loading = false;
        },
    },
});

export const { reset, setNotificationList } = notificationSlice.actions;
export const selectNotification = (state: RootState) => state.notification;

