import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { getErrorMessage } from 'api';
import { getCompanyProfile } from 'api/application';

export type CompanyProfileData = {
    user: {
        email: string,
        uuid: string
    },
    profile: {
        id: number,
        userId: number,
        lastname: string,
        firstname: string,
        lastnameFurigana: string,
        firstnameFurigana: string,
        phone: string,
        createdAt: string,
        updatedAt: string
    },
    company: {
        name: string,
        nameFurigana: string,
        postalCode: string,
        address: string,
        buildingName: string,
        representativePosition: string,
        prefectureCode: string,
        industryCode: string,
        employeeCountRangeCode: string,
        prefectureName: string,
        industryName: string,
        employeeCountRangeName: string,
        parttimeEmployeeCount: number,
        insurancePlans: string[],
        laborRegulationsCompliant: number,
        allowSharoushi: number,
        allowShindanshi: number,
        daidoBranchName: string,
        daidoDepartmentName: string,
        daidoEmployeeName: string,
        createdAt: string,
        updatedAt: string
    },
    insurancePlans: {
        code: string,
        name: string
    }[]
};

type CompanyProfileState = {
    loading: boolean,
    success: boolean | null,
    data: CompanyProfileData
}

/**
 * calls fetch company profile API
 **/
export const fetchCompanyProfile = createAsyncThunk(
    'company/users/:uuid',
    async (uuid: string, { dispatch, rejectWithValue }) => {
        try {
            let response = await getCompanyProfile(uuid);
            const { data = {} } = response;

            if (data.success === true) {
                dispatch(setData(response.data.data));
                return true;
            }

            return rejectWithValue('Server error');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const companyProfileSlice = createSlice({
    name: 'companyProfile',
    initialState: {
        loading: false,
        success: null,
        data: {} as CompanyProfileData,
    } as CompanyProfileState,
    reducers: {
        setData: (state, { payload }) => {
            state.data = {
                user: {
                    email: payload?.user?.email,
                    uuid: payload?.user?.uuid,
                },
                profile: {
                    id: payload?.profile?.id,
                    userId: payload?.profile?.user_id,
                    lastname: payload?.profile?.lastname,
                    firstname: payload?.profile?.firstname,
                    lastnameFurigana: payload?.profile?.lastname_furigana,
                    firstnameFurigana: payload?.profile?.firstname_furigana,
                    phone: payload?.profile?.phone,
                    createdAt: payload?.profile?.created_at,
                    updatedAt: payload?.profile?.updated_at,
                },
                company: {
                    name: payload?.company?.name,
                    nameFurigana: payload?.company?.name_furigana,
                    postalCode: payload?.company?.postal_code,
                    address: payload?.company?.address,
                    buildingName: payload?.company?.building_name,
                    representativePosition: payload?.company?.representative_position,
                    prefectureCode: payload?.company?.prefecture_code,
                    industryCode: payload?.company?.industry_code,
                    employeeCountRangeCode: payload?.company?.employee_count_range_code,
                    prefectureName: payload?.company?.prefecture_name,
                    industryName: payload?.company?.industry_name,
                    employeeCountRangeName: payload?.company?.employee_count_range_name,
                    parttimeEmployeeCount: payload?.company?.parttime_employee_count,
                    insurancePlans: payload?.company?.insurance_plans,
                    laborRegulationsCompliant: payload?.company?.labor_regulations_compliant,
                    allowSharoushi: payload?.company?.allow_sharoushi,
                    allowShindanshi: payload?.company?.allow_shindanshi,
                    daidoBranchName: payload?.company?.daido_branch_name,
                    daidoDepartmentName: payload?.company?.daido_department_name,
                    daidoEmployeeName: payload?.company?.daido_employee_name,
                    createdAt: payload?.company?.created_at,
                    updatedAt: payload?.company?.updated_at,
                },
                insurancePlans: payload?.insurance_plans,
            };
        },
        resetCompanyProfile: (state) => {
            state.loading = false;
            state.success = null;
            state.data = {} as CompanyProfileData;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCompanyProfile.pending, (state) => {
            state.loading = true;
            state.success = null;
        });
        builder.addCase(fetchCompanyProfile.rejected, (state) => {
            state.loading = false;
            state.success = false;
        });
        builder.addCase(fetchCompanyProfile.fulfilled, (state) => {
            state.loading = false;
            state.success = true;
        });
    },
});

export const { setData, resetCompanyProfile } = companyProfileSlice.actions;
export const selectCompanyProfile = (state: RootState) => state.companyProfile;
