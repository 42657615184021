import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { getMeetings, getMeetingsCount } from 'api/meeting';
import { Pagination } from 'types';
import { getErrorMessage } from 'api';
import { ApplicationSpecialistStatuses } from 'enums';

export type MeetingListParams = {
    status: number,
    keyword: string,
    page: number
}

export type MeetingCountParams = {
    keyword: string
}

type MeetingResponse = {
    uuid: string,
    schedule: string,
    company: {
        name: string,
        prefecture: string,
        employee_count_range: string,
    },
    funds: string[],
    status: string,
}

export type Status = {
    content: string,
    color: string
};

export type Meeting = {
    uuid: string,
    schedule: string,
    company: {
        name: string,
        prefecture: string,
        employeeCountRange: string,
    },
    title: string,
    status: Status,
}

type MeetingListCount = {
    scheduled: number,
    finished: number
}

type MeetingListState = {
    loading: boolean,
    success: boolean | null,
    meetings: Meeting[],
    pagination: Pagination,
    count: MeetingListCount
}

export const fetchMeetings = createAsyncThunk(
    'specialist/meetings',
    async (params: MeetingListParams, { dispatch, rejectWithValue }) => {
        try {
            const response = await getMeetings(params);
            dispatch(setMeetings(response.data.data));
            dispatch(setPagination(response.data.pagination));

            if (params.status === ApplicationSpecialistStatuses.SCHEDULED) {
                dispatch(setScheduledCount(response.data.pagination));
            } else if (params.status === ApplicationSpecialistStatuses.FINISHED) {
                dispatch(setFinishedCount(response.data.pagination));
            }

            return response.data;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const fetchMeetingsCount = createAsyncThunk(
    'specialist/meetings/count',
    async (params: MeetingCountParams, { dispatch, rejectWithValue }) => {
        try {
            const response = await getMeetingsCount(params);
            dispatch(setMeetingsCount(response.data.data));
            return response.data;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const meetingListSlice = createSlice({
    name: 'meetingList',
    initialState: {
        loading: false,
        success: null,
        meetings: [],
        pagination: {} as Pagination,
        count: {
            scheduled: 0,
            finished: 0,
        },
    } as MeetingListState,
    reducers: {
        setMeetings: (state, { payload }) => {
            state.meetings = payload.map((meeting: MeetingResponse) => ({
                uuid: meeting.uuid,
                schedule: meeting.schedule,
                company: meeting.company ? {
                    name: meeting.company.name,
                    prefecture: meeting.company.prefecture,
                    employeeCountRange: meeting.company.employee_count_range,
                } : null,
                funds: meeting.funds,
                status: meeting.status,
            }));
        },
        setPagination: (state, { payload }) => {
            state.pagination = {
                count: payload.count,
                total: payload.total,
                perPage: payload.per_page,
                currentPage: payload.current_page,
                lastPage: payload.last_page,
            };
        },
        setMeetingsCount: (state, { payload }) => {
            state.count = {
                scheduled: payload.selected,
                finished: payload.finished,
            };
        },
        setScheduledCount: (state, { payload }) => {
            state.count.scheduled = payload.total;
        },
        setFinishedCount: (state, { payload }) => {
            state.count.finished = payload.total;
        },
    }, extraReducers: (builder) => {
        builder.addCase(fetchMeetings.pending, (state) => {
            state.loading = true;
            state.success = null;
            state.meetings = [];
            state.pagination = {} as Pagination;
        });
        builder.addCase(fetchMeetings.rejected, (state) => {
            state.loading = false;
            state.success = false;
        });
        builder.addCase(fetchMeetings.fulfilled, (state) => {
            state.loading = false;
            state.success = true;
        });
    },
});

export const selectMeetingList = (state: RootState) => state.meetingList;

export const {
    setMeetings,
    setPagination,
    setMeetingsCount,
    setScheduledCount,
    setFinishedCount,
} = meetingListSlice.actions;
