import React, { useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import ChangePasswordSuccess from './ChangePasswordSuccess';
import { validatePasswordConfirmation } from 'lib/utils';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectAuth } from 'app/slice/authSlice';
import { RootState } from 'app/store';
import { changePassword } from 'app/slice/specialistSlice';

const rules = {
    currentPassword: [{ required: true, message: '現在のパスワードを入力してください' }],
    newPassword: [
        { required: true, message: '新しいパスワードを入力してください' },
        {
            pattern: new RegExp(/^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d).*$/),
            message: '8桁の英数混合のパスワードをご入力ください。',
        },
    ],
    newPasswordConfirmation: [
        { required: true, message: '新しいパスワード（確認用）をご入力ください。' },
        validatePasswordConfirmation('newPassword', 'パスワードは一致していません。'),
    ],
};

type Props = {
    isPasswordSuccess: boolean,
    setClose: () => void,
    setIsPasswordSuccess: (value: boolean) => void
}

const ChangePasswordRequirement = (props: Props) => {
    const { setIsPasswordSuccess } = props;
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const { status } = useAppSelector((state: RootState) => state.specialist);
    const [currentPassError, setCurrentPassError] = useState(false);

    const onSubmit = async (values: any) => {
        const { currentPassword, newPassword, newPasswordConfirmation } = values;
        dispatch(changePassword({ currentPassword, newPassword, newPasswordConfirmation }));
    };

    const resetCurrentPassError = () => setCurrentPassError(false);

    useEffect(() => {
        if (status === 'success') {
            setIsPasswordSuccess(true);
            form.resetFields();
        } else if (status === 'failed') {
            setCurrentPassError(true);
            form.validateFields(['current_password']);
        }
    }, [form, setIsPasswordSuccess, status]);

    const onCloseSuccess = () => {
        props.setClose();
    };

    const currentPasswordValidation = () => {
        if (currentPassError) {
            return Promise.reject('現在のパスワードが無効です。');
        }
        return Promise.resolve();
    };

    return (
        <>
            {props.isPasswordSuccess === false ?
                <Form
                    form={form}
                    onFinish={onSubmit}
                    layout='vertical'
                    autoComplete='off'
                >
                    <Form.Item
                        name='currentPassword'
                        label='現在のパスワード'
                        rules={[...rules.currentPassword, { validator: currentPasswordValidation }]}
                    >
                        <Input.Password onChange={resetCurrentPassError} />
                    </Form.Item>
                    <Form.Item
                        name='newPassword'
                        label='新しいパスワード'
                        rules={rules.newPassword}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        name='newPasswordConfirmation'
                        label='新しいパスワード (確認用)'
                        rules={rules.newPasswordConfirmation}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Button type='primary' htmlType='submit' className='w-100'>
                        変更
                    </Button>
                </Form>
                :
                <ChangePasswordSuccess onCloseSuccess={onCloseSuccess} />
            }
        </>
    );
};

export default ChangePasswordRequirement;
