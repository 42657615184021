import { Card } from 'antd';
import React from 'react';
import checkIcon from 'assets/images/success_check.png';

const RegistrationSuccess = () => {
    return (
        <Card className='success-modal'>
            <div className='title'>受付完了</div>
            <img src={checkIcon} alt={'chukidan-icon'} />
            <div className='message'>
                ご登録ありがとうございました。 <br />
                入力された情報を確認いたします。ご連絡があるまでお待ちください。
            </div>
        </Card>
    );
};

export default RegistrationSuccess;
