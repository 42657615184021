import React, { useEffect, useState } from 'react';
import { Button, Card, Checkbox, Col, Form, Input, Row, Select, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { useAppDispatch } from 'app/hooks';
import {
    getUserRegistrationData,
    registration,
    REGISTRATION,
    RegistrationCredentials,
} from 'app/slice/registrationSlice';
import { getEmployeeCountRanges, getFields, getIndustries, getPrefectures } from 'app/slice/resourcesSlice';
import { RootState } from 'app/store';
import { useLocation, useParams } from 'react-router-dom';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import {
    countFormatter,
    scrollToTop,
    useQuery,
    validateImage,
    validatePasswordConfirmation,
    validatePdf,
} from 'lib/utils';
import { useSelector } from 'react-redux';
import RegistrationSuccess from 'components/main/RegistrationSuccess';

type RegistrationFile = {
    file: { status: string; originFileObj: Blob }
}

const rules = {
    profileImage: [validateImage('single')],
    businessManual: [validatePdf('single')],
    field: [{ required: true, message: '得意分野を選択してください。' }],
    industry: [{ required: true, message: '業種を選択してください。' }],
    employee: [{ required: true, message: '得意とする従業員の範囲を選択してください。' }],
    lastName: [
        { required: true, whitespace: true, message: '姓をご入力ください。' },
        { max: 50, message: '50文字以内ご入力ください。' },
    ],
    firstName: [
        { required: true, whitespace: true, message: '名をご入力ください。' },
        { max: 50, message: '50文字以内ご入力ください。' },
    ],
    lastNameFurigana: [
        { pattern: new RegExp(/^([\sァ-ヶー]+)$/), message: '姓をフリガナでご入力ください。' },
        { max: 50, message: '50文字以内ご入力ください。' },
    ],
    firstNameFurigana: [
        { pattern: new RegExp(/^([\sァ-ヶー]+)$/), message: '名をフリガナでご入力ください。' },
        { max: 50, message: '50文字以内ご入力ください。' },
    ],
    specialistOfficeName: [
        { required: true, whitespace: true, message: '事務所名をご入力ください。' },
        { max: 100, message: '100文字以内ご入力ください。' },
    ],
    specialistPostalCode: [{ required: true, whitespace: true, message: '郵便番号をご入力ください。' }],
    specialistPrefectureCode: [{ required: true, whitespace: true, message: '都道府県をご選択ください。' }],
    specialistAddress: [
        { required: true, whitespace: true, message: '住所をご入力ください。' },
        { max: 200, message: '200文字以内ご入力ください。' },
    ],
    specialistOtherAddresses: [
        { whitespace: true, message: '他の住所を入力してください。' },
        { max: 100, message: '100文字以内ご入力ください。' },
    ],
    specialistSupportedAreas: [
        { whitespace: true, message: 'サポートされているエリアを入力してください。' },
        { max: 100, message: '100文字以内ご入力ください。' },
    ],
    specialistOfficePr: [
        { whitespace: true, message: 'オフィスの強さを入力してください。' },
        { max: 1000, message: '1000文字以内ご入力ください。' },
    ],
    specialistOfficeHistory: [
        { whitespace: true, message: '事務所の履歴を入力してください。' },
        { max: 1000, message: '1000文字以内ご入力ください。' },
    ],
    specialistQualifications: [
        { whitespace: true, message: '資格を入力してください。' },
        { max: 1000, message: '1000文字以内ご入力ください。' },
    ],
    prefecturesDropdown: [{ required: true, message: 'プルダウンからご選択ください。' }],
    phoneNumber: [{ required: true, whitespace: true, message: '電話番号をご入力ください。' }],
    certificationMark: [validateImage('list')],
    password: [
        { required: true, message: 'パスワードをご入力ください。' },
        { pattern: new RegExp(/^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d).*$/), message: '8桁の英数混合のパスワードをご入力ください。' },
    ],
    passwordConfirmation: [
        { required: true, message: 'パスワードをご入力ください。' },
    ],
};

const Registration = () => {
    const [form] = Form.useForm();

    const location = useLocation();
    const params = useParams();
    const query = useQuery();
    const {
        nextUrl,
        userEmail,
        type,
        success,
        loading,
    } = useSelector((state: RootState) => state.registration);
    const {
        fields,
        prefectures,
        industries,
        employeeCountRanges,
    } = useSelector((state: RootState) => state.resource);

    const dispatch = useAppDispatch();
    const [checkAllEmployeeCount, setCheckAllEmployeeCount] = useState(false);
    const [checkOtherField, setCheckOtherField] = useState(false);
    const [checkAllIndustries, setCheckAllIndustries] = useState(false);
    const [checkPrivacyPolicy, setCheckPrivacyPolicy] = useState(false);
    const [editMode, setEditMode] = useState(true);
    const [profileImageUrl, setProfileImageUrl] = useState(null);
    const [profileImageLoading, setProfileImageLoading] = useState(false);
    const [successForm, setSuccessForm] = useState(false);

    const onCancel = () => {
        setEditMode(true);
        scrollToTop('smooth');
    };

    const onSubmit = () => {
        if (editMode && form.isFieldsValidating) {
            setEditMode(false);
            scrollToTop('smooth');
            return;
        }
        dispatch(registration({
            nextUrl,
            form: form.getFieldsValue(),
            roleName: location.pathname.replace(/\/|registration/g, ''),
            email: query.get('email') ?? '',
        }));
    };

    useEffect(() => {
        if (type === REGISTRATION && success === true) {
            setSuccessForm(true);
        }
    }, [type, success]);

    useEffect(() => {
        const registrationCredentials: RegistrationCredentials = {
            email: query.get('email') ?? '',
            expires: query.get('expires') ?? '',
            signature: query.get('signature') ?? '',
        };

        dispatch(getUserRegistrationData(registrationCredentials));
        const role: string = location.pathname.replace(/\/|registration/g, '');
        dispatch(getEmployeeCountRanges());
        dispatch(getFields({ role }));
        dispatch(getIndustries());
        dispatch(getPrefectures());
    }, [dispatch, params.uuid, query, location.pathname]);

    const onChangeOtherfield = (e: { target: { value: string | any[]; }; }) => {
        setCheckOtherField(e.target.value.length > 0);
    };

    const onSelectAllPrefecture = (prefectureValue: string[]) => {
        let data: string[];
        if (prefectureValue.includes('all')) {
            data = prefectures.map(prefectures => prefectures.code);
        } else {
            data = prefectureValue;
        }
        form.setFieldsValue({
            'prefectures[]': data,
        });
    };

    const onCheckAllIndustry = (e: CheckboxChangeEvent) => {
        let data: string[] = [];
        setCheckAllIndustries(e.target.checked);
        if (e.target.checked) {
            data = industries.map(industries => industries?.code);
        }
        form.setFieldsValue({
            'industries[]': data,
        });
    };

    const onCheckPrivacyPolicy = (e: CheckboxChangeEvent) => {
        setCheckPrivacyPolicy(e.target.checked);
    };

    const onCheckIndustryOption = (checkValue: CheckboxValueType[]) => {
        if (checkValue.length < industries.length) {
            setCheckAllIndustries(false);
        }
    };

    const onCheckAllEmployeeCount = (e: CheckboxChangeEvent) => {
        let data: string[] = [];
        setCheckAllEmployeeCount(e.target.checked);
        if (e.target.checked) {
            data = employeeCountRanges.map(employeeCountRange => employeeCountRange.code);
        }
        form.setFieldsValue({
            'employeeCount[]': data,
        });
    };

    const onCheckEmployeeOption = (checkValue: CheckboxValueType[]) => {
        if (checkValue.length < employeeCountRanges.length) {
            setCheckAllEmployeeCount(false);
        }
    };

    const getBase64 = (img: Blob, callback: Function) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const uploadProfileButton = () => {
        return <Button className='upload-box' icon={profileImageLoading ? <LoadingOutlined /> : <PlusOutlined />}>
            <span className='upload-label'>アップロード</span>
        </Button>;
    };

    const handleProfileChange = (info: RegistrationFile) => {
        if (info.file.status === 'uploading') {
            setProfileImageLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (imageUrl: React.SetStateAction<null>) => {
                setProfileImageUrl(imageUrl);
                setProfileImageLoading(false);
            });
        }
    };

    const fileUpload = (e: { fileList: object, file: object }) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    //Created a custom request for upload to not seperate post request with submit
    const uploadfiles = (options: { onSuccess?: any }) => {
        const { onSuccess } = options;
        onSuccess('Ok');
    };

    return successForm ? <RegistrationSuccess /> : (
        <Card className='sharoushi-registration-card'>
            <div className='title'
            >{location.pathname.replace(/\/|registration/g, '') === 'sharoushi' ? '社労士向けサイト' : '診断士向けサイト'}</div>
            <div className='text-center helper-text mt-3 mb-1'>
                ご登録の内容が、企業とのマッチングに反映されます。対応可能地域、得意分野、得意業種など、対応可能なものを多く選択していただくと、マッチングの可能性が高まります。
            </div>
            <div className='subtitle'>は必須項目です</div>
            {userEmail &&
                <>
                    <Form form={form}
                          className='mt-4'
                          layout='vertical'
                          onFinish={onSubmit}
                          autoComplete='off'
                    >
                        <Row gutter={16}>
                            <Col sm={24} md={24}>
                                <Form.Item
                                    className='upload-form'
                                    name='attachment[profileImage]'
                                    label='プロフィール画像'
                                    extra='画像形式： png、jpg、jpeg。最大容量：2MB'
                                    rules={rules.profileImage}
                                    getValueFromEvent={fileUpload}
                                >
                                    <Upload name='profile' disabled={!editMode} showUploadList={false} accept='image/*'
                                            onChange={handleProfileChange} customRequest={uploadfiles}
                                    >
                                        {profileImageUrl ? (
                                            <img className='mt-2 h-150px w-150px' src={profileImageUrl} alt='avatar' />
                                        ) : (
                                            uploadProfileButton()
                                        )}
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={12}>
                                <Form.Item
                                    name='profile[lastname]'
                                    label='姓'
                                    extra='50文字以内'
                                    rules={rules.lastName}
                                >
                                    <Input readOnly={!editMode} placeholder='山田' />
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={12}>
                                <Form.Item
                                    name='profile[firstname]'
                                    label='名'
                                    extra='50文字以内'
                                    rules={rules.firstName}
                                >
                                    <Input readOnly={!editMode} placeholder='太郎' />
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={12}>
                                <Form.Item
                                    name='profile[lastnameFurigana]'
                                    label='姓（フリガナ）'
                                    extra='カタカナ（全角）を入力'
                                    rules={rules.lastNameFurigana}
                                >
                                    <Input readOnly={!editMode} placeholder='ヤマダ' />
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={12}>
                                <Form.Item
                                    name='profile[firstnameFurigana]'
                                    label='名（フリガナ）'
                                    extra='カタカナ（全角）を入力'
                                    rules={rules.firstNameFurigana}
                                >
                                    <Input readOnly={!editMode} placeholder='タロウ' />
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={12}>
                                <Form.Item
                                    name='specialist[officeName]'
                                    label='事務所名'
                                    extra='100文字以内'
                                    rules={rules.specialistOfficeName}
                                >
                                    <Input readOnly={!editMode} />
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={12}>
                                <Form.Item
                                    name='specialist[postalCode]'
                                    label='郵便番号'
                                    extra='0123456のように半角数字で入力（ハイフン「-」は不要）'
                                    rules={rules.specialistPostalCode}
                                >
                                    <Input readOnly={!editMode} placeholder='0000000' />
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={12}>
                                <Form.Item
                                    name='specialist[prefectureCode]'
                                    label='都道府県'
                                    rules={rules.specialistPrefectureCode}
                                >
                                    <Select placeholder='東京都' disabled={!editMode}>
                                        {prefectures.map((row) => (
                                            <Select.Option key={row.code} value={row.code}>{row.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={12}>
                                <Form.Item
                                    name='specialist[address]'
                                    label='住所'
                                    extra='200文字以内'
                                    rules={rules.specialistAddress}
                                >
                                    <Input readOnly={!editMode} placeholder='住所' />
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={12}>
                                <Form.Item
                                    name='specialist[buildingName]'
                                    label='ビル名'
                                >
                                    <Input readOnly={!editMode} placeholder='ビル名' />
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={24}>
                                <Form.Item
                                    name='prefectures[]'
                                    label='オンライン面談対応可能地域（複数選択可）'
                                    rules={rules.prefecturesDropdown}
                                >
                                    <Select
                                        mode='multiple'
                                        allowClear
                                        style={{ width: '100%' }}
                                        disabled={!editMode}
                                        onChange={onSelectAllPrefecture}
                                    >
                                        <Select.Option key='-1' value={'all'}>全国</Select.Option>
                                        {prefectures.map((row) => (
                                            <Select.Option key={row.code} value={row.code}>
                                                {row.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={12}>
                                <Form.Item
                                    name='specialist[supportedAreas]'
                                    label='その他対応可能地域（自由入力）'
                                    extra='自由入力、100文字以内'
                                    rules={rules.specialistSupportedAreas}
                                >
                                    <Input readOnly={!editMode} />
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={12}>
                                <Form.Item
                                    name='specialist[otherAddress]'
                                    label='その他の事務所住所（事務所が2つ以上ある方）'
                                    extra='自由入力、100文字以内'
                                    rules={rules.specialistOtherAddresses}
                                >
                                    <Input readOnly={!editMode} />
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={24}>
                                <Form.Item
                                    name='specialist[officePr]'
                                    label='事務所の強みやPR'
                                    extra='自由入力、1000文字以内'
                                    rules={rules.specialistOfficePr}
                                >
                                    <TextArea readOnly={!editMode} rows={3}
                                              showCount={editMode ? { formatter: countFormatter } : false}
                                              maxLength={1000}
                                    />
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={24}>
                                <Form.Item
                                    name='specialist[officeHistory]'
                                    label='経歴・実績'
                                    extra='自由入力、1000文字以内'
                                    rules={rules.specialistOfficeHistory}
                                >
                                    <TextArea readOnly={!editMode} rows={3}
                                              showCount={editMode ? { formatter: countFormatter } : false}
                                              maxLength={1000}
                                    />
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={24}>
                                <Form.Item
                                    name='specialist[qualifications]'
                                    label='保有資格'
                                    extra='自由入力、1000文字以内'
                                    rules={rules.specialistQualifications}
                                >
                                    <TextArea readOnly={!editMode} rows={3}
                                              showCount={editMode ? { formatter: countFormatter } : false}
                                              maxLength={1000}
                                    />
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={24} className='mb-3'>
                                <Form.Item
                                    className='mb-1'
                                    name='fields[]'
                                    label={(
                                        <>
                                            得意分野
                                            <span className='ms-2 text-gray'>少なくとも1つ選択してください</span>
                                        </>
                                    )}
                                    rules={rules.field}
                                >
                                    <Checkbox.Group className='w-100' disabled={!editMode}>
                                        <Row gutter={[8, 8]}>
                                            {Object.entries(fields).map((column, key) => (
                                                <Col key={key} sm={24} md={12}>
                                                    <Row gutter={[8, 8]}>
                                                        <Col key={key} md={24}>
                                                            <Checkbox value={column[1].code}>{column[1].name}</Checkbox>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Checkbox.Group>
                                </Form.Item>
                                <Row style={{ lineHeight: '32px' }}>
                                    <Checkbox checked={checkOtherField}>その他</Checkbox>
                                    <Form.Item
                                        className='w-350px'
                                        name='fields[others]'
                                    >
                                        <Input className='' onChange={onChangeOtherfield} readOnly={!editMode}
                                               placeholder='選択項目にない分野はこちらにご入力ください'
                                        />
                                    </Form.Item>
                                </Row>
                            </Col>

                            <Col sm={24} md={24}>
                                <div className='required-mark'>
                                    得意業種
                                    <span className='ms-2 text-gray'>少なくとも1つ選択してください</span>
                                </div>
                                {/* Putting this on Form.Item will break the check all functionality */}
                                <Checkbox className='mt-2 mb-2' checked={checkAllIndustries}
                                          onChange={onCheckAllIndustry}
                                >
                                    全業種対応可
                                </Checkbox>
                                <Form.Item
                                    name='industries[]'
                                    rules={rules.industry}
                                >
                                    <Checkbox.Group className='w-100' onChange={onCheckIndustryOption}
                                                    disabled={!editMode}
                                    >
                                        <Row gutter={[8, 8]}>
                                            {Object.entries(industries).map((column, key) => (
                                                <Col key={key} sm={24} md={12}>
                                                    <Row gutter={[8, 8]}>
                                                        <Col key={key} md={24}>
                                                            <Checkbox value={column[1].code}>{column[1].name}</Checkbox>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={24}>
                                <div className='required-mark'>
                                    得意事業規模
                                    <span className='ms-2 text-gray'>少なくとも1つ選択してください</span>
                                </div>
                                {/* Putting this on Form.Item will break the check all functionality */}
                                <Checkbox className='mt-2 mb-2' checked={checkAllEmployeeCount}
                                          onChange={onCheckAllEmployeeCount}
                                >
                                    全ての規模対応可
                                </Checkbox>
                                <Form.Item
                                    name='employeeCount[]'
                                    rules={rules.employee}
                                >
                                    <Checkbox.Group className='w-100' onChange={onCheckEmployeeOption}
                                                    disabled={!editMode}
                                    >
                                        <Row gutter={[8, 8]}>
                                            {Object.entries(employeeCountRanges).map((column, key) => (
                                                <Col key={key} sm={24} md={12}>
                                                    <Row gutter={[8, 8]}>
                                                        <Col key={key} md={24}>
                                                            <Checkbox value={column[1].code}
                                                            >{column[1].name}</Checkbox>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={12}>
                                <Form.Item
                                    name='profile[phone]'
                                    label='電話番号'
                                    extra='03-1234-5678の形式（半角数字、半角ハイフン）'
                                    rules={rules.phoneNumber}
                                >
                                    <Input readOnly={!editMode} placeholder='000-000-0000' />
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={12}>
                                <Form.Item
                                    label='Eメール'
                                    required={true}
                                >
                                    <Input readOnly={true} value={query.get('email') ?? ''} />
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={12}>
                                <Form.Item
                                    name='specialist[twitterUrl]'
                                    label='Twitter URL'
                                >
                                    <Input readOnly={!editMode} />
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={12}>
                                <Form.Item
                                    name='specialist[facebookUrl]'
                                    label='Facebook URL'
                                >
                                    <Input readOnly={!editMode} />
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={24}>
                                <Form.Item
                                    name='specialist[homepageUrl]'
                                    label='事務所ホームページURL'
                                >
                                    <Input readOnly={!editMode} />
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={12}>
                                <Form.Item
                                    className='upload-form'
                                    name='attachment[businessManual]'
                                    label='事務所案内'
                                    rules={rules.businessManual}
                                    getValueFromEvent={fileUpload}
                                    extra='PDFでアップロード、最大容量：2MB'
                                >
                                    <Upload name='business_manual' disabled={!editMode} accept='.pdf'
                                            customRequest={uploadfiles} maxCount={1}
                                    >
                                        <Button icon={<UploadOutlined />}>追加</Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={12}>
                                <Form.Item
                                    className='upload-form'
                                    name='attachment[certificationMark][]'
                                    label='認証・認定マーク'
                                    getValueFromEvent={fileUpload}
                                    rules={rules.certificationMark}
                                    extra={<div>Pマーク等、認証マークの画像を掲載できます。<br />推奨サイズ : 500px x 500px以上（最大容量：2MB）</div>}
                                >
                                    <Upload name='certification_mark' listType='picture' accept='image/*'
                                            disabled={!editMode} customRequest={uploadfiles}
                                    >
                                        <Button icon={<UploadOutlined />}>追加</Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={12}>
                                <Form.Item
                                    name='user[password]'
                                    label='パスワード'
                                    rules={rules.password}
                                >
                                    <Input.Password readOnly={!editMode} placeholder='abc1234567' />
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={12}>
                                <Form.Item
                                    name='user[passwordConfirmation]'
                                    label='パスワード（確認用）'
                                    rules={[
                                        ...rules.passwordConfirmation,
                                        validatePasswordConfirmation('user[password]', 'パスワードは一致していません。'),
                                    ]}
                                >
                                    <Input.Password readOnly={!editMode} placeholder='abc1234567' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <div className='text-center mt-3'>
                            {!editMode && (
                                <Checkbox className='mt-2 mb-2' checked={checkPrivacyPolicy}
                                          onChange={onCheckPrivacyPolicy}
                                >
                                    <a href='https://www.chukidan.com/privacy' target='_blank' rel='noreferrer'
                                    >個人情報取扱規約</a>に同意して次に進む
                                </Checkbox>
                            )}
                        </div>
                        <div className='text-center mt-3'>
                            {!editMode && (
                                <Button className='me-3' style={{ width: 300 }} onClick={onCancel} disabled={loading}
                                        htmlType='button'
                                >
                                    戻る
                                </Button>
                            )}
                            <Button htmlType='submit' type='primary' size='large'
                                    disabled={!editMode && !checkPrivacyPolicy} loading={loading}
                                    style={{ width: 300 }}
                            >
                                {editMode ? '確認' : '登録'}
                            </Button>
                        </div>
                    </Form>
                </>
            }
        </Card>
    );
};

export default Registration;
