import { useAppSelector } from 'app/hooks';
import { Meeting, selectMeetingList, Status } from 'app/slice/meetingListSlice';
import { Button, Space, Table, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { ConsultationStatuses } from 'enums';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Column } = Table;

const MeetingListTable = () => {
    const { meetings, loading } = useAppSelector(selectMeetingList);

    const renderTooltipContent = (status: Status) => {
        switch (status.content) {
            case ConsultationStatuses.SCHEDULED:
                return '専門家との面談日時が決定した状態です。面談当日は、詳細画面に掲載されている「ミーティングURL」からアクセスしてください。';
            case ConsultationStatuses.FINISHED:
                return '面談が完了した状態です。';
            default:
                return '';
        }
    };

    const renderFunds = (funds: string[]) => {
        return funds.map((fund: string) => (
            <>
                {fund}<br />
            </>
        ));
    };

    const renderStatus = (value: any, meeting: Meeting) => {
        return (
            <Space>
                <span className='gutter-row consultation-list-card-title'
                      style={{ color: `${meeting.status?.color}` }}
                >
                    {meeting.status?.content}
                </span>
                <Tooltip title={renderTooltipContent(meeting.status)}>
                    <InfoCircleOutlined className='status-info-icon' />
                </Tooltip>
            </Space>
        );
    };

    const renderActionButtons = (value: any, meeting: Meeting) => {
        return (
            <div className='details-button'>
                <Link to={`${meeting.uuid}/details`}>
                    <Button className='w-75'>詳細</Button>
                </Link>
            </div>
        );
    };

    return (
        <Table dataSource={meetings} rowKey='uuid' pagination={false} loading={loading}>
            <Column title='面談日時' dataIndex='schedule' />
            <Column title='企業名' dataIndex={['company', 'name']} />
            <Column title='所在地' dataIndex={['company', 'prefecture']} />
            <Column title='人数規模' dataIndex={['company', 'employeeCountRange']} />
            <Column title='面談内容' dataIndex='funds' render={renderFunds} />
            <Column title='ステータス' dataIndex='status' render={renderStatus} />
            <Column className='text-center' width='10%' title='' dataIndex='actionButtons'
                    key='actionButtons' render={renderActionButtons}
            />
        </Table>
    );
};

export default MeetingListTable;
