import { Card, Typography } from 'antd';
import { FundRequirements, Funds } from 'app/slice/applicationSlice';
import React from 'react';
import MeetingDetailsTextArea from 'components/consultation/MeetingDetailsTextArea';
import { ConsultationStatuses } from 'enums';

type Props = {
    fundApplication: Funds[],
    fundRequirements: FundRequirements[],
    emergencyContactNumber?: string,
    status: string,
}

const GrantChecklist = (props: Props) => {
    const { Title } = Typography;
    const {
        fundApplication,
        fundRequirements,
        emergencyContactNumber,
        status,
    } = props;

    return (
        <>
            {fundRequirements?.map((items, index) => {
                return (
                    <Card className='grant-checklist my-1' key={index}>
                        <span className='text-decoration-underline'><strong>以下は、全てお客さまが自社に該当するものとして ✓ 印を付けた項目です。</strong></span>
                        <br />
                        <div><Title level={5}>{items.title}</Title></div>
                        <ul>
                            {items?.requirements?.map((content, index) =>
                                <li key={index}>{content?.content}</li>,
                            )}
                        </ul>
                        {
                            items?.title !== '基本確認項目' &&
                            <MeetingDetailsTextArea fundApplication={fundApplication} requirementTitle={items?.title} />
                        }
                        {(status === ConsultationStatuses.SCHEDULED) && (index === fundRequirements.length - 1) &&
                            <div>
                                <span>当日の緊急連絡先: {emergencyContactNumber}</span>
                            </div>
                        }
                    </Card>
                );
            })}
        </>
    );
};

export default GrantChecklist;
