import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createScheduleApi, removeScheduleApi, updateScheduleApi } from 'api/schedule';
import { RootState } from 'app/store';

export const CREATE_SCHEDULE = 'CREATE_SCHEDULE';
export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE';
export const REMOVE_SCHEDULE = 'REMOVE_SCHEDULE';

type ScheduleState = {
    loading: boolean,
    success: boolean | null,
    type: string
}

export type CreateScheduleRequest = {
    uuid: string,
    date: string
}

export type UpdateScheduleRequest = {
    uuid: string,
    id: number,
    date: string
}

export type RemoveScheduleRequest = {
    uuid: string,
    id: number,
}

export const createSchedule = createAsyncThunk(
    'schedule/create',
    async (data: CreateScheduleRequest, { rejectWithValue }) => {
        try {
            const response = await createScheduleApi(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(false);
        }
    },
);

export const updateSchedule = createAsyncThunk(
    'schedule/update',
    async (data: UpdateScheduleRequest, { rejectWithValue }) => {
        try {
            const response = await updateScheduleApi(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(false);
        }
    },
);

export const removeSchedule = createAsyncThunk(
    'schedule/remove',
    async (data: RemoveScheduleRequest, { rejectWithValue }) => {
        try {
            const response = await removeScheduleApi(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(false);
        }
    },
);

export const scheduleSlice = createSlice({
    name: 'schedule',
    initialState: {
        loading: false,
        success: null,
        type: '',
    } as ScheduleState,
    reducers: {
        resetState: (state) => {
            state.loading = false;
            state.success = null;
            state.type = '';
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createSchedule.pending, (state) => {
            state.loading = true;
            state.success = null;
            state.type = CREATE_SCHEDULE;
        });
        builder.addCase(createSchedule.fulfilled, (state) => {
            state.loading = false;
            state.success = true;
            state.type = CREATE_SCHEDULE;
        });
        builder.addCase(createSchedule.rejected, (state) => {
            state.loading = false;
            state.success = false;
            state.type = CREATE_SCHEDULE;
        });
        builder.addCase(updateSchedule.pending, (state) => {
            state.loading = true;
            state.success = null;
            state.type = UPDATE_SCHEDULE;
        });
        builder.addCase(updateSchedule.fulfilled, (state) => {
            state.loading = false;
            state.success = true;
            state.type = UPDATE_SCHEDULE;
        });
        builder.addCase(updateSchedule.rejected, (state) => {
            state.loading = false;
            state.success = false;
            state.type = UPDATE_SCHEDULE;
        });
        builder.addCase(removeSchedule.pending, (state) => {
            state.loading = true;
            state.success = null;
            state.type = REMOVE_SCHEDULE;
        });
        builder.addCase(removeSchedule.fulfilled, (state) => {
            state.loading = false;
            state.success = true;
            state.type = REMOVE_SCHEDULE;
        });
        builder.addCase(removeSchedule.rejected, (state) => {
            state.loading = false;
            state.success = false;
            state.type = REMOVE_SCHEDULE;
        });
    },
});

export const { resetState } = scheduleSlice.actions;
export const selectSchedule = (state: RootState) => state.schedule;
