export enum ConsultationStatuses {
    MATCHING = 'マッチング中',
    SCHEDULED = '面談予約済み',
    DECLINED = '辞退',
    DUE_DATE = '回答期限切れ',
    CANCELED = 'キャンセル',
    FINISHED = '面談済み',
    WAITING_CONF = '企業からの返事待ち',
    MATCHING_END = 'マッチング終了',
}

export enum ApplicationSpecialistStatuses {
    SCHEDULED = 3,
    FINISHED = 5,
}
