import React from 'react';
import { Button, Modal } from 'antd';
import declineIcon from 'assets/images/ic_confirm_delete.png';

type Props = {
    isVisible: boolean,
    onClose: () => void,
    onDeclineClick: () => void,
}

const DeclineConsultationModal = (props: Props) => {

    const { isVisible, onClose, onDeclineClick } = props;

    return (
        <Modal className='consultation-decline-modal' visible={isVisible} footer={null} closable={false}>
            <div className='title'>面談依頼を辞退</div>
            <div className='text-center my-4'>
                <img src={declineIcon} alt={'decline-icon'} />
            </div>
            <div className='text-center my-3'>
                この面談依頼を辞退してもよろしいでしょうか。
            </div>
            <div className='text-center'>
                <Button className='consultation-decline-modal-buttons'
                        type='primary'
                        onClick={onDeclineClick} danger block>辞退</Button>
                <Button className='consultation-decline-modal-buttons' block onClick={onClose}>キャンセル</Button>
            </div>
        </Modal>
    );

};

export default DeclineConsultationModal;
