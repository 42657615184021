import { Button, Card, Col, Row, Typography } from 'antd';
import { Application, Schedule } from 'app/slice/applicationSlice';
import React, { useState } from 'react';
import CompanyProfileModal from 'components/consultation/CompanyProfileModal';
import { fetchCompanyProfile, resetCompanyProfile } from 'app/slice/companyProfileSlice';
import { useAppDispatch } from 'app/hooks';
import { ConsultationStatuses } from 'enums';

type Props = {
    application?: Application,
    hasProfileButton?: boolean,
    schedule?: Schedule[],
    scheduleData?: React.ReactNode,
    role: string,
};

const { Title } = Typography;

const ClientDetails = (props: Props) => {
    const { application, scheduleData } = props;
    const { company } = application || {};

    const dispatch = useAppDispatch();
    const [isCompanyProfileVisible, setCompanyProfileVisible] = useState(false);
    const onOpenCompanyProfile = () => {
        dispatch(fetchCompanyProfile(application!.uuid));
        setCompanyProfileVisible(true);
    };
    const onCloseCompanyProfile = () => {
        setCompanyProfileVisible(false);
        dispatch(resetCompanyProfile());
    };

    return (
        <div className='consultation-specialist-card'>
            <Card>
                { company && (
                    <>
                    <Title level={ 5 } className='d-inline'>お客さま</Title>
                    {(application?.label?.content === ConsultationStatuses.FINISHED || application?.label?.content === ConsultationStatuses.SCHEDULED) && (
                        <Button type='primary' className='button-title' onClick={ onOpenCompanyProfile }>お客さま情報詳細</Button>

                    )}
                    <Row gutter={ 16 }>
                        <Col md={ 12 }>
                            <Row gutter={ 10 }>
                                <Col md={ 7 } xs={ 8 }>会社名</Col>
                                <Col md={ 17 } xs={ 16 }>
                                    <span>{ company?.name }</span>
                                </Col>
                            </Row>
                            <Row gutter={ 10 }>
                                <Col md={ 7 } xs={ 8 }>住所</Col>
                                <Col md={ 17 } xs={ 16 }>{ company?.address }</Col>
                            </Row>
                            <Row gutter={ 10 }>
                                <Col md={ 7 } xs={ 8 }>業種</Col>
                                <Col md={ 17 } xs={ 16 }>{ company?.industry }</Col>
                            </Row>
                            <Row gutter={ 10 }>
                                <Col md={ 7 } xs={ 8 }>従業員数</Col>
                                <Col md={ 17 } xs={ 16 }>{ company?.employeeCountRanges }</Col>
                            </Row>
                            <Row gutter={ 10 }>
                                <Col md={ 7 } xs={ 8 }>パート/アルバイト数</Col>
                                <Col md={ 17 } xs={ 16 }>{ company?.parttimeEmployeeCount }</Col>
                            </Row>


                        </Col>
                        <Col md={ 9 }>
                            <Row gutter={ 10 }>
                                <Col md={ 7 } xs={ 8 }>社会保険</Col>
                                { company?.insurances?.length > 0 ? (
                                    <Col md={ 17 } xs={ 16 }>
                                        { company?.insurances.map((insurance, key) =>
                                            <div key={ key }>{ insurance.name }</div>,
                                        ) }
                                    </Col>
                                ) : (
                                    <Col md={ 17 } xs={ 16 }>無し</Col>
                                ) }
                            </Row>
                            <Row gutter={ 10 }>
                                <Col md={ 7 } xs={ 8 }>就業規則</Col>
                                <Col md={ 17 } xs={ 16 }>{ company?.laborRegulationsCompliant ? '有り' : '無し' }</Col>
                            </Row>
                            <Row gutter={ 10 }>
                                <Col md={ 7 } xs={ 8 }>顧問診断士</Col>
                                <Col md={ 17 } xs={ 16 }>{ company?.allowShindanshi ? '有り' : '無し' }</Col>
                            </Row>
                            <Row gutter={ 10 }>
                                <Col md={ 7 } xs={ 8 }>顧問社労士</Col>
                                <Col md={ 17 } xs={ 16 }>{ company?.allowSharoushi ? '有り' : '無し' }</Col>
                            </Row>
                        </Col>
                    </Row>
                    </>) }

                { scheduleData }
            </Card>
            <CompanyProfileModal isVisible={ isCompanyProfileVisible } onClose={ onCloseCompanyProfile } />
        </div>
    );

};

export default ClientDetails;
