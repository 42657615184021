import { getAxiosClientWithToken } from './index';
import { CreateScheduleRequest, RemoveScheduleRequest, UpdateScheduleRequest } from 'app/slice/scheduleSlice';

export const createScheduleApi = (data: CreateScheduleRequest) => {
    const { uuid } = data;

    const restClient = getAxiosClientWithToken();
    return restClient.post(`/specialist/application/${uuid}/schedule/single`, data);
};

export const updateScheduleApi = (data: UpdateScheduleRequest) => {
    const { uuid, id } = data;

    const restClient = getAxiosClientWithToken();
    return restClient.patch(`/specialist/application/${uuid}/schedule/single/${id}`, data);
};

export const removeScheduleApi = (data: RemoveScheduleRequest) => {
    const { uuid, id } = data;

    const restClient = getAxiosClientWithToken();
    return restClient.delete(`/specialist/application/${uuid}/schedule/single/${id}`);
};
