import React from 'react';
import { Button, Modal } from 'antd';
import questionIcon from 'assets/images/icon_question.png';

type Props = {
    title: React.ReactNode | string,
    isVisible: boolean,
    onClose: () => void,
    onSubmit: () => void,
    loading: boolean
}

const RemoveScheduleModal = (props: Props) => {

    const { title, isVisible, onClose, onSubmit, loading } = props;

    return (
        <Modal className='consultation-decline-modal' visible={isVisible} footer={null} closable={false}>
            <div className='title'>{title}</div>
            <div className='text-center my-4'>
                <img src={questionIcon} alt={'question-icon'} />
            </div>
            <div className='text-center'>
                <Button className='consultation-decline-modal-buttons'
                        type='primary'
                        onClick={onSubmit} danger block loading={loading}>削除する</Button>
                <Button className='consultation-decline-modal-buttons' block onClick={onClose} disabled={loading}>キャンセル</Button>
            </div>
        </Modal>
    );

};

export default RemoveScheduleModal;
