import { Pagination, Tabs } from 'antd';
import Search from 'antd/lib/input/Search';
import { ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { fetchMeetings, fetchMeetingsCount, selectMeetingList } from 'app/slice/meetingListSlice';
import { ApplicationSpecialistStatuses } from 'enums';
import MeetingListTable from 'components/meeting-list/MeetingListTable';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useParamsState } from 'lib/utils';
import { setSearchQuery } from 'app/slice/searchQuerySlice';

const { TabPane } = Tabs;

enum TabType {
    SCHEDULED = 1,
    FINISHED = 2
}

const MeetingList = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const [activeTab, setActiveTab] = useParamsState('tab', params.type ?? '1');
    const [keyword] = useParamsState('keyword', '');
    const [page, setPage] = useParamsState<number>('page', 1, parseInt);
    const { pagination, count } = useAppSelector(selectMeetingList);
    const [keywordChange, setKeywordChange] = useState<string>();

    const onChangeKeyword: ChangeEventHandler = (event: any) => {
        setKeywordChange(event.target.value);
    };

    useEffect(() => {
        setKeywordChange(keyword);
    }, [keyword]);

    useEffect(() => {
        dispatch(setSearchQuery('?' + searchParams.toString()));
    }, [dispatch, searchParams]);

    const onChangeTab = (key: string) => {
        navigate('/dashboard/meeting-list/' + key);
        setActiveTab(key);
    };

    const onSearch = (value: string) => {
        searchParams.set('page', '1');
        searchParams.set('keyword', value);
        setSearchParams(searchParams);
    };

    const fetchMeetingList = useCallback(() => {
        let status = 0;

        if (+activeTab === TabType.SCHEDULED) {
            status = ApplicationSpecialistStatuses.SCHEDULED;
        } else if (+activeTab === TabType.FINISHED) {
            status = ApplicationSpecialistStatuses.FINISHED;
        } else {
            return;
        }

        dispatch(fetchMeetings({ status, keyword, page }));
    }, [activeTab, dispatch, keyword, page]);

    useEffect(() => {
        dispatch(fetchMeetingsCount({ keyword }));
    }, [dispatch, keyword]);

    useEffect(() => {
        fetchMeetingList();
    }, [fetchMeetingList]);

    useEffect(() => {
        if (!params.type) {
            navigate('1');
        }
    }, [navigate, params.type]);

    return (
        <div className='content consultation-list'>
            <div className='title'>
                面談予定一覧
                <small className='fw-normal ms-3'>※Zoom面談のURLは、「詳細」ボタンからご確認ください。</small>
            </div>
            <div className='table-filter'>
                <Search placeholder='検索'
                        value={keywordChange}
                        onChange={onChangeKeyword}
                        onSearch={onSearch} className='search-dropdown' allowClear={true}
                />
            </div>

            <Tabs activeKey={activeTab} onChange={onChangeTab}>
                <TabPane tab={`今後の面談予定 (${count.scheduled})`} key={TabType.SCHEDULED}>
                    <MeetingListTable />
                </TabPane>
                <TabPane tab={`面談終了した案件 (${count.finished})`} key={TabType.FINISHED}>
                    <MeetingListTable />
                </TabPane>
            </Tabs>

            <div className='text-center mt-3'>
                {(pagination?.total > 0) &&
                    <Pagination className='d-inline float-center'
                                defaultPageSize={pagination.perPage}
                                current={page}
                                total={pagination.total}
                                onChange={setPage}
                                showSizeChanger={false}
                    />
                }
            </div>
        </div>
    );
};

export default MeetingList;
