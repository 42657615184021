import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    cancelApplicationApi,
    CancelApplicationRequest,
    declineApplicationApi,
    getApplicationDetailsApi,
    getApplicationsApi,
    GetApplicationsRequest,
    getApplicationStatusTypesApi,
    getAvailableTimeSlotOnCurrentDate,
    getHolidaysApi,
    saveMeetingRemarks,
    submitScheduleApi,
} from 'api/application';
import { RootState } from 'app/store';
import { Moment } from 'moment';
import { SelectedSchedules, SubmitScheduleRequest } from './meetingSlice';

export type { GetApplicationsRequest } from 'api/application';
export const GET_APPLICATIONS = 'GET_APPLICATIONS';
export const GET_APPLICATION_DETAILS = 'GET_APPLICATION_DETAILS';
export const SUBMIT_SCHEDULE = 'SUBMIT_SCHEDULE';
export const SUBMIT_MEETING_REMARKS = 'SUBMIT_MEETING_REMARKS';

type AvailableStartTime = {
    availableStartTime: string,
    availability: boolean
}

type AvailableEndTime = {
    availableEndTime: string,
    availability: boolean
}

type AvailableTimeResponse = {
    start: string,
    end: string,
    available: boolean
}

type ApplicationType = {
    applications: Application[],
    applicationStatusTypes: ApplicationStatus[],
    pagination: Pagination,
    application: Application,
    schedule: Schedule,
    company: Company,
    funds: Funds[],
    availableStartTime: AvailableStartTime[],
    availableEndTime: AvailableEndTime[],
    holidays: string[],
    loading: boolean,
    success: boolean,
    type: string,
}

export type FundRequirements = {
    title: string,
    requirements: Requirements[]
}

export type Requirements = {
    content: string
}

export type Funds = {
    code: string,
    title: string,
    type: number,
    fundRequirements: FundRequirements[],
    applicationRemarks: string
}

export type FundsResponse = {
    code: string,
    title: string,
    type: number,
    fund_requirements: FundRequirements[],
    application_remarks: string
}

type CompanyResponse = {
    name: string,
    address: string,
    industry: string,
    labor_regulations_compliant: boolean,
    insurances: CompanyInsurance[],
    allow_sharoushi: boolean,
    allow_shindanshi: boolean,
    person_in_charge: string,
    prefecture: string,
    employee_count_ranges: string,
    parttime_employee_count: string,
}

type ScheduleResponse = {
    id: number,
    uuid: string,
    number: number,
    date: string,
    content: string
}

type ApplicationResponse = {
    uuid: string,
    status: number,
    label: Label,
    schedule: string,
    schedule_datetime: string,
    daido_joins: boolean,
    emergency_contact_number: string,
    is_deadline: boolean,
    join_url: string,
    password: string,
    funds: FundsResponse[],
    company: CompanyResponse,
    chukidan_schedules: ScheduleResponse[],
    specialists: SpecialistResponse[]
    schedules: ScheduleResponse,
    meeting_remarks: string,
    meeting_created_date: string
}

type SpecialistResponse = {
    uuid: string,
    schedules: ScheduleResponse[]
}

export type Schedule = {
    date: string;
    datetime: string;
    inCharge: string;
    meetingURL: string;
    password: string;
};

export type Company = {
    name: string,
    address: string,
    industry: string,
    laborRegulationsCompliant: boolean,
    insurances: CompanyInsurance[],
    allowSharoushi: boolean,
    allowShindanshi: boolean,
    personInCharge: string
    prefecture: string,
    employeeCountRanges: string,
    parttimeEmployeeCount: string
}

type CompanyInsurance = {
    code: string,
    name: string
}

type Label = {
    content: string,
    color: string
}

// Application Data Structure
export type Application = {
    uuid: string,
    status: number,
    label: Label,
    specialistScheduleLabel: string,
    schedule: string,
    scheduleDatetime: string | null,
    daidoJoins: boolean,
    emergencyContactNumber: string,
    isDeadline: boolean | null,
    joinUrl: string,
    password: string,
    funds: Funds[],
    company: Company,
    schedules: SelectedSchedules[],
    meetingRemarks: string,
    meetingCreatedDate: string
}

// Application Status Data Structure, mainly for Filter function
export type ApplicationStatus = {
    code: string,
    name: string
}

// Pagination Data Structure returned by API call
export type Pagination = {
    count: number,
    total: number,
    perPage: number,
    currentPage: number,
    lastPage: number,
}

export type SaveMeetingRemarks = {
    remarks: string,
    uuid: string
}

/**
 * GET applications list
 **/
export const getApplications = createAsyncThunk(
    '/specialist/applications',
    async (request: GetApplicationsRequest, { dispatch }) => {
        try {
            const response = await getApplicationsApi(request);
            const { data, pagination } = response.data;
            // transform snake_case to camel_case
            const applications = data?.map((app: ApplicationResponse) => ({
                uuid: app?.uuid,
                status: app?.status,
                label: app?.label,
                isDeadline: app?.is_deadline,
                scheduleDatetime: app?.schedule_datetime,
                joinUrl: app?.join_url,
                password: app?.password,
                daidoJoins: app?.daido_joins,
                schedule: app?.schedule,
                meetingCreatedDate: app?.meeting_created_date,
                meetingRemarks: app?.meeting_remarks,
                company: {
                    name: app?.company?.name,
                    address: app?.company?.address,
                    industry: app?.company?.industry,
                    laborRegulationsCompliant: app?.company?.labor_regulations_compliant,
                    allowSharoushi: app?.company?.allow_sharoushi,
                    allowShindanshi: app?.company?.allow_shindanshi,
                    insurances: app?.company?.insurances,
                    personInCharge: app?.company?.person_in_charge,
                    prefecture: app?.company?.prefecture,
                    employeeCountRanges: app?.company?.employee_count_ranges,
                    parttimeEmployeeCount: app?.company?.parttime_employee_count,
                },
                funds: app?.funds?.map((fund: FundsResponse) => ({
                    code: fund?.code,
                    type: fund?.type,
                    title: fund?.title,
                    fundRequirements: fund?.fund_requirements,
                    applicationRemarks: fund?.application_remarks,
                })),
                schedules: app?.chukidan_schedules,
            }));
            dispatch(setApplications({ applications, pagination }));
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

/**
 * GET application details
 **/
export const getApplicationDetails = createAsyncThunk(
    '/specialist/application/details',
    async (uuid: string, { dispatch }) => {
        try {
            const response = await getApplicationDetailsApi(uuid);
            const { data, success } = response.data;
            if (success) {
                dispatch(setApplication(data));
            }
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

/**
 * GET application statuses list
 **/
export const getApplicationStatusTypes = createAsyncThunk(
    '/specialist/application/statuses',
    async (data, { dispatch }) => {
        try {
            const response = await getApplicationStatusTypesApi();
            dispatch(setApplicationStatusTypes(response.data));
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

/**
 * UPDATE application status to decline
 **/
export const declineApplication = createAsyncThunk(
    '/specialist/applications/decline',
    async (data: string) => {
        try {
            const response = await declineApplicationApi(data);
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

/**
 * UPDATE application status to cancel
 **/
export const cancelApplication = createAsyncThunk(
    '/specialist/applications',
    async (data: CancelApplicationRequest) => {
        try {
            const response = await cancelApplicationApi(data);
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

export const getAvailableTimeSlot = createAsyncThunk(
    '/specialist/getAvailableTimeSlot',
    async (date: Moment, { dispatch }) => {
        try {
            const response = await getAvailableTimeSlotOnCurrentDate(date);
            dispatch(setAvailableTimeSlot(response.data));
            return response.data;
        } catch (err) {
            dispatch(resetAvailableTime());
            return false;
        }
    },
);

export const getHolidays = createAsyncThunk(
    '/specialist/getHolidays',
    async (data, { dispatch }) => {
        try {
            const response = await getHolidaysApi();
            dispatch(setHolidays(response.data));
            return response.data;
        } catch (err) {
            dispatch(resetHolidays());
            return false;
        }
    },
);

export const submitSchedule = createAsyncThunk(
    'schedule/submit',
    async (data: SubmitScheduleRequest, { rejectWithValue }) => {
        try {
            const response = await submitScheduleApi(data);
            return response.data;
        } catch (err) {
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(false);
        }
    },
);

export const submitMeetingRemarks = createAsyncThunk(
    'meeting/remarks',
    async (data: SaveMeetingRemarks, { rejectWithValue }) => {
        try {
            const response = await saveMeetingRemarks(data);
            return response.data;
        } catch (err) {
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(false);
        }
    },
);

/**
 * Create Application Slice
 */
export const applicationSlice = createSlice({
    name: 'applications',
    initialState: {
        applications: [] as Application[],
        applicationStatusTypes: [] as ApplicationStatus[],
        pagination: {} as Pagination,
        application: {} as Application,
        schedule: {} as Schedule,
        availableStartTime: [] as AvailableStartTime[],
        availableEndTime: [] as AvailableEndTime[],
        holidays: [] as string[],
        loading: false,
        success: false,
        type: '',
    } as ApplicationType,
    reducers: {
        setApplications: (state, { payload }) => {
            const { applications, pagination } = payload;
            state.applications = applications;
            state.pagination = {
                count: pagination.count,
                total: pagination.total,
                perPage: pagination.per_page,
                currentPage: pagination.current_page,
                lastPage: pagination.last_page,
            };
        },
        setApplicationStatusTypes: (state, { payload }) => {
            state.applicationStatusTypes = payload.data;
        },
        // for consultation details
        setApplication: (state, { payload }) => {
            let funds = payload?.funds?.map((fund: FundsResponse) => ({
                code: fund?.code,
                type: fund?.type,
                title: fund?.title,
                fundRequirements: fund?.fund_requirements,
                applicationRemarks: fund?.application_remarks,
            }));
            state.application = {
                uuid: payload?.uuid,
                status: payload?.status,
                label: payload?.label,
                specialistScheduleLabel: payload?.specialist_schedule_label,
                isDeadline: payload?.is_deadline,
                scheduleDatetime: payload?.schedule_datetime,
                joinUrl: payload?.join_url,
                password: payload?.password,
                daidoJoins: payload?.daido_joins,
                emergencyContactNumber: payload?.emergency_contact_number,
                schedule: payload?.schedule,
                meetingRemarks: payload?.meeting_remarks,
                company: {
                    name: payload?.company?.name,
                    address: payload?.company?.address,
                    industry: payload?.company?.industry,
                    laborRegulationsCompliant: payload?.company?.labor_regulations_compliant,
                    allowSharoushi: payload?.company?.allow_sharoushi,
                    allowShindanshi: payload?.company?.allow_shindanshi,
                    insurances: payload?.company?.insurances,
                    personInCharge: payload?.company?.person_in_charge,
                    parttimeEmployeeCount: payload?.company?.parttime_employee_count,
                    employeeCountRanges: payload?.company?.employee_count_ranges,
                },
                funds: funds,
                schedules: payload?.schedules,
            } as Application;
        },
        setSchedule: (state, { payload }) => {
            state.schedule.date = payload?.schedule;
            state.schedule.datetime = payload?.scheduleDatetime;
            // TODO: change to the appropriate value
            state.schedule.inCharge = ''; // backend WIP
            state.schedule.meetingURL = payload?.join_url;
            state.schedule.password = payload?.password;
        },
        setAvailableTimeSlot: (state, { payload }) => {
            const availableStartTime: AvailableStartTime[] = [];
            const availableEndTime: AvailableEndTime[] = [];
            payload.data?.forEach((item: AvailableTimeResponse) => {
                if (item.available) {
                    availableStartTime.push({
                        availableStartTime: item.start,
                        availability: item.available,
                    });
                    availableEndTime.push({
                        availableEndTime: item.end,
                        availability: item.available,
                    });
                }
            });
            state.availableStartTime = availableStartTime;
            state.availableEndTime = availableEndTime;
        },
        setHolidays: (state, { payload }) => {
            state.holidays = payload.data;
        },
        resetAvailableTime: (state) => {
            state.availableStartTime = [] as AvailableStartTime[];
            state.availableEndTime = [] as AvailableEndTime[];
        },
        resetApplication: (state) => {
            state.application = {} as Application;
            state.schedule = {} as Schedule;
        },
        resetHolidays: (state) => {
            state.holidays = [] as string[];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getApplications.pending, (state) => {
            state.type = GET_APPLICATIONS;
            state.loading = true;
            state.success = false;
            state.applications = [];
        });
        builder.addCase(getApplications.rejected, (state) => {
            state.type = GET_APPLICATIONS;
            state.loading = false;
            state.success = false;
        });
        builder.addCase(getApplications.fulfilled, (state) => {
            state.type = GET_APPLICATIONS;
            state.loading = false;
            state.success = true;
        });
        builder.addCase(getApplicationDetails.pending, (state) => {
            state.type = GET_APPLICATION_DETAILS;
            state.loading = true;
            state.success = false;
            state.application = {} as Application;
        });
        builder.addCase(getApplicationDetails.rejected, (state) => {
            state.type = GET_APPLICATION_DETAILS;
            state.loading = false;
            state.success = false;
        });
        builder.addCase(getApplicationDetails.fulfilled, (state) => {
            state.type = GET_APPLICATION_DETAILS;
            state.loading = false;
            state.success = true;
        });
        builder.addCase(submitSchedule.pending, (state) => {
            state.type = SUBMIT_SCHEDULE;
            state.loading = true;
            state.success = false;
        });
        builder.addCase(submitSchedule.rejected, (state) => {
            state.type = SUBMIT_SCHEDULE;
            state.loading = false;
            state.success = false;
        });
        builder.addCase(submitSchedule.fulfilled, (state) => {
            state.type = SUBMIT_SCHEDULE;
            state.loading = false;
            state.success = true;
        });
        builder.addCase(submitMeetingRemarks.pending, (state) => {
            state.type = SUBMIT_MEETING_REMARKS;
            state.loading = true;
            state.success = false;
        });
        builder.addCase(submitMeetingRemarks.rejected, (state) => {
            state.type = SUBMIT_MEETING_REMARKS;
            state.loading = false;
            state.success = false;
        });
        builder.addCase(submitMeetingRemarks.fulfilled, (state) => {
            state.type = SUBMIT_MEETING_REMARKS;
            state.loading = false;
            state.success = true;
        });
    },
});

export const {
    setApplications,
    setApplicationStatusTypes,
    setSchedule,
    setApplication,
    setHolidays,
    resetApplication,
    resetAvailableTime,
    resetHolidays,
    setAvailableTimeSlot,
} = applicationSlice.actions;
export const selectApplications = (state: RootState) => state.applications;
