import { Button, Empty, Input, Pagination, Select, Skeleton, Space, Table, Tooltip } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
    Application,
    Funds,
    getApplications,
    getApplicationStatusTypes,
    resetApplication,
    selectApplications,
} from 'app/slice/applicationSlice';

import { ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { range } from 'lodash';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ConsultationStatuses } from 'enums';
import { useParamsState } from 'lib/utils';
import { setSearchQuery } from 'app/slice/searchQuerySlice';

const { Search } = Input;

const orderByValues = {
    created_at: 'created_at',
    company: ['company', 'name'],
    prefecture: ['company','prefecture'],
    employeeCountRanges: ['company','employeeCountRanges'],
    funds: ['funds'],
    status: 'status',
}

const ConsultationList = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const { applications, pagination, applicationStatusTypes, loading } = useAppSelector(selectApplications);
    // Filter Function Constants
    const [keywords] = useParamsState('keyword', '');
    const [page, setPage] = useParamsState<number>('page', 1, parseInt);
    const [selectedStatus] = useParamsState<number>('status', -1, parseInt);
    const [orderBy] = useParamsState<number | string | string[]>(
        'orderBy', -1,
        useCallback(value => orderByValues[value as keyof typeof orderByValues], [])
    );
    const [sortBy] = useParamsState<number | 'asc' | 'desc'>('sortBy', -1);
    const [keywordChange, setKeywordChange] = useState<string>();

    const onChangeKeyword: ChangeEventHandler = (event: any) => {
        setKeywordChange(event.target.value);
    };

    useEffect(() => {
        setKeywordChange(keywords);
    }, [keywords]);

    useEffect(() => {
        dispatch(setSearchQuery('?' + searchParams.toString()));
    }, [dispatch, searchParams]);

    const onSearch = (value: string) => {
        searchParams.set('page', '1');
        searchParams.set('keyword', value);
        setSearchParams(searchParams);
    };

    //Set table values
    const columns = [
        {
            title: '案件受信日',
            dataIndex: 'meetingCreatedDate',
            key: 'created_at',
            sorter: true,
            sortDirection: ['desc', 'asc'],
            width: '17%'
        },
        {
            title: '企業名',
            dataIndex: ['company', 'name'],
            key: 'company',
            sorter: true,
            sortDirection: ['desc', 'asc'],
            width: '12%'
        },
        {
            title: '所在地',
            dataIndex: ['company','prefecture'],
            key: 'prefecture',
            sorter: true,
            sortDirection: ['desc', 'asc'],
            width: '11%'
        },
        {
            title: '人数規模',
            dataIndex: ['company','employeeCountRanges'],
            key: 'employeeCountRanges',
            sorter: true,
            sortDirection: ['desc', 'asc'],
            width: '13%'
        },
        {
            title: '面談内容',
            dataIndex:['funds'],
            key: 'funds',
            sorter: true,
            width: '13%',
            sortDirection: ['desc', 'asc'],
            render: (funds: Funds[]) => {
                return funds.map((fund: Funds)=> (<div>{fund.title}</div>))
             },
        },
        {
            title: 'ステータス',
            dataIndex: 'label',
            key: 'status',
            sorter: true,
            width: '15%',
            sortDirection: ['desc', 'asc'],
            render: (label: { color: string; content: string }) => {
               return (<Space>

                        <span className='gutter-row consultation-list-card-title'
                              style={{ color: `${label?.color}` }}
                        >
                            {label?.content}
                        </span>
                        <Tooltip title={renderTooltipContent(label?.content)}>
                            <InfoCircleOutlined className='status-info-icon' />
                        </Tooltip>
                    </Space>);
            },
        },
        {
            title: '',
            key: 'schedule',
            width: '9%',
            render: (application: Application) => {
                //Should not display schedule when status is on the following
                const filterStatus: string[] = [ConsultationStatuses.MATCHING_END, ConsultationStatuses.DECLINED];
                return (<>
                     {!filterStatus.includes(application?.label?.content) && (
                        <>
                            {
                                (application?.isDeadline && application?.schedule) ?
                                    '回答期限：' :
                                    (!application?.isDeadline && application?.schedule && application?.label?.content !== ConsultationStatuses.DUE_DATE) ?
                                        '面談日：' : ''
                            }
                            {application?.schedule}
                        </>
                    )}
                     </>);
             },
        },
        {
            title: '',
            key: 'button',
            width: '10%',
            render: (application: Application) => {
                return (
                     <Button onClick={onClick(application)} className='details-button'>詳細</Button>
                   );
             },
        },
    ];

    const sortColumn = (pagination: any, filters:any, sorter: any) =>  {
        let fieldParam = sorter?.column.key;
        const sortNewValue = sorter?.order?.replace('end', '');
        //Once sortBy initial value would replace it will always have asc as default
        let sorterParam =  sortNewValue !== sortBy ? sortNewValue : 'desc';

        searchParams.set('page', '1');
        searchParams.set('orderBy', fieldParam);
        searchParams.set('sortBy', sorterParam);
        setSearchParams(searchParams);
    }

    const renderTooltipContent = (status: string) => {
        switch (status) {
            case ConsultationStatuses.MATCHING:
                return '新規の相談依頼がございました。先生の応募をお待ちしている状態です。（本日を含め、土日・祝日を除く３日を過ぎると「回答期限切れ」となります 。）';
            case ConsultationStatuses.WAITING_CONF:
                return '先生の応募に対して、お客さまが検討している状態です。（本日を含め、土日・祝日を除く３日を過ぎると「回答期限切れ」となります 。）';
            case ConsultationStatuses.MATCHING_END:
                return 'お客さまが他の専門家を選択された状態です。また次の案件にご応募いただければ幸いです。';
            case ConsultationStatuses.SCHEDULED:
                return 'お客さまとの面談が確定した状態です。「面談管理」にて日時をご確認の上、面談当日は詳細画面に掲載されている「ミーティングURL」からアクセスしてください。';
            case ConsultationStatuses.FINISHED:
                return '面談が完了した状態です。';
            case ConsultationStatuses.DUE_DATE:
                return '応募受付が終了した状態です。また次の案件にご応募いただければ幸いです。';
            case ConsultationStatuses.CANCELED:
                return '面談はキャンセルとなりました。';
            case ConsultationStatuses.DECLINED:
                return '応募受付が終了した状態です。また次の案件にご応募いただければ幸いです。';
            default:
                return '';
        }
    };

    useEffect(() => {
        dispatch(getApplicationStatusTypes());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getApplications({
            keywords: keywords,
            page: page,
            status: selectedStatus,
            orderBy: orderBy,
            sortBy: sortBy
        }));
    }, [dispatch, keywords, page, selectedStatus, orderBy, sortBy]);

    const onClick = (application: Application) => {
        return () => {
            dispatch(resetApplication());
            navigate(`/dashboard/consultation/${application?.uuid}/details`);
        };
    };

    const onChangePage = (page: number) => {
        setPage(page);
    };

    const onSelectChange = (value: number) => {
        searchParams.set('page', '1');
        searchParams.set('status', value.toString());
        setSearchParams(searchParams);
    };

    return (
        <div className='content consultation-list'>
            <div className='title'>面談依頼一覧</div>
            <div className='table-filter'>
                <Select className='page-actions-items status-dropdown' value={selectedStatus}
                        onChange={onSelectChange}
                >
                    <Select.Option value={-1}>全て</Select.Option>
                    {applicationStatusTypes.map((row, index) => (
                        <Select.Option
                            key={index}
                            value={row.code}
                        >{row.name}</Select.Option>
                    ))}
                </Select>
                <Search placeholder='検索' value={keywordChange}
                        onChange={onChangeKeyword}
                        onSearch={onSearch} className='search-dropdown' />
            </div>
            <div className='subtitle'>受信件数：{pagination.total}件</div>

            {loading ? (
                <div className='my-3'>
                    {range(0, 10).map(key => (
                        <Skeleton.Button key={key} active size='large' block className='mb-2' />
                    ))}
                </div>
            ) : (
                applications.length === 0 ? (
                    <Empty />
                ) : (
                    <Table className='consultation-table' dataSource={applications} columns={columns} pagination={false} onChange={sortColumn} />
                )
            )}
            <div className='text-center consultation-list-pagination'>
                {(pagination?.total > 0) && (
                    <Pagination className='d-inline float-center'
                                defaultPageSize={pagination.perPage}
                                disabled={loading}
                                current={page}
                                total={pagination.total}
                                showSizeChanger={false}
                                onChange={onChangePage}
                    />
                )}
            </div>
        </div>
    );
};

export default ConsultationList;
