import { message, Skeleton } from 'antd';
import { CancelApplicationRequest } from 'api/application';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
    cancelApplication,
    declineApplication,
    FundRequirements,
    GET_APPLICATION_DETAILS,
    getApplicationDetails,
    selectApplications,
    setSchedule,
    SUBMIT_SCHEDULE,
    submitSchedule,
} from 'app/slice/applicationSlice';
import { selectAuth } from 'app/slice/authSlice';
import {
    resetSelectedSchedules,
    resetState,
    selectMeeting,
    setSchedules,
    SubmitScheduleRequest,
} from 'app/slice/meetingSlice';
import { reset, selectSuccessModal, setSuccessState, SuccessModalState } from 'app/slice/successSlice';
import successIcon from 'assets/images/success_check.png';
import Breadcrumbs, { Breadcrumb } from 'components/common/Breadcrumb';
import { ConsultationStatuses } from 'enums';
import moment from 'moment';
import Canceled from 'pages/consultation/Canceled';
import Declined from 'pages/consultation/Declined';
import Finished from 'pages/consultation/Finished';
import Matching from 'pages/consultation/Matching';
import MatchingEnd from 'pages/consultation/MatchingEnd';
import PastDueDate from 'pages/consultation/PastDueDate';
import Scheduled from 'pages/consultation/Scheduled';
import WaitingConfirmation from 'pages/consultation/WaitingConfirmation';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { selectSearchQuery } from 'app/slice/searchQuerySlice';

const ConsultationDetails = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { uuid, ...params } = useParams();
    const { schedule, success, application, type, loading } = useAppSelector(selectApplications);
    const { searchQuery } = useAppSelector(selectSearchQuery);
    const auth = useAppSelector(selectAuth);
    const { selectedSchedules } = useAppSelector(selectMeeting);
    const { isShow } = useAppSelector(selectSuccessModal);
    const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
    const onCloseCancelModal = () => setIsCancelModalVisible(false);
    const onOpenCancelModal = () => setIsCancelModalVisible(true);

    const meetingListBreadcrumb: Breadcrumb[] = useMemo(() => ([
        {
            name: '面談予定一覧',
            url: '/dashboard/meeting-list/' + params.type + searchQuery,
        },
        {
            name: '面談詳細',
            url: '',
        },
    ]), [params.type, searchQuery]);

    const consultationBreadcrumbs: Breadcrumb[] = useMemo(() => ([
        {
            name: 'ホームページ',
            url: '/dashboard/meeting',
        },
        {
            name: '面談依頼一覧',
            url: '/dashboard/consultation' + searchQuery,
        },
        {
            name: '面談詳細',
            url: '',
        },
    ]), [searchQuery]);

    useEffect(() => {
        if (typeof uuid === 'string') {
            dispatch(getApplicationDetails(uuid));
        }
    }, [dispatch, uuid]);

    useEffect(() => {
        if (application) {
            dispatch(setSchedule(application));
            dispatch(setSchedules(application?.schedules));
        }
    }, [application, application?.schedules, dispatch]);

    const fundRequirements = useMemo(() => {
        const requirements: FundRequirements[] = [];

        if (application?.funds) {
            for (let fund of application?.funds) {
                for (let fundRequirement of fund?.fundRequirements) {
                    const requirement = requirements.find((value => value.title === fundRequirement.title));
                    if (!requirement) {
                        requirements.push(fundRequirement);
                    }
                }
            }
        }

        return requirements;
    }, [application?.funds]);

    const breadcrumbs = useMemo(() => {
        if (location.pathname.startsWith('/dashboard/meeting-list')) {
            return meetingListBreadcrumb;
        }

        return consultationBreadcrumbs;
    }, [consultationBreadcrumbs, location.pathname, meetingListBreadcrumb]);

    const onCancelApplication = (value: string) => {
        const request: CancelApplicationRequest = {
            reason: value,
            uuid: application?.uuid,
        };
        dispatch(cancelApplication(request));
        navigate('/dashboard/consultation/');
    };

    const onCancelClick = () => {
        navigate('../');
        dispatch(resetSelectedSchedules());
    };

    const onDeclineClick = () => {
        dispatch(declineApplication(application?.uuid));
        onCloseCancelModal();
        navigate('/dashboard/consultation');
    };

    const onSubmitSchedule = () => {
        let dates: string[] = [];
        // format and push on array the dates to be submitted
        selectedSchedules.forEach((item) => {
            let startTime = item.content.split(' ~ ');
            dates.push(moment(`${item.date} ${startTime[0]}`, 'YYYY年MM月DD日（ddd） HH:mm').format('YYYY-MM-DD HH:mm'));
        });
        //Check if there is any duplicate schedules
        if (new Set(dates).size !== dates.length) {
            message.warn('日程が重複しています。');
            return false;
        }
        // build request
        const request: SubmitScheduleRequest = {
            dates: dates,
            uuid: application?.uuid,
        };
        dispatch(submitSchedule(request));
    };

    useEffect(() => {
        if (type === SUBMIT_SCHEDULE && success) {
            let successModalPayload: SuccessModalState = {
                isShow: success,
                title: '面談申込完了',
                message: '申し込みが完了しました。\n' +
                    'お客さまの回答がありましたら、ご連絡を差し上げます。',
                withButton: true,
                image: successIcon,
                buttonLink: '/dashboard/consultation',
                buttonTitle: '完了',
            };

            dispatch(setSuccessState(successModalPayload));
            dispatch(resetState());
        }
    }, [application?.label?.content, dispatch, success, type]);

    useEffect(() => {
        if (!isShow) {
            dispatch(reset());
        }
    }, [dispatch, isShow]);

    const renderConsultationDetailPage = () => {
        switch (application?.label?.content) {
            case ConsultationStatuses.SCHEDULED:
                return <Scheduled application={application}
                                  role={auth?.roleName}
                                  fundRequirements={fundRequirements}
                                  schedule={schedule}
                                  onCancelApplication={onCancelApplication}
                />;
            case ConsultationStatuses.MATCHING:
                return <Matching application={application}
                                 role={auth?.roleName}
                                 fundRequirements={fundRequirements}
                                 selectedSchedules={selectedSchedules}
                                 isCancelModalVisible={isCancelModalVisible}
                                 onCancelClick={onCancelClick}
                                 onCloseCancelModal={onCloseCancelModal}
                                 onOpenCancelModal={onOpenCancelModal}
                                 onDeclineClick={onDeclineClick}
                                 onSubmitSchedule={onSubmitSchedule}

                />;
            case ConsultationStatuses.DUE_DATE:
                return <PastDueDate application={application}
                                    role={auth?.roleName}
                                    fundRequirements={fundRequirements}
                />;
            case ConsultationStatuses.CANCELED:
                return <Canceled application={application}
                                 role={auth?.roleName}
                                 fundRequirements={fundRequirements}
                />;
            case ConsultationStatuses.FINISHED:
                return <Finished application={application}
                                 role={auth?.roleName}
                                 fundRequirements={fundRequirements}
                                 schedule={schedule}
                                 type={type}
                                 success={success}
                />;
            case ConsultationStatuses.DECLINED:
                return <Declined application={application}
                                 role={auth?.roleName}
                                 fundRequirements={fundRequirements}
                />;
            case ConsultationStatuses.MATCHING_END:
                return <MatchingEnd application={application}
                                    role={auth?.roleName}
                                    fundRequirements={fundRequirements}
                />;
            case ConsultationStatuses.WAITING_CONF:
                return <WaitingConfirmation application={application}
                                            role={auth?.roleName}
                                            fundRequirements={fundRequirements}
                                            selectedSchedules={selectedSchedules}
                                            onCancelClick={onCancelClick}
                />;
            default:
                return '';
        }
    };

    return (
        <>
            <Breadcrumbs breadcrumb={breadcrumbs} />
            {loading && type === GET_APPLICATION_DETAILS ? (
                <Skeleton active />
            ) : renderConsultationDetailPage()}
        </>
    );
};

export default ConsultationDetails;
