import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { fetchSystemNotification, selectSystemNotification } from 'app/slice/systemNotificationSlice';
import { Modal } from 'antd';

const SystemNotification = () => {
    const dispatch = useAppDispatch();
    const { data, success } = useAppSelector(selectSystemNotification);

    useEffect(() => {
        dispatch(fetchSystemNotification());
    }, [dispatch]);

    const showModal = () => {
        Modal.warning({
            title: <strong>{data.title}</strong>,
            content: <div dangerouslySetInnerHTML={{ __html: data.content }} />,
            okText: 'OK',
            width: 800,
            centered: true,
        });
    };

    if (!success || !data) {
        return null;
    }

    return (
        <div className='system-notification-container'>
            <p onClick={showModal}>{data.createdAt} — {data.title}</p>
        </div>
    );
};

export default SystemNotification;
