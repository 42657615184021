import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchUserRegistrationData, registering, sendRegistrationEmail } from 'api/user';
import { RootState } from 'app/store';
import { isArray } from 'lodash';

export const REGISTRATION = 'REGISTRATION';
export const SEND_EMAIL = 'SEND_EMAIL';
export const GET_REGISTRATION = 'GET_REGISTRATION';

export type RegistrationState = {
    success: boolean,
    message: string,
    loading: boolean,
    status: string,
    type: string
}
export type ResourcesValue = {
    code: string;
}


export type RegistrationFieldData = {
    'user[email]': string,
    'user[roleName]': string,
    'user[password]': string,
    'user[passwordConfirmation]': string,
    'profile[lastname]': string,
    'profile[firstname]': string,
    'profile[lastnameFurigana]': string,
    'profile[firstnameFurigana]': string,
    'profile[phone]': string,
    'specialist[officeName]': string,
    'specialist[postalCode]': string,
    'specialist[address]': string,
    'specialist[prefectureCode]': string,
    'specialist[buildingName]': string,
    'specialist[otherAddress]': string,
    'specialist[twitterUrl]': string,
    'specialist[facebookUrl]': string,
    'specialist[homepageUrl]': string,
    'specialist[nationwideSupport]': string,
    'specialist[supportedAreas]': string,
    'specialist[officePr]': string,
    'specialist[officeHistory]': string,
    'specialist[qualifications]': string,
    'specialist[onlineSupport]': number,
    'specialist[allowNotifications]': number,
    'specialist[allowCompanyReferrals]': number,
    'specialist[allowSpecialistSearch]': number,
    'specialist[representativePosition]': string,
    'industries': ResourcesValue[],
    'fields': ResourcesValue[],
    'employee_count': ResourcesValue[],
    'prefectures': ResourcesValue[],
}

export type RegistrationEmail = {
    email: string,
    type: string
}

/**
 * Send email for user's registration data
 **/
export const sendEmail = createAsyncThunk(
    'registration/emailData',
    async (data: RegistrationEmail, { rejectWithValue }) => {
        const { email, type } = data;
        try {
            const response = await sendRegistrationEmail(email, type);
            return response.data;
        } catch (err) {
            return rejectWithValue(false);
        }
    },
);

export type RegistrationCredentials = {
    email: string,
    expires: string,
    signature: string
}

/**
 * GET user's registration data
 **/
export const getUserRegistrationData = createAsyncThunk(
    'registration/userData',
    async (data: RegistrationCredentials) => {
        const { email, expires, signature } = data;
        try {
            const response = await fetchUserRegistrationData(email, expires, signature);
            return response.data.data;
        } catch (err) {
            return false;
        }
    },
);

// export type RegistrationRequest =  FormData;
type RegistrationRequest = {
    nextUrl: string,
    form: RegistrationFieldData,
    roleName: string,
    email: string
}

/**
 * POST user's registration data
 **/
export const registration = createAsyncThunk(
    'registration/registerData',
    async (data: RegistrationRequest, { rejectWithValue }) => {
        const { nextUrl, form, email, roleName } = data;
        const formData = new FormData();

        for (let [key, value] of Object.entries<any>(form)) {
            value = isArray(value) ? value : [value];
            for (let data of value) {
                data = data?.originFileObj ?? data;
                //Add if the value is not undefined or a field array
                if (data) {
                    formData.append(key, data?.url || data);
                }
            }
        }

        formData.append('user[roleName]', roleName);
        //Value not being submitted in the readOnly field, appended email field
        formData.append('user[email]', email);

        let newData = new FormData();
        for (let [key, value] of formData.entries()) {
            let newkey = key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
            newData.append(newkey, value);
        }

        try {
            const response = await registering(nextUrl, newData);
            if (response.data.success) {
                return true;
            }
            return rejectWithValue('Server error.');
        } catch (err) {
            return rejectWithValue(false);
        }
    },
);

export const registrationSlice = createSlice({
    name: 'registration',
    initialState: {
        success: false,
        message: '',
        loading: false,
        status: 'pending',
        nextUrl: '',
        userEmail: '',
        type: '',
    },
    reducers: {
        resetRegistration: (state) => {
            state.success = false;
            state.message = '';
            state.loading = false;
            state.status = 'pending';
            state.nextUrl = '';
            state.userEmail = '';
            state.type = '';
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUserRegistrationData.fulfilled, (state, { payload }) => {
            state.type = GET_REGISTRATION;
            state.userEmail = payload?.email;
            state.nextUrl = payload?.url;
        });
        builder.addCase(sendEmail.pending, (state: RegistrationState) => {
            state.type = SEND_EMAIL;
            state.loading = true;
            state.success = false;
            state.status = 'pending';
        });
        builder.addCase(sendEmail.fulfilled, (state: RegistrationState) => {
            state.type = SEND_EMAIL;
            state.loading = false;
            state.success = true;
            state.status = 'success';
        });
        builder.addCase(sendEmail.rejected, (state: RegistrationState) => {
            state.type = SEND_EMAIL;
            state.loading = false;
            state.success = false;
            state.status = 'failed';
            state.message = '既に登録されているメールアドレスです。';
        });
        builder.addCase(registration.pending, (state: RegistrationState) => {
            state.type = REGISTRATION;
            state.loading = true;
            state.success = false;
            state.status = 'pending';
        });
        builder.addCase(registration.rejected, (state: RegistrationState) => {
            state.type = REGISTRATION;
            state.loading = false;
            state.success = false;
            state.status = 'failed';
        });
        builder.addCase(registration.fulfilled, (state: RegistrationState) => {
            state.type = REGISTRATION;
            state.loading = false;
            state.success = true;
            state.status = 'success';
        });
    },
});

export const { resetRegistration } = registrationSlice.actions;
export const selectRegistration = (state: RootState) => state.registration;
