import { Alert, Button, Form, Input, Modal } from 'antd';
import {
    ForgotPasswordEmailSendingRequest,
    selectForgotPassword,
    sendForgotPasswordEmail,
} from 'app/slice/forgotPasswordSlice';
import React, { useCallback, useEffect } from 'react';
import { resetActionKey, selectSuccessModal, setSuccessState, SuccessModalState } from 'app/slice/successSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Rules } from 'types';
import sendingEmailSuccessIcon from 'assets/images/icon_send_mail.png';

type Props = {
    isVisible: boolean;
    onClose: () => void;
    origin: string;
};

const rules: Rules = {
    email: [
        { required: true, whitespace: true, message: 'メールアドレスをご入力ください。' },
        { type: 'email' }
    ]
};

const ForgotPasswordModal = (props: Props) => {
    const { isVisible, origin } = props;
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const { success = '', message, loading = false, status } = useAppSelector(selectForgotPassword);
    const successModal = useAppSelector(selectSuccessModal);

    const onFormSubmit = async (values: ForgotPasswordEmailSendingRequest) => {
        const { email } = values;
        dispatch(sendForgotPasswordEmail({ email, origin }));
    };
    const onClose = useCallback(() => {
        props.onClose();
        form.resetFields();
    }, [form, props]);

    useEffect(() => {
        if (success) {
            const successModalPayload: SuccessModalState = {
                isShow: success,
                title: 'パスワード再発行',
                message: '入力されたメールアドレスに認証メールを送信しました。\n送信されたURLからパスワードの再設定をお願いします。',
                withButton: false,
                image: sendingEmailSuccessIcon,
                buttonActionKey: 'ON_FORGOT_PASSWORD_SENT',
            };
            onClose();
            dispatch(setSuccessState(successModalPayload));
        }
    }, [dispatch, onClose, success]);

    useEffect(() => {
        if (successModal.isShow === false && successModal.buttonActionKey === 'ON_FORGOT_PASSWORD_SENT') {
            dispatch(resetActionKey());
            onClose();
        }
    }, [dispatch, onClose, successModal.buttonActionKey, successModal.isShow]);

    
    return (
        <Modal className='forgot-password-modal' visible={isVisible} onCancel={onClose} footer={null}>
            <div className='title'>パスワード再発行</div>
            <div className='subtitle'>確認のため、ご登録のメールアドレスをご入力ください。</div>
            {status === 'failed' && <Alert type={'error'} message={message} className='my-3' />}
            <Form form={form} layout='vertical' onFinish={onFormSubmit} autoComplete='off'>
                <Form.Item 
                    name='email' 
                    label='メールアドレス' 
                    rules={rules.email}
                >
                    <Input />
                </Form.Item>
                <Button type='primary' htmlType='submit' className='w-100' loading={loading}>
                    {!loading ? '送信' : ''}
                </Button>
            </Form>
            <div className='helper-text'>※ご登録のメールアドレスをお忘れの場合は、中小企業福祉事業団にご確認ください。</div>
        </Modal>
    );
};

export default ForgotPasswordModal;
