import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getScheduledMeetings } from 'api/meeting';
import { RootState } from 'app/store';
import moment from 'moment';

/**
 * GET user's scheduled meetings
 **/
export const getUserScheduledMeetings = createAsyncThunk(
    'users/meetings',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const response = await getScheduledMeetings();
            dispatch(setScheduledMeetings(response.data));
            return response.data;
        } catch (err) {
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(false);
        }
    },
);

export type Schedule = {
    schedule: string,
    companyName: string,
    uuid: string
}

// type for the response
type ResponseSchedule = {
    schedule: string,
    company_name: string,
    uuid: string
}

export type SelectedSchedules = {
    id: number,
    number: number,
    date: string,
    content: string,
}

export type SelectedSchedulesRequest = {
    id: number,
    date: string,
    startTime: string,
    endTime: string
}

export type SubmitScheduleRequest = {
    dates: string[],
    uuid: string
}

type MeetingState = {
    loading: boolean,
    success: boolean,
    schedules: Schedule[],
    countScheduledMeetings: number
    selectedSchedules: SelectedSchedules[]
}

const initialState: MeetingState = {
    loading: false,
    success: false,
    schedules: [] as Schedule[],
    countScheduledMeetings: 0,
    selectedSchedules: [] as SelectedSchedules[],
};

export const meetingSlice = createSlice({
    name: 'meeting',
    initialState: initialState,
    reducers: {
        setScheduledMeetings: (state, { payload }) => {
            const { schedules, count_scheduled_meetings } = payload.data;
            const convertedData: Schedule[] = [];
            schedules?.forEach((sched: ResponseSchedule) => {
                convertedData.push({
                    schedule: sched?.schedule,
                    companyName: sched?.company_name,
                    uuid: sched.uuid,
                });
            });
            state.schedules = convertedData;
            state.countScheduledMeetings = count_scheduled_meetings;
        },
        setSelectedMeetingSchedules: (state, { payload }) => {
            const { date, startTime, endTime } = payload;
            state.selectedSchedules.push({
                id: state.selectedSchedules.length + 1,
                number: state.selectedSchedules.length + 1,
                date: moment(date).format('YYYY年MM月DD日（ddd）'),
                content: `${startTime} ~ ${endTime}`,
            });
        },
        updateScheduleFromList: (state, { payload }) => {
            const newSchedule: SelectedSchedules[] = [...state.selectedSchedules];
            let schedule = newSchedule.find(item => item.id === payload.id);

            if (schedule) {
                schedule.date = payload.date;
                schedule.content = payload.startTime + ' ~ ' + payload.endTime;
                state.selectedSchedules = newSchedule;
            }
        },
        removeScheduleFromList: (state, { payload }) => {
            const newSchedule = [...state.selectedSchedules];
            // find id and remove from the array
            newSchedule.splice(newSchedule.findIndex(item => item.number === payload), 1);
            newSchedule.forEach((item, index) => {
                item.id = index + 1;
                item.number = index + 1;
            });
            state.selectedSchedules = newSchedule;
        },
        setSchedules: (state, { payload }) => {
            const schedules: SelectedSchedules[] = [];
            payload?.forEach((item: SelectedSchedules) => {
                let newDate;
                let newTime;
                let schedDateAndTime = item.content.split('　');
                if (item.content.includes('（')) {
                    const splitDate = item.content.split('）');
                    newDate = splitDate[0] + '）';
                    newTime = splitDate[1];
                } else {
                    newDate = schedDateAndTime[0];
                    newTime = schedDateAndTime[1];
                }
                schedules.push({
                    id: item.number,
                    number: item.number,
                    date: newDate,
                    content: newTime,
                });
            });

            state.selectedSchedules = schedules;
        },
        resetSelectedSchedules: (state) => {
            state.selectedSchedules = [];
        },
        resetState: (state) => {
            state.loading = false;
            state.success = false;
        },
    },
});

export const selectMeeting = (state: RootState) => state.meeting;

export const {
    setScheduledMeetings,
    setSelectedMeetingSchedules,
    updateScheduleFromList,
    removeScheduleFromList,
    resetSelectedSchedules,
    resetState,
    setSchedules,
} = meetingSlice.actions;
