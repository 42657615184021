import { getAxiosClient, getAxiosClientWithToken } from './index';

export const registering = (url: string, data: FormData) => {
    const restClient = getAxiosClient();
    return restClient.post(url, data, {
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'multipart/form-data',
        },
    });
};
export const sendRegistrationEmail = (email: string, type: string) => {
    const restClient = getAxiosClient();
    return restClient.post('/specialist/register/confirm', { email, type });
};
export const fetchUserRegistrationData = (email: string, expires: string, signature: string) => {
    const restClient = getAxiosClient();
    return restClient.get('/specialist/register/api', { params: { email, expires, signature } });
};

export const getUserData = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/user');
};

export const getProfile = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/specialist/users');
};

export const saveProfile = (data: FormData) => {
    const restClient = getAxiosClientWithToken();

    return restClient.post('/specialist/profile', data, {
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'multipart/form-data',
        },
    });
};
