import React from 'react';
import { Button, Card } from 'antd';
import successIcon from 'assets/images/success_check.png';
import { Link, useLocation } from 'react-router-dom';

const ForgotPasswordSuccess = () => {

    const location = useLocation();
    const loginRedirect = () => {
        if (location.pathname.includes('shindanshi')) {
            return '/shindanshi/login';
        }
        return '/sharoushi/login';
    }

    return (
        <Card className='success-modal'>
            <div className='title'>パスワード設定完了</div>
            <img src={successIcon} alt={'chukidan-icon'} />
            <div className='message'>新しいパスワードの設定が完了しました。<br />
                以下のログインボタンよりログインしてください。
            </div>
            <Link to={loginRedirect()}><Button type={'primary'}>ログイン</Button></Link>
        </Card>
    );
};

export default ForgotPasswordSuccess;
