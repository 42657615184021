import { getAxiosClient } from './index';

export const authenticateSharoushiApi = (email: string, password: string) => {
    const restClient = getAxiosClient();
    return restClient.post('/sharoushi/login', { email, password });
};

export const authenticateShindanshiApi = (email: string, password: string) => {
    const restClient = getAxiosClient();
    return restClient.post('/shindanshi/login', { email, password });
};
